import React, { useEffect, useState } from 'react'
import Component from '../components/common/Component'
import NavBar from '../components/common/NavBar'
import Container from '@mui/material/Container'
import {
  createIntegration,
  GetIntegrationResult,
  getIntegrations,
  GetIntegrationsResult,
  IntegrationDisplayName,
  IntegrationName,
} from '../api/integration'
import { copy, err, log } from '../utils/functions'
import Grid from '@mui/material/Grid'
import Tabs from '../components/common/Tabs'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import EbayIntegration from './integrations/EbayIntegration'
import EtsyIntegration from './integrations/EtsyIntegration'
import ShopifyIntegration from './integrations/ShopifyIntegration'
import BigCommerceIntegration from './integrations/BigCommerceIntegration'
import { INTEGRATION_NAMES } from '../types'
import SquareIntegration from './integrations/SquareIntegration'
import CloverIntegration from './integrations/CloverIntegration'

export default function Integrations(): JSX.Element {
  const [integrations, setIntegrations] = useState<GetIntegrationsResult>({})

  const handleSetIntegrations = <T extends IntegrationName>(
    name: IntegrationName,
    integration: GetIntegrationResult<T>,
  ) => {
    const newIntegrations = copy(integrations)

    // Map to type, type checking
    if (name === IntegrationName.EBAY) {
      newIntegrations[IntegrationName.EBAY] =
        integration as GetIntegrationResult<IntegrationName.EBAY>
    } else if (name === IntegrationName.ETSY) {
      newIntegrations[IntegrationName.ETSY] =
        integration as GetIntegrationResult<IntegrationName.ETSY>
    } else if (name === IntegrationName.SHOPIFY) {
      newIntegrations[IntegrationName.SHOPIFY] =
        integration as GetIntegrationResult<IntegrationName.SHOPIFY>
    } else if (name === IntegrationName.BIG_COMMERCE) {
      newIntegrations[IntegrationName.BIG_COMMERCE] =
        integration as GetIntegrationResult<IntegrationName.BIG_COMMERCE>
    } else if (name === IntegrationName.SQUARE) {
      newIntegrations[IntegrationName.SQUARE] =
        integration as GetIntegrationResult<IntegrationName.SQUARE>
    } else setIntegrations(newIntegrations)
  }

  const handleCreateIntegration = <T extends IntegrationName>(name: T) => {
    createIntegration(name)
      .then((res) => {
        if (res.success && res.data) {
          const getIntRes: GetIntegrationResult<T> = {
            integration: res.data,
            authenticationType: res.data.authenticationType,
            authenticated: false,
          }
          const name = res.data.name as IntegrationName
          handleSetIntegrations(name, getIntRes)
        } else if (!res.success) {
          log(res.data)
        }
      })
      .catch((e) => err(e))
  }

  useEffect(() => {
    getIntegrations({})
      .then((res) => {
        if (res.success && res.data) {
          setIntegrations(res.data)
        } else {
          log(res.message)
        }
      })
      .catch((e) => err(e))
  }, [])

  return (
    <>
      <NavBar />
      <div
        style={{
          padding: '2em',
        }}
      >
        <Container>
          <Component title="Integrations">
            <Grid container>
              <Grid item xs={12}>
                <Tabs
                  tabs={INTEGRATION_NAMES}
                  panels={[
                    <>
                      {integrations.ebay ? (
                        <EbayIntegration integration={integrations.ebay} />
                      ) : (
                        <CreateIntegration
                          name={IntegrationName.EBAY}
                          onCreate={handleCreateIntegration}
                        />
                      )}
                    </>,
                    <>
                      {integrations.etsy ? (
                        <EtsyIntegration integration={integrations.etsy} />
                      ) : (
                        <CreateIntegration
                          name={IntegrationName.ETSY}
                          onCreate={handleCreateIntegration}
                        />
                      )}
                    </>,
                    <>
                      {integrations.shopify ? (
                        <ShopifyIntegration
                          integration={integrations.shopify}
                        />
                      ) : (
                        <CreateIntegration
                          name={IntegrationName.SHOPIFY}
                          onCreate={handleCreateIntegration}
                        />
                      )}
                    </>,
                    <>
                      {integrations.bigcommerce ? (
                        <BigCommerceIntegration
                          integration={integrations.bigcommerce}
                        />
                      ) : (
                        <CreateIntegration
                          name={IntegrationName.BIG_COMMERCE}
                          onCreate={handleCreateIntegration}
                        />
                      )}
                    </>,
                    <>
                      {integrations.square ? (
                        <SquareIntegration integration={integrations.square} />
                      ) : (
                        <CreateIntegration
                          name={IntegrationName.SQUARE}
                          onCreate={handleCreateIntegration}
                        />
                      )}
                    </>,
                    <>
                      {integrations.clover ? (
                        <CloverIntegration integration={integrations.clover} />
                      ) : (
                        <CreateIntegration
                          name={IntegrationName.CLOVER}
                          onCreate={handleCreateIntegration}
                        />
                      )}
                    </>,
                  ]}
                ></Tabs>
              </Grid>
            </Grid>
            {/* Show available integrations, with create buttons */}
            <Box p={3}></Box>
          </Component>
        </Container>
      </div>
      <Box p={30}></Box>
    </>
  )
}

function CreateIntegration({
  name,
  onCreate,
}: {
  name: IntegrationName
  onCreate: (name: IntegrationName) => void
}): JSX.Element {
  return (
    <Grid container spacing={2} sx={{ mt: 1 }}>
      <Grid item xs={12}>
        <Typography variant="h6">
          No Integration Configured for{' '}
          {IntegrationDisplayName[name as IntegrationName] || name}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography></Typography>
      </Grid>

      <Grid item xs={12}>
        <Button variant="contained" onClick={() => onCreate(name)}>
          Create
        </Button>
      </Grid>
    </Grid>
  )
}
