import React from 'react'
import { HexColorPicker } from 'react-colorful'

interface ColorPickerProps {
  value: string | undefined
  onChange: (value: string) => void
}
export default function ColorPicker({
  value,
  onChange,
}: ColorPickerProps): JSX.Element {
  const handleChange = (newColor: string) => {
    onChange(newColor)
  }

  return <HexColorPicker color={value} onChange={handleChange} />
}
