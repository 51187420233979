import React from 'react'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { useEffect, useRef, useState } from 'react'
import { GetProductTemplate, getProductTemplates } from '../../api/product'
import Component from '../../components/common/Component'
import NavBar from '../../components/common/NavBar'
import { useHistory } from 'react-router-dom'
import Alert, { AlertInput } from '../../components/common/Alert'
import Fab from '@mui/material/Fab'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import Table from '../../components/common/Table'
import { err, isDefined } from '../../utils/functions'
import Tabs from '../../components/common/Tabs'
import {
  GetIntegrationsResult,
  IntegrationDisplayName,
  IntegrationName,
  getIntegrations,
} from '../../api/integration'
import Box from '@mui/material/Box'
import EbayImportOffers from '../../components/product/integrations/ebay/EbayImportOffers'
import CollapseTitle from '../../components/common/CollapseTitle'
import SquareImportItems from '../../components/product/integrations/square/SquareImportItems'
import ShopifyImportProducts from '../../components/product/integrations/shopify/ShopifyImportProducts'
import BigCommerceImportProducts from '../../components/product/integrations/bigcommerce/BigCommerceImportProducts'
import CloverImportItems from '../../components/product/integrations/clover/CloverImportProducts'

export default function AddProduct(): JSX.Element {
  const history = useHistory()
  const [alert, setAlert] = useState<AlertInput>({ open: false })

  const isMounted = useRef(true)
  const [templates, setTemplates] = useState<GetProductTemplate[]>([])
  const [integrations, setIntegrations] = useState<
    GetIntegrationsResult | undefined
  >()

  const [integrationsExpanded, setIntegrationsExpanded] =
    useState<boolean>(false)
  const handleTypeSelect = (templateId: number) => {
    history.push(`/newProduct/${templateId}`)
  }

  const handleInventory = () => {
    history.push('/products')
  }

  const templateNames = templates.map((t) => t.template.name)

  /* Get Product Templates */
  useEffect(() => {
    if (isMounted.current) {
      getProductTemplates()
        .then((res) => setTemplates(res?.data || []))
        .catch((e) => err(e))
      return () => {
        isMounted.current = false
      }
    }
  })

  useEffect(() => {
    if (isMounted.current) {
      getIntegrations()
        .then((res) => setIntegrations(res?.data))
        .catch((e) => err(e))
      return () => {
        isMounted.current = false
      }
    }
  })

  const tabs = integrations
    ? Object.keys(integrations)
        .map(
          (name) => IntegrationDisplayName[name as keyof GetIntegrationsResult],
        )
        .filter(isDefined)
    : []

  return (
    <div
      style={{
        width: '100%',
      }}
    >
      <NavBar />
      <Container sx={{ marginTop: 4 }}>
        <Component p={3}>
          <Grid container justifyContent="center" spacing={6}>
            <Grid item xs={12}>
              <Grid container alignItems="center" sx={{ position: 'relative' }}>
                <Grid item xs={12}>
                  <Typography variant="h5">
                    <Fab
                      variant="extended"
                      color="primary"
                      size="small"
                      sx={{
                        position: 'absolute',
                        left: 0,
                        paddingRight: '1em',
                      }}
                      onClick={handleInventory}
                    >
                      <ArrowBackIcon
                        fontSize="small"
                        sx={{ paddingRight: '0.25em' }}
                      />
                      Inventory
                    </Fab>
                    Add Inventory
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Grid
                container
                sx={{ marginTop: 2 }}
                spacing={2}
                justifyContent="center"
              >
                <Grid item xs={12}>
                  <Typography variant="h6">Create New Product</Typography>
                  <Typography>Select Template</Typography>
                </Grid>
                <Grid item xs={12} sx={{ position: 'relative' }}>
                  <Table
                    data={templates}
                    head={['Product Templates']}
                    row={(template) => [template.template.name]}
                    onRowClick={(template) =>
                      handleTypeSelect(template.template.id)
                    }
                    pageSize={10}
                  ></Table>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container justifyContent="center" spacing={3}>
                <Grid item xs={12}>
                  <CollapseTitle
                    title={
                      <Typography variant="h6" component="span">
                        Import Items
                      </Typography>
                    }
                    subtitle={
                      <Typography variant="body2" component="div">
                        Import items from integrated channels
                      </Typography>
                    }
                    expanded={false}
                    onClick={(expanded) => setIntegrationsExpanded(expanded)}
                  >
                    {integrationsExpanded ? (
                      <Tabs
                        tabs={tabs}
                        panels={
                          integrations
                            ? Object.entries(integrations).map(
                                ([name, integration]) => {
                                  return (
                                    <div key={name}>
                                      <Grid
                                        container
                                        justifyContent="center"
                                        spacing={3}
                                      >
                                        <Grid item xs={12}>
                                          {name === IntegrationName.EBAY ? (
                                            <EbayImportOffers
                                              templates={templateNames}
                                            />
                                          ) : null}
                                          {name === IntegrationName.SQUARE ? (
                                            <SquareImportItems
                                              templates={templateNames}
                                            />
                                          ) : null}
                                          {name === IntegrationName.SHOPIFY ? (
                                            <ShopifyImportProducts
                                              templates={templateNames}
                                            />
                                          ) : null}
                                          {name ===
                                          IntegrationName.BIG_COMMERCE ? (
                                            <BigCommerceImportProducts
                                              templates={templateNames}
                                            />
                                          ) : null}
                                          {name === IntegrationName.CLOVER ? (
                                            <CloverImportItems
                                              templates={templateNames}
                                            />
                                          ) : null}
                                        </Grid>
                                      </Grid>
                                    </div>
                                  )
                                },
                              )
                            : []
                        }
                      ></Tabs>
                    ) : null}
                  </CollapseTitle>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container justifyContent="center">
                <Grid item xs={12}>
                  <Typography variant="h6">Import from CSV</Typography>
                  <Typography variant="body2">
                    This feature is a work in progress. Please check back later.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Component>
        <Box m={30}></Box>
      </Container>
      <Alert
        alert={alert}
        onClose={() => setAlert({ ...alert, open: false })}
      ></Alert>
    </div>
  )
}
