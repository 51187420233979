import React, { useRef } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import type { Identifier, XYCoord } from 'dnd-core'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'

export const ItemTypes = {
  IMAGE: 'img',
}

const style = {
  border: '1px dotted lightgray',
  borderRadius: '3px',
  padding: '0.5rem 1rem',
  marginBottom: '.5rem',
  backgroundColor: 'white',
  cursor: 'move',
}

interface ImageDragProps {
  name?: string
  value: string | File
  selected?: boolean
  index: number
  onChange: (item: ImageDragItem, newIndex: number) => void
  onDelete: (index: number) => void
}

export type ImageDragItem = {
  value: string | File
  index: number
  name?: string
}

export default function ImageDrag({
  name,
  value,
  index,
  selected,
  onChange,
  onDelete,
}: ImageDragProps): JSX.Element {
  const ref = useRef<HTMLDivElement>(null)

  const [{ handlerId }, drop] = useDrop<
    ImageDragItem,
    void,
    { handlerId: Identifier | null }
  >({
    accept: ItemTypes.IMAGE,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      }
    },
    hover(item: ImageDragItem, monitor) {
      if (!ref.current) return
      const dragIndex = item.index
      const hoverIndex = index
      if (dragIndex === hoverIndex) return
      const hoverBoundingRect = ref.current?.getBoundingClientRect()
      const hoverMiddleX =
        (hoverBoundingRect.right - hoverBoundingRect.left) / 2
      const clientOffset = monitor.getClientOffset()

      const hoverClientX = (clientOffset as XYCoord).x - hoverBoundingRect.left
      if (dragIndex <= hoverIndex && hoverClientX <= hoverMiddleX) return
      if (dragIndex >= hoverIndex && hoverClientX >= hoverMiddleX) return

      onChange(item, hoverIndex)
      item.index = hoverIndex // update item
    },
  })

  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.IMAGE,
    item: () => {
      return { name, value, index }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  })

  drag(drop(ref))

  const opacity = isDragging ? 0.33 : 1
  return (
    <div ref={ref} style={{ ...style, opacity }} data-handler-id={handlerId}>
      <Stack direction="column">
        <Box
          width={150}
          height={150}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <img
            alt="Not Found"
            style={{
              maxWidth: 150,
              maxHeight: 150,
              borderRadius: 3,
            }}
            src={
              typeof value === 'string'
                ? value
                : URL.createObjectURL(value as Blob)
            }
          />
        </Box>

        {selected ? (
          <>
            <Typography variant="caption">{name || '-'}</Typography>
            <Button onClick={() => onDelete(index)}>Remove</Button>
          </>
        ) : (
          <Button onClick={() => onDelete(index)}>Delete</Button>
        )}
      </Stack>
    </div>
  )
}
