import React from 'react'
import Autocomplete from '@mui/material/Autocomplete'
import Chip from '@mui/material/Chip'
import TextField from '@mui/material/TextField'

interface MultipleSelectProps {
  label: string
  value: string[]
  options?: string[]
  freeSolo?: boolean
  onChange: (values: string[]) => void
}
export function MultipleSelect({
  label,
  value,
  options,
  freeSolo,
  onChange,
}: MultipleSelectProps): JSX.Element {
  return (
    <Autocomplete
      value={value}
      options={options || []}
      freeSolo={freeSolo}
      multiple
      autoHighlight={true}
      isOptionEqualToValue={(option, value) => option === value}
      onChange={(e, values) => {
        onChange(values)
      }}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip
            {...getTagProps({ index })}
            key={option}
            variant="outlined"
            label={option}
            style={{
              height: '29px',
              marginBottom: '1px',
              marginTop: '2px',
              fontSize: '15px',
            }}
          />
        ))
      }
      renderInput={(params) => (
        <TextField {...params} fullWidth label={label} size="small" />
      )}
    ></Autocomplete>
  )
}
