import React, { useEffect, useState } from 'react'
import qs from 'qs'
import {
  IntegrationDisplayName,
  IntegrationName,
  authenticateOAuth,
  getOAuthUrl,
} from '../../api/integration'
import { err } from '../../utils/functions'
import Component from '../../components/common/Component'
import NavBar from '../../components/common/NavBar'
import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { AxiosError } from 'axios'
import { APIResult } from '../../utils/types'
import { useParams } from 'react-router-dom'

// Get query params outside of component to avoid re-renders
const params = qs.parse(window.location.search, { ignoreQueryPrefix: true })

export default function OAuthHandler(): JSX.Element | null {
  const { channel, dev } = useParams<{ channel: string; dev?: string }>()
  const integrationName = channel as IntegrationName

  const [message, setMessage] = useState<string>('')
  const [error, setError] = useState<string>('')

  // redirect to localhost when in development
  if (dev) {
    const path = `/oauth/${channel}`
    const search = window.location.search
    const localhost = 'http://localhost:3001'
    window.location.href = localhost + path + search
  }

  useEffect(() => {
    console.log(channel === IntegrationName.BIG_COMMERCE && params)

    // oauth
    if (channel === IntegrationName.BIG_COMMERCE && params) {
      console.log('Auth BigCommerce')
      authenticateOAuth(integrationName, params)
        .then((res) => {
          if (res.success) {
            setMessage(
              `Integration successful! Configure Integration <a href="/integrations"></a>`,
            )
          } else {
            setError(res.message || '')
          }
        })
        .catch((e: unknown) => {
          const errorMessage = (e as AxiosError<APIResult>)?.response?.data
            ?.message
          if (errorMessage) {
            setError(errorMessage)
          }
        })
      return
    }

    // All other channels

    if (!params.code) {
      // No code, authenticate the request and redirect to authUrl
      getOAuthUrl(integrationName, params)
        .then((res) => {
          if (res.success && res.data) {
            const url = res.data
            try {
              new URL(url)
            } catch {
              setError('Did not receive valid redirect URL from server.')
            }
            window.location.href = url
          } else {
            setError(res.message || '')
          }
        })
        .catch((e) => err(e))
    } else if (params) {
      authenticateOAuth(integrationName, params)
        .then((res) => {
          if (res.success) {
            window.close()
          } else {
            setError(res.message || '')
          }
        })
        .catch((e: unknown) => {
          const errorMessage = (e as AxiosError<APIResult>)?.response?.data
            ?.message
          if (errorMessage) {
            setError(errorMessage)
          }
        })
    }
  }, [channel, integrationName])

  return (
    <div
      style={{
        width: '100%',
      }}
    >
      <NavBar />
      <Container>
        <div
          style={{
            padding: '1em',
            paddingTop: '2em',
          }}
        >
          <Component>
            <div>
              Authorizing with {IntegrationDisplayName[integrationName] || ''}{' '}
              OAuth Authentication. You will be redirected shortly.
            </div>
            <Box m={2}>
              {message ? (
                <>
                  <Typography>{message}</Typography>
                </>
              ) : null}
            </Box>
            <Box m={2}>
              {error ? (
                <>
                  <strong>Error: </strong>
                  <Typography
                    dangerouslySetInnerHTML={{
                      __html: error,
                    }}
                  />
                </>
              ) : null}
            </Box>
          </Component>
        </div>
      </Container>
    </div>
  )
}
