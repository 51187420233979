import React, { useEffect, useState } from 'react'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import { Integration } from '../../../../api/types'
import Grid from '@mui/material/Grid'
import {
  getInventoryLocation,
  getListingPolicies,
  getNotificationPreferences,
  setInventoryLocation,
  setListingPolicies,
  setNotificationPreferences,
} from '../../../../api/integrations/ebay'
import { err, log } from '../../../../utils/functions'
import { CountryCodeEnum, ListingPolicies } from '../../../../types/Ebay.types'
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material'
import Alert, { AlertInput, handleAlert } from '../../../common/Alert'

interface EbayPoliciesProps {
  integration: Integration | undefined
}
export default function EbayPolicies({
  integration,
}: EbayPoliciesProps): JSX.Element | null {
  // const [location, setLocation] = useState<InventoryLocation>()
  const [listingPoliciesState, setListingPoliciesState] =
    useState<ListingPolicies>()
  const [country, setCountry] = useState<CountryCodeEnum>(CountryCodeEnum.US)
  const [postalCode, setPostalCode] = useState<string>('')
  const [alert, setAlert] = useState<AlertInput>({ open: false })

  const handleSetInventoryLocation = () => {
    if (!country || !postalCode) {
      return
    }
    setInventoryLocation({ country, postalCode })
      .then((res) => {
        handleAlert(setAlert, res, 'Inventory location set.')
      })
      .catch((e) => err(e))
  }

  const handleSetDefaultPolicies = () => {
    setListingPolicies()
      .then((res) => {
        handleAlert(setAlert, res, 'Inventory location set.')
      })
      .catch((e) => err(e))
  }

  const handleGetNotificationPreferences = () => {
    getNotificationPreferences()
      .then((res) => {
        handleAlert(setAlert, res, `Notifications Preferences: ${res.data}`)
      })
      .catch((e) => err(e))
  }

  const handleSetNotificationPreferences = () => {
    setNotificationPreferences()
      .then((res) => {
        handleAlert(setAlert, res, `Notifications set. ${res.data}`)
      })
      .catch((e) => err(e))
  }

  useEffect(() => {
    getListingPolicies()
      .then((res) => {
        if (res.success && res.data) {
          setListingPoliciesState(res.data)
        }
      })
      .catch((e) => err(e))
    return
  }, [])

  useEffect(() => {
    getInventoryLocation()
      .then((res) => {
        if (res.success && res.data) {
          setCountry(res.data.location.address.country as CountryCodeEnum)
          setPostalCode(res.data.location.address.postalCode)
        } else {
          log(res.message)
        }
      })
      .catch((e) => err(e))
    return
  }, [])

  return (
    <Grid container justifyContent="center" spacing={8}>
      <Grid item xs={12}>
        <Grid container justifyContent="center" spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6">Ebay Listing Policies</Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography>
              Your store must have payment, shipping, and return policies.{' '}
              <a
                href="https://www.bizpolicy.ebay.com/businesspolicy/manage"
                target="_blank"
                rel="noreferrer"
              >
                Edit on Ebay
              </a>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {listingPoliciesState ? (
              <>
                <Typography>
                  Payment Policy:{' '}
                  {listingPoliciesState.paymentPolicies?.[0]?.name}
                </Typography>
                <Typography>
                  Return Policy:{' '}
                  {listingPoliciesState.returnPolicies?.[0]?.name}
                </Typography>
                <Typography>
                  Shipping Policy:{' '}
                  {listingPoliciesState.fulfillmentPolicies?.[0]?.name}
                </Typography>
              </>
            ) : (
              <Button
                variant="contained"
                onClick={() => {
                  handleSetDefaultPolicies()
                }}
              >
                Set Default Policies
              </Button>
            )}
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid container justifyContent="center" spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6">Ebay Inventory Location</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography>
              Your store must have a location.{' '}
              <a
                href="https://accountsettings.ebay.com/uas/addresses"
                target="_blank"
                rel="noreferrer"
              >
                Edit on Ebay
              </a>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid
              container
              justifyContent="center"
              spacing={3}
              direction="column"
            >
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel id="countries-label">Country Code</InputLabel>
                  <Select
                    labelId="countries-label"
                    label="Country Code"
                    id="countries"
                    value={country}
                    onChange={(e) =>
                      setCountry(e.target.value as CountryCodeEnum)
                    }
                    size="small"
                  >
                    {Object.keys(CountryCodeEnum).map((c) => (
                      <MenuItem key={c} value={c}>
                        {c}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Postal Code"
                  size="small"
                  fullWidth
                  value={postalCode}
                  onChange={(e) => setPostalCode(e.target.value)}
                ></TextField>
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  onClick={() => {
                    handleSetInventoryLocation()
                  }}
                >
                  Set Location
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6">Ebay Notifications</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography>
              Notfications allow us to recieve live updates when an item is
              sold.
              <br />
              Set up this configuration by clicking below.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              onClick={() => {
                handleSetNotificationPreferences()
              }}
            >
              Set Notification Preferences
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              onClick={() => {
                handleGetNotificationPreferences()
              }}
            >
              Get Notification Preferences
            </Button>
          </Grid>
        </Grid>
      </Grid>

      {/* <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6">Validate Test User</Typography>
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              onClick={() => {
                handleValidateUser()
              }}
            >
              Validate
            </Button>
          </Grid>
        </Grid>
      </Grid> */}
      <Alert
        alert={alert}
        onClose={() => setAlert({ ...alert, open: false })}
      ></Alert>
    </Grid>
  )
}
