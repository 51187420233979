import React, { ReactNode, useRef } from 'react'
import { DragSourceMonitor, useDrag, useDrop } from 'react-dnd'
import type { Identifier, XYCoord } from 'dnd-core'

export const DragType = {
  TYPE: 'drag',
}

const style = {
  border: '1px dotted lightgray',
  borderRadius: '3px',
  // padding: '1rem 1rem',
  backgroundColor: 'white',
  cursor: 'move',
}

interface DraggableProps {
  index: number
  onChange: (index: number, newIndex: number) => void
  children: ReactNode
  id: string
}

export type DraggableItem = {
  index: number
  id: string
  type: string
}

export default function Draggable({
  index,
  id,
  children,
  onChange,
}: DraggableProps): JSX.Element {
  const ref = useRef<HTMLDivElement>(null)

  const [{ handlerId }, drop] = useDrop<
    DraggableItem,
    void,
    { handlerId: Identifier | null }
  >({
    accept: DragType.TYPE,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      }
    },
    hover(item: DraggableItem, monitor) {
      if (!ref.current) return
      const dragIndex = item.index
      const hoverIndex = index
      if (dragIndex === hoverIndex) return
      const hoverBoundingRect = ref.current?.getBoundingClientRect()
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      const clientOffset = monitor.getClientOffset()

      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) return
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) return

      item.index = hoverIndex // update item
      onChange(dragIndex, hoverIndex)
    },
  })

  const [{ isDragging }, drag] = useDrag({
    type: DragType.TYPE,
    item: () => {
      return { index, id }
    },
    collect: (monitor: DragSourceMonitor) => ({
      isDragging: monitor.isDragging(),
    }),
  })

  drag(drop(ref))

  const opacity = isDragging ? 0.33 : 1
  return (
    <div ref={ref} style={{ ...style, opacity }} data-handler-id={handlerId}>
      {children}
    </div>
  )
}
