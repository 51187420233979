import { APIResult } from '../../utils/types'
import instance from '..'
import { getResult } from '../../utils/functions'
import {
  ShopifyLocation,
  ShopifyProduct,
  ShopifyProductIntegration,
  ShopifyTemplateIntegration,
} from '../../types/Shopify.types'
import { DataFields, Integration } from '../types'

export function getShopifyLocations(): Promise<APIResult<ShopifyLocation[]>> {
  return instance
    .get('/api/integration/shopify/locations')
    .then((res) => getResult(res))
}

export async function shopifyBulkImport(params?: {
  ids?: number[]
  template?: string
  updateExistingProducts?: boolean
}): Promise<APIResult<void>> {
  const result = await instance
    .post('/api/integration/shopify/bulkImport', params)
    .then((res) => getResult<void>(res))
  return result
}

export interface GetShopifyProducts {
  products: ShopifyProduct[]
  sinceId?: number
}

export async function getShopifyProducts(params: {
  sinceId?: number
  limit?: number
}): Promise<APIResult<GetShopifyProducts>> {
  const result = await instance
    .get('/api/integration/shopify/products', {
      params,
    })
    .then((res) => getResult<GetShopifyProducts>(res))
  return result
}

export type GetShopifyIntegrationsResult = {
  integration?: Integration
  templateIntegration?: ShopifyTemplateIntegration
  productIntegration?: ShopifyProductIntegration
}
export async function getShopifyIntegrations(
  integrationId: number,
  templateId?: number,
  productId?: number,
): Promise<APIResult<GetShopifyIntegrationsResult>> {
  const setTemplateIntegration = await instance
    .get('/api/integration/shopify/integrations', {
      params: {
        integrationId,
        templateId,
        productId,
      },
    })
    .then((res) => getResult<GetShopifyIntegrationsResult>(res))
  return setTemplateIntegration
}

export async function setShopifyTemplateIntegration(
  templateIntegration?: DataFields<
    Omit<ShopifyTemplateIntegration, 'clientId'>
  >,
): Promise<APIResult<ShopifyTemplateIntegration>> {
  const setTemplateIntegration = await instance
    .post('/api/integration/shopify/templateIntegration', {
      templateIntegration,
    })
    .then((res) => getResult<ShopifyTemplateIntegration>(res))
  return setTemplateIntegration
}

export async function setShopifyProductIntegration(
  productIntegration?: DataFields<Omit<ShopifyProductIntegration, 'clientId'>>,
): Promise<APIResult<ShopifyProductIntegration>> {
  const setProductIntegration = await instance
    .post('/api/integration/shopify/productIntegration', {
      productIntegration,
    })
    .then((res) => getResult<ShopifyProductIntegration>(res))
  return setProductIntegration
}
