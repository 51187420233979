import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import React from 'react'
import { GetProduct } from '../../../../api/product'

interface ListUnlistButtonsProps {
  list?: boolean
  hasProductIntegration?: boolean
  product?: GetProduct
  onList?: () => void
  onUnlist?: () => void
}

export function ListUnlistButtons({
  list,
  hasProductIntegration,
  product,
  onList,
  onUnlist,
}: ListUnlistButtonsProps): JSX.Element {
  if (!product) return <></>

  return (
    <Grid container justifyContent="space-between" spacing={3}>
      <Grid item>
        <Button
          variant="outlined"
          size="small"
          onClick={onUnlist}
          disabled={!hasProductIntegration}
        >
          Unlist
        </Button>
      </Grid>
      <Grid item>
        <Button
          variant="contained"
          size="small"
          onClick={onList}
          disabled={!list}
        >
          List / Sync
        </Button>
      </Grid>
    </Grid>
  )
}
