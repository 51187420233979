import React, { useEffect, useMemo, useState } from 'react'
import {
  ProductTemplateAttributeInput,
  SetProductAttribute,
  updateProductTemplateAttributes,
} from '../../../api/product'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import Autocomplete from '@mui/material/Autocomplete'
import { RequiredIntegrationTemplateAttributes } from '../../../api/integrations/ebay'
import { darken, lighten, styled } from '@mui/material/styles'
import Button from '@mui/material/Button'
import { DEFAULT_ATTRIBUTE_TYPE } from '../../../utils/constants'
import { ProductTemplateAttribute } from '../../../api/types'
import { copy } from '../../../utils/functions'

interface OptionValue {
  value: string
  source: OptionGroupsEnum
}

enum OptionGroupsEnum {
  OPTION = 'Options',
  EBAY = 'Ebay Example Values',
}
interface ProductAttributeInputFieldProps {
  id?: string
  name: string
  value?: string
  attributes: SetProductAttribute[]
  options?: string[]
  onChange: (name: string, value: string) => void
  onSelection?: (next?: boolean) => void
  onUpdate?: () => void
  disabled?: boolean
  requiredAttributes: RequiredIntegrationTemplateAttributes[]
}

export default function ProductAttributeInputField({
  id,
  name,
  value: defaultValue,
  attributes,
  options,
  onChange,
  onSelection,
  onUpdate,
  disabled = false,
  requiredAttributes,
}: ProductAttributeInputFieldProps): JSX.Element | null {
  const getAttribute = (
    attributes: SetProductAttribute[],
    name: string,
  ): SetProductAttribute | undefined => {
    const attribute = attributes.find(
      (attribute) => attribute.templateAttribute.name === name,
    )
    if (!attribute) return undefined
    return {
      attribute: attribute.attribute,
      templateAttribute: attribute.templateAttribute,
      templateAttributeOptions: attribute?.templateAttributeOptions,
    }
  }

  const getRequiredAttribute = (
    requiredAttributes: RequiredIntegrationTemplateAttributes[],
    name: string,
  ): RequiredIntegrationTemplateAttributes | undefined => {
    return requiredAttributes.find((reqAttribute) =>
      reqAttribute.templateAttributeNames.includes(name),
    )
  }

  const [requiredAttribute, setRequiredAttribute] = useState<
    RequiredIntegrationTemplateAttributes | undefined
  >()
  const [attribute, setAttribute] = useState<SetProductAttribute | undefined>()
  const [value, setValue] = useState<string>(defaultValue || '')
  const [requiredText, setRequiredText] = useState<string | undefined>(
    undefined,
  )
  const [warningText, setWarningText] = useState<string | undefined>(undefined)

  const [optionValues, setOptionValues] = useState<OptionValue[]>([])

  useMemo(() => {
    const attributeOptionValues = attribute?.templateAttributeOptions
      ? attribute?.templateAttributeOptions
          .map((o) => ({ value: o.value, source: OptionGroupsEnum.OPTION }))
          .sort((a, b) => a.value.localeCompare(b.value))
      : []

    const lowercaseValue = value.toLowerCase()
    const ebayOptions = options
      ?.filter((o) => o.toLowerCase().startsWith(lowercaseValue))
      ?.slice(0, 50)
      ?.filter(
        (o) =>
          !attributeOptionValues.find(
            (attributeOption) => attributeOption.value === o,
          ),
      )
      .map((ebayOption) => ({
        value: ebayOption,
        source: OptionGroupsEnum.EBAY,
      }))

    setOptionValues(attributeOptionValues.concat(ebayOptions || []))
  }, [attribute?.templateAttributeOptions, options, value])

  useEffect(() => {
    const newAttr = getAttribute(attributes, name)
    setAttribute(newAttr)
    defaultValue ?? setValue(newAttr?.attribute?.value || '')
  }, [name, defaultValue, attributes])
  useEffect(() => {
    setRequiredAttribute(getRequiredAttribute(requiredAttributes, name))
  }, [requiredAttributes, name])

  useEffect(() => {
    if (
      !value &&
      requiredAttribute?.aspect &&
      requiredAttribute.aspect?.required
    ) {
      const requiredTextValue = `Attribute is required. Used in Ebay Aspect "${
        requiredAttribute.aspect.aspectName || 'Unknown Aspect'
      }" as ${requiredAttribute.aspect.value}`
      setRequiredText(requiredTextValue)
    } else if (value) {
      setRequiredText('')
    }
  }, [requiredAttribute, value])

  useEffect(() => {
    if (
      !value &&
      requiredAttribute?.aspect &&
      !requiredAttribute.aspect.required
    ) {
      const warningTextValue = `Attribute is recommended. Used in Ebay Aspect "${
        requiredAttribute.aspect.aspectName || 'Unknown Aspect'
      }" as ${requiredAttribute.aspect.value}`
      setWarningText(warningTextValue)
    } else if (!value && requiredAttribute) {
      const warningTextValue =
        'Attribute is reccommended. Used in Title or Description.'
      setWarningText(warningTextValue)
    } else if (value) {
      setWarningText('')
    }
  }, [requiredAttribute, value])

  const handleChange = (value: string) => {
    setValue(value)
  }

  const handleKeyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (!e.shiftKey && e.key === 'Enter') {
      onSelection?.(true)
    } else if (e.shiftKey && e.key === 'Enter') {
      onSelection?.(false)
    }
  }

  const handleAddTemplateAttributeOption = async (option: string) => {
    if (!attribute) return
    // Get template id from template attribute
    const templateId = attribute.templateAttribute.templateId
    const templateAttributeName = attribute.templateAttribute.name

    const templateAttribute = attribute.templateAttribute
    const newOptionIndex = attribute.templateAttributeOptions.length // length+1
    if (!templateId || !templateAttributeName) return

    // add attribute with option
    const addTemplateAttr: ProductTemplateAttributeInput = {
      attribute: templateAttribute,
      attributeOptions: attribute.templateAttributeOptions.concat({
        value: option,
        type: DEFAULT_ATTRIBUTE_TYPE,
        index: newOptionIndex,
      }),
    }

    const newAttribute = copy(attribute)
    newAttribute.templateAttribute =
      addTemplateAttr.attribute as ProductTemplateAttribute
    newAttribute.templateAttributeOptions = addTemplateAttr.attributeOptions
    setAttribute(newAttribute)
    const res = await updateProductTemplateAttributes(templateId, [
      addTemplateAttr,
    ])
    if (res.success) {
      console.log('success')
    } else {
      console.log('Error')
    }
    onUpdate?.()
  }

  return (
    <Grid container spacing={1} justifyContent="center">
      <Grid item xs={12} sm={12} md={3} lg={2}>
        <Typography sx={{ mt: 1 }}>
          <strong>
            {name}
            {attribute?.templateAttribute.constraint === 'required'
              ? ' **'
              : ''}
            {attribute?.templateAttribute.constraint === 'recommended'
              ? ' *'
              : ''}
          </strong>
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={9} lg={10}>
        <Autocomplete
          openOnFocus={true}
          // selectOnFocus={true}
          clearOnEscape={true}
          id={id}
          value={value}
          options={optionValues}
          groupBy={(option) => option.source}
          freeSolo
          limitTags={5}
          disabled={disabled}
          onChange={(e, v: OptionValue | string | null) => {
            if (v && typeof v !== 'string') {
              const optionValue = v as OptionValue
              setValue(value)
              onChange(name, optionValue.value)
              onSelection?.()
              setRequiredText('')
              setWarningText('')
            }
          }}
          onInput={(e) => {
            handleChange((e.target as HTMLInputElement).value)
          }}
          onBlur={() => {
            setValue(value)
            // onChange(name, value)
          }}
          isOptionEqualToValue={(
            option: OptionValue,
            value: OptionValue | string,
          ) => {
            if (typeof value === 'string') {
              return value === option.value
            }
            if (value.value === option.value) {
              return true
            }
            return false
          }}
          renderGroup={(params) => (
            <li key={params.key}>
              <GroupHeader>{params.group}</GroupHeader>
              <GroupItems>{params.children}</GroupItems>
            </li>
          )}
          getOptionLabel={(option: OptionValue | string) => {
            if (typeof option !== 'string') {
              return (option as OptionValue).value
            } else {
              return option
            }
          }}
          renderOption={(props, option: OptionValue) => (
            <li {...props}>
              <Grid container justifyContent="space-between">
                <Grid item>{option.value}</Grid>
                {option.source !== OptionGroupsEnum.OPTION ? (
                  <Grid item>
                    <Button
                      size="small"
                      onClick={(e) => {
                        e.stopPropagation()
                        void handleAddTemplateAttributeOption(option.value)
                      }}
                    >
                      Add
                    </Button>
                  </Grid>
                ) : null}
              </Grid>
            </li>
          )}
          renderInput={(a) => (
            <TextField
              {...a}
              fullWidth
              label={name}
              size="small"
              value={value}
              helperText={requiredText || warningText}
              error={!!requiredText}
              color={warningText ? 'warning' : undefined}
              onKeyDown={(e) => handleKeyPress(e)}
              onChange={(e) => {
                const value = (e.target as HTMLInputElement).value
                setValue(value)
                onChange(name, value)
              }}
            />
          )}
        ></Autocomplete>
      </Grid>
    </Grid>
  )
}

const GroupHeader = styled('div')(({ theme }) => ({
  position: 'sticky',
  top: '-8px',
  padding: '4px 10px',
  color: theme.palette.primary.main,
  backgroundColor:
    theme.palette.mode === 'light'
      ? lighten(theme.palette.primary.light, 0.85)
      : darken(theme.palette.primary.main, 0.8),
}))

const GroupItems = styled('ul')({
  padding: 0,
  backgroundColor: '#fff',
  li: '1px solid red',
})
