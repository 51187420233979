import React, { useState } from 'react'
import { GetIntegrationResult, IntegrationName } from '../../api/integration'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import { shopifyBulkImport } from '../../api/integrations/shopify'
import Alert, { AlertInput, handleAlert } from '../../components/common/Alert'
import { err } from '../../utils/functions'

export default function ShopifyIntegration({
  integration,
}: {
  integration: GetIntegrationResult<IntegrationName.SHOPIFY>
}): JSX.Element {
  const [alert, setAlert] = useState<AlertInput>({ open: false })

  const handleAppStoreClick = () => {
    if (!integration.oAuthUrl) return
    window.open(integration.oAuthUrl, '_blank')
  }

  const handleBulkImport = () => {
    shopifyBulkImport()
      .then((res) => {
        handleAlert(setAlert, res, 'Bulk import process started.')
      })
      .catch((e) => err(e))
  }

  return (
    <Grid container spacing={2}>
      {!integration.authenticated ? (
        <Grid item xs={12}>
          <Grid container justifyContent="center" spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h6">Authentication</Typography>
              <Typography>
                To authenticate app access, please add the TeamAutomate Shopify
                app to your store.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Button variant="contained" onClick={handleAppStoreClick}>
                Open App Store
              </Button>
            </Grid>
          </Grid>
        </Grid>
      ) : null}

      {integration.authenticated ? (
        <Grid item xs={12}>
          {integration.integration?.shop ? (
            <Grid container justifyContent="center">
              <Grid item xs={12}>
                <Typography variant="h6">Shop Details</Typography>
              </Grid>

              <Grid item xs={12}>
                <Typography>
                  {integration.integration.shop || 'Shop name not set.'}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2">
                  Authentication Last Updated:{' '}
                  {integration.integration?.updatedAt
                    ? new Date(
                        integration.integration.updatedAt,
                      ).toLocaleString()
                    : 'Unknown'}
                </Typography>
              </Grid>
            </Grid>
          ) : null}
        </Grid>
      ) : null}
      <Grid item xs={12}></Grid>
      <Grid item xs={12}></Grid>
      <Grid item xs={12}>
        <Button variant="contained" onClick={handleBulkImport}>
          Bulk Import Inventory
        </Button>
      </Grid>
      <Alert
        alert={alert}
        onClose={() => setAlert({ ...alert, open: false })}
      ></Alert>
    </Grid>
  )
}
