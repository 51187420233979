import React from 'react'
import { GetIntegrationResult, IntegrationName } from '../../api/integration'

export default function EtsyIntegration({
  integration,
}: {
  integration: GetIntegrationResult<IntegrationName.ETSY>
}): JSX.Element {
  console.log(integration)

  return <div>etsy</div>
}
