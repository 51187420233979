import React, { useState } from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import EbayAuthorization from '../../components/product/integrations/ebay/EbayAuthorization'
import { GetIntegrationResult, IntegrationName } from '../../api/integration'
import EbayPolicies from '../../components/product/integrations/ebay/EbayPolicies'
import Button from '@mui/material/Button'
import { err } from '../../utils/functions'
import { ebayBulkImport } from '../../api/integrations/ebay'
import Alert, { AlertInput, handleAlert } from '../../components/common/Alert'

export default function EbayIntegration({
  integration,
}: {
  integration: GetIntegrationResult<IntegrationName.EBAY>
}): JSX.Element {
  const [alert, setAlert] = useState<AlertInput>({ open: false })

  const handleBulkImport = () => {
    ebayBulkImport()
      .then((res) => {
        handleAlert(setAlert, res, 'Bulk import process started.')
      })
      .catch((e) => err(e))
  }
  return (
    <Grid
      container
      spacing={8}
      sx={{ mt: 1 }}
      key={integration.integration?.id}
    >
      <Grid item xs={12}>
        <Typography variant="h5">Authentication</Typography>

        {integration.authenticated && integration.oAuthUrl ? (
          <>
            <Typography>
              Third-party-app access{' '}
              <a
                href="https://accounts.ebay.com/acctsec/security-center/third-party-app-access"
                target="_blank"
                rel="noreferrer"
              >
                Edit on Ebay
              </a>
            </Typography>
            <Typography>
              If encountering authentication errors, you may need to
              re-authorize
            </Typography>
          </>
        ) : null}
        <EbayAuthorization
          integration={integration.integration}
          authorized={integration.authenticated}
          oAuthUrl={integration.oAuthUrl}
        />
      </Grid>
      <Grid item xs={12}>
        <EbayPolicies integration={integration.integration} />
      </Grid>
      <Grid item xs={12}>
        <Grid container justifyContent="center" spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h5">Import Inventory</Typography>
            <Typography>
              Import Inventory from Ebay. Template and Products will be created.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Button variant="contained" onClick={handleBulkImport}>
              Bulk Import Inventory
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Alert
        alert={alert}
        onClose={() => setAlert({ ...alert, open: false })}
      ></Alert>
    </Grid>
  )
}
