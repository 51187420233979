import React, { useEffect, useRef } from 'react'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { useState } from 'react'
import {
  createProductTemplate,
  CreateProductTemplateInput,
  GetProductTemplateAttribute,
  SetProductTemplateAttribute,
} from '../../api/product'
import Component from '../../components/common/Component'
import NavBar from '../../components/common/NavBar'
import Alert, { AlertInput } from '../../components/common/Alert'
import Box from '@mui/material/Box'
import { useHistory } from 'react-router-dom'
import { err } from '../../utils/functions'
import { newAttribute } from './ProductTemplate'

export const STOCK_ATTRIBUTES = [
  'Brand',
  'Category',
  'Condition Description',
  'Vendor',
  'Location',
  'Wharehouse',
  'Bin',
]

export function getStockAttributes(
  attributes: GetProductTemplateAttribute[],
): SetProductTemplateAttribute[] {
  const existingAttributeNames = attributes.map((a) => a.attribute.name)
  const stockAttributes = STOCK_ATTRIBUTES.filter(
    (name) => !existingAttributeNames.includes(name),
  ).map((name, i) =>
    newAttribute({
      name,
      templateId: attributes[0]?.attribute.templateId,
      index: i,
    }),
  )
  return stockAttributes
}

export default function CreateTemplate(): JSX.Element {
  const history = useHistory()
  const isMounted = useRef(true)
  // Product Template
  const [name, setName] = useState<string>('')
  const [nameError, setNameError] = useState<string>('')
  const [description, setDescription] = useState<string>('')
  const [notes, setNotes] = useState<string | undefined>()
  const [tags, setTags] = useState<string>('')

  const [templateId, setProductTemplateId] = useState<number>()
  const [alert, setAlert] = useState<AlertInput>({ open: false })

  useEffect(() => {
    if (templateId !== undefined) {
      history.push(`template/${templateId}`)
    }
  }, [history, templateId])

  const handleSubmit = () => {
    if (isMounted.current) {
      if (!name.trim()) {
        setNameError('Name field is required.')
        return
      }

      const templateAttributes = STOCK_ATTRIBUTES.map(
        (name, i) =>
          newAttribute({ name, value: '', type: 'text', index: i }).attribute,
      )
      const template: CreateProductTemplateInput = { name, description }
      createProductTemplate(template, templateAttributes)
        .then((result) => {
          if (result?.success) {
            const templateId = result.data?.id
            if (templateId) {
              setAlert({
                open: true,
                message: 'Template Created.',
                severity: 'success',
              })
              setProductTemplateId(templateId)
            } else {
              setAlert({
                open: true,
                message: 'Unknown response from server.',
                severity: 'error',
              })
            }
          } else {
            setAlert({
              open: true,
              message: result?.message || 'Error',
              severity: 'error',
            })
          }

          return () => {
            isMounted.current = false
          }
        })
        .catch((e) => err(e))
    }
  }

  return (
    <div
      style={{
        width: '100%',
      }}
    >
      <NavBar />
      <Container sx={{ marginTop: 4 }}>
        <Component>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Grid container spacing={2} justifyContent="center">
                <Grid item xs={12}>
                  <Typography variant="h6">Create Template</Typography>
                  <Typography variant="body1">
                    Set a descriptive name, description, notes, and tags.
                    <br />
                    Additional details on next page.
                  </Typography>
                </Grid>
                <Grid item xs={12} md={8}>
                  <TextField
                    label="Template Name"
                    size="small"
                    required
                    value={name}
                    fullWidth
                    autoComplete="off"
                    onChange={(e) => setName(e.target.value)}
                    error={!!nameError}
                    helperText={nameError}
                    onClick={() => {
                      setNameError('')
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={8}>
                  <TextField
                    label="Template Description"
                    size="small"
                    multiline
                    minRows={10}
                    fullWidth
                    value={description}
                    autoComplete="off"
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} md={8}>
                  <TextField
                    label="Notes"
                    size="small"
                    multiline
                    minRows={5}
                    fullWidth
                    value={notes}
                    autoComplete="off"
                    onChange={(e) => setNotes(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} md={8}>
                  <TextField
                    label="Tags"
                    size="small"
                    fullWidth
                    autoComplete="off"
                    value={tags}
                    onChange={(e) => setTags(e.target.value)}
                    helperText="Comma separeted"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Grid container justifyContent="flex-end">
                    <Button onClick={handleSubmit} variant="contained">
                      Next
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              <Box sx={{ height: '100px' }} />
            </Grid>
          </Grid>
        </Component>
        <Box p={30}></Box>
      </Container>
      <Alert
        alert={alert}
        onClose={() => setAlert({ ...alert, open: false })}
      ></Alert>
    </div>
  )
}
