import * as React from 'react'
import {
  GetProductTemplateAttribute,
  SetProductTemplateAttribute,
} from '../../api/product'
import { ProductTemplateAttributeTypes } from '../../api/types'
import { ProductTemplateAttributeInputField } from './ProductTemplateAttributeInputField'

interface ProductTemplateDefaultAttributeInputFieldProps {
  name: string
  attributes: SetProductTemplateAttribute[] | GetProductTemplateAttribute[]
  onChange: (
    attribute: SetProductTemplateAttribute | undefined,
    name: string,
  ) => void
  disabled?: boolean
}

export default function ProductTemplateDefaultAttributeInputField({
  name,
  attributes,
  onChange,
  disabled = false,
}: ProductTemplateDefaultAttributeInputFieldProps): JSX.Element | null {
  const getAttribute = (
    attributes: SetProductTemplateAttribute[],
    name: string,
  ): SetProductTemplateAttribute => {
    const exists = attributes.find(
      (attribute) => attribute.attribute.name === name,
    )

    const newIndex = attributes.length - 1
    if (exists) {
      return exists
    } else {
      const newAttr = newAttribute({ name, index: newIndex })
      return newAttr
    }
  }

  const newAttribute = (opts: {
    name?: string
    value?: string
    index: number
    type?: ProductTemplateAttributeTypes
  }): SetProductTemplateAttribute => {
    return {
      attribute: {
        name: opts?.name || '',
        value: opts?.value || '',
        type: opts?.type || 'text',
        index: opts.index,
      },
      attributeOptions: [],
    }
  }

  const attribute = getAttribute(attributes, name)

  if (!attribute) return null
  return (
    <ProductTemplateAttributeInputField
      attribute={attribute}
      nameEditable={false}
      disabled={disabled}
      onChange={(attribute) => onChange(attribute, name)}
    />
  )
}
