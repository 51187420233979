import React, { useMemo } from 'react'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { useHistory, useParams } from 'react-router-dom'
import { useEffect, useRef, useState } from 'react'
import {
  deleteProductTemplate,
  GetProductTemplate,
  getProductTemplate,
  ProductTemplateInput,
  updateProductTemplate,
  SetProductTemplateAttribute,
  duplicateProductTemplate,
  mergeTemplateAttributes,
} from '../../api/product'
import Component from '../../components/common/Component'
import NavBar from '../../components/common/NavBar'
import TextField from '@mui/material/TextField'
import {
  DataFields,
  ProductTemplateAttribute,
  ProductTemplateAttributeOption,
  ProductTemplateAttributeTypes,
  ProductTemplateAttributeTypesEnum,
} from '../../api/types'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import Tabs from '../../components/common/Tabs'
import { ProductTemplateAttributeInputField } from './ProductTemplateAttributeInputField'
import Alert, { AlertInput, handleAlert } from '../../components/common/Alert'
import {
  GetIntegrationsResult,
  getAvailableIntegrations,
  getIntegrations,
} from '../../api/integration'
import { err, getAttributeTag, wait } from '../../utils/functions'
import EbayTemplateIntegrationComponent from '../../components/product/integrations/ebay/EbayTemplateIntegrationComponent'
import ProductSizeWeightInput, {
  SizeWeightChangeEnum,
} from '../../components/product/ProductSizeWeightInput'
import ProductTemplateDefaultAttributeInputField from './ProductTemplateDefaultAttributeInputField'
import { ProductDescriptionEditor } from '../products/ProductDescriptionEditor'
import { STOCK_ATTRIBUTES } from './CreateTemplate'
import Autocomplete from '@mui/material/Autocomplete'
import Chip from '@mui/material/Chip'
import ShopifyTemplateIntegrationComponent from '../../components/product/integrations/shopify/ShopifyTemplateIntegrationComponent'
import BigCommerceTemplateIntegrationComponent from '../../components/product/integrations/bigcommerce/BigCommerceTemplateIntegrationComponent'
import ProductTags from '../products/product/ProductTags'
import SqaureTemplateIntegrationComponent from '../../components/product/integrations/square/SquareTemplateIntegrationComponent'
import EtsyTemplateIntegrationComponent from '../../components/product/integrations/etsy/EtsyTemplateIntegrationComponent'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Modal from '../../components/common/Modal'
import CloverTemplateIntegrationComponent from '../../components/product/integrations/clover/CloverTemplateIntegrationComponent'
import { ProductAttributeInputText } from '../products/product/ProductAttributeInputText'
import RichTextToggle from '../products/RichTextToggle'
import Draggable from '../../components/common/Draggable'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'
import { SingleSelect } from '../../components/common/SingleSelect'
import FormGroup from '@mui/material/FormGroup'
import Checkbox from '@mui/material/Checkbox'

const ITEM_HEIGHT = 48

export function newAttribute(opts: {
  name?: string
  value?: string
  type?: ProductTemplateAttributeTypes
  index: number
  templateId?: number
}): SetProductTemplateAttribute {
  return {
    attribute: {
      name: opts?.name || '',
      value: opts?.value || '',
      type: opts?.type || 'text',
      index: opts.index,
    },
    attributeOptions: [],
  }
}

export function newOption(opts?: {
  name?: string
  value?: string
  type?: ProductTemplateAttributeTypes
  index?: number
}): Omit<
  DataFields<ProductTemplateAttributeOption>,
  'templateId' | 'templateAttributeId'
> {
  return {
    value: opts?.value || '',
    type: opts?.type || 'text',
    index: opts?.index || 0,
  }
}

export default function ProductTemplate(): JSX.Element {
  const { templateId: templateIdParam } = useParams<{
    templateId?: string
  }>()

  const [update, setUpdate] = useState<number>(0)
  const history = useHistory()
  const templateId = templateIdParam ? parseInt(templateIdParam) : undefined
  const isMounted = useRef(true)
  const [alert, setAlert] = useState<AlertInput>({ open: false })

  const [richText, setRichText] = useState<boolean>(true)

  // Product Template
  const [productTemplate, setProductTemplate] = useState<GetProductTemplate>()

  const [name, setName] = useState<string>('')
  const [nameError, setNameError] = useState<string>('')
  const [typeDescription, setTypeDescription] = useState<string>('')
  const [typeNotes, setTypeNotes] = useState<string>('')
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [typeTags, setTypeTags] = useState<string[]>([])

  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false)
  // Product Template Attributes
  const [title, setTitle] = useState<string>('')
  const [description, setDescription] = useState<string>('')

  const [tags, setTags] = useState<string[]>([])
  const [weightUnit, setWeightUnit] = useState<string>('')
  const [sizeUnit, setSizeUnit] = useState<string>('')
  const [length, setLength] = useState<string>('')
  const [width, setWidth] = useState<string>('0')
  const [height, setHeight] = useState<string>('0')
  const [weight, setWeight] = useState<string>('0')

  const [bulkActionsMenuOpen, setBulkActionsMenuOpen] = useState<boolean>(false)
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const [reorderAttributes, setReorderAttributes] = useState<boolean>(false)

  const [templateVariationAttributes, setTemplateVariationAttributes] =
    useState<DataFields<ProductTemplateAttribute>[]>([])

  // integrations
  const [integrations, setIntegrations] = useState<GetIntegrationsResult>({})
  const [availableIntegrations, setAvailableIntegrations] = useState<string[]>(
    [],
  )

  const [mergeAttributesDeleteSource, setMergeAttributesDeleteSource] =
    useState<boolean>(false)
  const [mergeAttributesAction, setMergeAttributesAction] = useState<string>('')
  const [mergeAttributesModalOpen, setMergeAttributesModalOpen] =
    useState<boolean>(false)
  const [mergeAttributeSource, setMergeAttributeSource] = useState<
    SetProductTemplateAttribute | undefined
  >(undefined)
  const [mergeAttributeTarget, setMergeAttributeTarget] = useState<
    SetProductTemplateAttribute | undefined
  >(undefined)
  const [confirmDeleteName, setConfirmDeleteName] = useState<string>('')

  // Editing and Adding Attributes
  const [templateAttributes, setTemplateAttributes] = useState<
    SetProductTemplateAttribute[]
  >([])

  const editableAttributes = useMemo(() => {
    return templateAttributes.filter(
      (a) => !STOCK_ATTRIBUTES.includes(a.attribute.name),
    )
  }, [templateAttributes])

  const bulkActionOptions: string[] = [
    'Add Attribute Named List to Description',
    // 'Add Attribute Bullets to Description',
  ]

  const handleBulkActionsMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
    setBulkActionsMenuOpen(true)
  }
  const handleBulkActionsMenuClose = () => {
    setBulkActionsMenuOpen(false)
    setAnchorEl(null)
  }

  const handleBulkActionsOptionClick = (
    e: React.MouseEvent<HTMLLIElement, MouseEvent>,
  ) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const action = (e.target as any).innerText
    if (action === bulkActionOptions[0]) {
      // Add Attributes Named List to Description
      let listString = ''
      templateAttributes.forEach((a) => {
        listString +=
          '\n' + a.attribute.name + ': ' + getAttributeTag(a.attribute.name)
      })
      const richTextValue = richText
      setRichText(false)
      setDescription(description + '\n' + listString)
      void wait(100).then(() => setRichText(richTextValue)) // hacky way to rerender
    } else if (action === bulkActionOptions[1]) {
      // no-op
    }

    handleBulkActionsMenuClose()
  }

  const handleMergeAttributes = () => {
    if (
      !templateId ||
      !mergeAttributeSource ||
      !mergeAttributeTarget ||
      !mergeAttributesAction
    ) {
      return
    }
    mergeTemplateAttributes({
      templateId: templateId,
      source: mergeAttributeSource.attribute.name,
      target: mergeAttributeTarget.attribute.name,
      action: mergeAttributesAction,
      deleteSource: mergeAttributesDeleteSource,
    })
      .then((res) => {
        handleAlert(setAlert, res, 'Merged Template Attributes')
      })
      .catch((e) => err(e))
  }

  const handleUpdate = () => {
    setUpdate(update + 1) // increase update state to cause render
  }

  const pushTemplateAttributes = (attribute: SetProductTemplateAttribute) => {
    // check null doesnt exist
    const attrbiteExists = templateAttributes.find(
      (templateAttribute) =>
        templateAttribute.attribute.name === attribute.attribute.name,
    )

    if (attrbiteExists) {
      setAlert({
        open: true,
        message:
          'Empty Attribute exists. Please enter a name before adding new attributes.',
        severity: 'warning',
      })
      return
    }
    setTemplateAttributes(templateAttributes.concat([attribute]))
  }

  const attributeNames = templateAttributes.map((a) => a.attribute.name)

  const reIndexVariations = (
    newAttributes: SetProductTemplateAttribute[],
  ): SetProductTemplateAttribute[] => {
    return newAttributes.map(
      (a, i): SetProductTemplateAttribute => ({
        attribute: { ...a.attribute, index: i },
        attributeOptions: a.attributeOptions,
      }),
    )
  }

  const handleTemplateAttributeChange = (
    attribute: SetProductTemplateAttribute | undefined,
    name: string,
  ) => {
    let newAttributes = templateAttributes.slice() // copy
    const index = newAttributes.findIndex((a) => a.attribute.name === name)

    // Update
    if (attribute && index > -1) {
      newAttributes[index] = attribute
      // Add
    } else if (attribute && index === -1) {
      newAttributes.push(attribute) // add
      // Delete
    } else if (!attribute) {
      newAttributes.splice(index, 1) // delete
      newAttributes = reIndexVariations(newAttributes)
    }
    setTemplateAttributes(newAttributes)
  }

  const handleTemplateAttributeIndexChange = (
    index: number,
    newIndex: number,
  ) => {
    let newAttributes = templateAttributes.slice() // copy
    const attribute = newAttributes[index]

    if (attribute) {
      // update index
      const tempAttribute = newAttributes[newIndex] // store temp
      newAttributes[newIndex] = attribute // update new index
      if (tempAttribute) newAttributes[index] = tempAttribute // set old index to temp
      // re-index

      // preserve indexes, may not be 0 based.
      newAttributes = newAttributes.map(
        (a, i): SetProductTemplateAttribute => ({
          attribute: { ...a.attribute, index: i },
          attributeOptions: a.attributeOptions,
        }),
      )
      setTemplateAttributes(newAttributes)
    }
  }

  const addAttributeToTitle = (a: SetProductTemplateAttribute) => {
    const attributeName = a.attribute.name
    const richTextValue = richText
    setRichText(false)
    setTitle(title + ' ' + getAttributeTag(attributeName))
    void wait(100).then(() => setRichText(richTextValue)) // hacky way to rerender
  }
  const addAttributeToDescription = (a: SetProductTemplateAttribute) => {
    const attributeName = a.attribute.name
    const richTextValue = richText
    setRichText(false)
    setDescription(description + '\n' + getAttributeTag(attributeName))
    void wait(100).then(() => setRichText(richTextValue)) // hacky way to rerender
  }

  const handleMergeAttributesPress = (a: SetProductTemplateAttribute) => {
    setMergeAttributeSource(a)
    setMergeAttributesModalOpen(true)
  }

  const handleVariantAttributeChange = (
    templateAttributeNames: { name: string }[],
  ) => {
    if (!templateId) return
    const newTemplateVariationAttributes = templateAttributeNames.map(
      (templateAttributeName, i) => ({
        name: templateAttributeName.name,
        index: i,
        templateId: templateId,
      }),
    )
    setTemplateVariationAttributes(newTemplateVariationAttributes)
  }

  const handleSizeWeightChange = (
    name: SizeWeightChangeEnum,
    value: string | number | undefined,
  ) => {
    switch (name) {
      case SizeWeightChangeEnum.WEIGHT_UNIT:
        setWeightUnit(value + '')
        break
      case SizeWeightChangeEnum.SIZE_UNIT:
        setSizeUnit(value + '')
        break
      case SizeWeightChangeEnum.WEIGHT:
        setWeight(value + '')
        break
      case SizeWeightChangeEnum.L:
        setLength(value + '')
        break
      case SizeWeightChangeEnum.W:
        setWidth(value + '')
        break
      case SizeWeightChangeEnum.H:
        setHeight(value + '')
        break
      default:
        console.log('Not found.')
    }
  }

  const handleDelete = async () => {
    if (!templateId) return

    if (confirmDeleteName !== name) {
      setAlert({
        open: true,
        severity: 'error',
        message: 'Template Name does not match.',
      })
      return
    }

    const res = await deleteProductTemplate(templateId)
    if (res.data) {
      handleAlert(
        setAlert,
        res,
        'Deleted Template. Navigating to Templates Page.',
      )
      await wait(1000)
      history.push('/templates')
    }
  }

  const handleDeleteRequest = async () => {
    setDeleteModalOpen(true)
  }

  const handleDuplicateTemplate = async () => {
    if (!templateId) return
    duplicateProductTemplate(templateId)
      .then(async (res) => {
        if (res.success && res.data) {
          const templateId = res.data
          setAlert({
            open: true,
            message:
              'Successfully duplicated template. Navigating to new Template',
            severity: 'success',
          })
          await wait(2000)
          setAlert({ open: false, severity: 'success', message: '' })
          history.push(`/template/${templateId}`)
        }
      })
      .catch((e) => {
        if (e?.response?.data?.message?.includes('already exists')) {
          setAlert({
            open: true,
            message: e.response.data.message,
            severity: 'error',
          })
          return
        }
        setAlert({
          open: true,
          message: 'Failed to duplicate template.',
          severity: 'error',
        })
      })
  }

  const handleSave = async () => {
    if (!templateId) return

    const productTemplate: ProductTemplateInput = {
      id: templateId,
      name,
      typeDescription,
      typeNotes,
      tags: tags.join(','),
      title,
      description,
      weightUnit,
      weight: parseFloat(weight),
      sizeUnit,
      width: parseFloat(width),
      length: parseFloat(length),
      height: parseFloat(height),
    }

    // add default type as string. TODO: Add types?
    const attributes: SetProductTemplateAttribute[] = templateAttributes?.map(
      (attr) => ({
        ...attr,
        type: ProductTemplateAttributeTypesEnum.TEXT,
      }),
    )

    // order default templates

    const result = await updateProductTemplate(
      productTemplate,
      attributes,
      templateVariationAttributes,
    )

    if (result.success && result.data) {
      setAlert({
        open: true,
        message: 'Template Saved.',
        severity: 'success',
      })
      setTemplateState(result.data)
    } else {
      setAlert({
        open: true,
        message: result.message,
        severity: 'error',
      })
    }
  }

  /* Get Product Templates */
  useEffect(() => {
    // May need to re-add this.
    // isMounted.current &&
    if (templateId) {
      getProductTemplate(templateId)
        .then((res) => {
          if (!res.data) {
            return
          }
          const productTemplate = res.data
          setTemplateState(productTemplate)
        })
        .catch((e) => err(e))
      return () => {
        isMounted.current = false
      }
    }
  }, [templateId, update])

  const setTemplateState = (productTemplate: GetProductTemplate) => {
    const { template, attributes, variations } = productTemplate
    // Sort and re-index
    const sortedAttributes = attributes.sort(
      (a, b) => a.attribute.index - b.attribute.index,
    )

    if (!productTemplate) return
    setProductTemplate(productTemplate)
    setName(template.name)
    setTypeDescription(template.typeDescription || '')
    setTypeNotes(template.typeNotes || '')
    setTypeTags(template.tags?.split(',') || [])
    setTemplateAttributes(sortedAttributes)
    setTemplateVariationAttributes(variations || [])

    setTitle(template.title || '')
    setDescription(template.description || '')
    setTags(template.tags ? template.tags?.split(',') : [])
    setWeightUnit(template.weightUnit || '')
    setSizeUnit(template.sizeUnit || '')
    setWeight(`${template.weight || 0}`)
    setLength(`${template.length || 0}`)
    setWidth(`${template.width || 0}`)
    setHeight(`${template.height || 0}`)
  }

  /* Get Integration */
  useEffect(() => {
    getAvailableIntegrations()
      .then((res) => {
        if (res.success) {
          setAvailableIntegrations(res.data || [])
        }
      })
      .catch((e) => err(e))
    getIntegrations({ templateId })
      .then((res) => {
        if (res.success && res.data) {
          setIntegrations(res.data)
        }
      })
      .catch((e) => err(e))
  }, [templateId])

  return (
    <div
      style={{
        width: '100%',
      }}
    >
      <NavBar />
      <Container sx={{ mt: 4 }}>
        <Component>
          <Grid item xs={12} sx={{ mt: 2, mb: 1 }}>
            <Typography variant="h5">
              <strong>{name || 'Template'}</strong>
            </Typography>
          </Grid>
          <Tabs
            tabs={['Template', 'Integrations', 'Details']}
            panels={[
              <>
                <Grid container spacing={2} sx={{ pt: 2 }}>
                  <Grid item xs={12}>
                    <Grid container justifyContent="center" spacing={3}>
                      <Grid item xs={12}>
                        <Grid container justifyContent="flex-end">
                          <Grid item>
                            <RichTextToggle
                              toggled={richText}
                              onChange={() => setRichText(!richText)}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        {richText && title !== undefined ? (
                          <ProductDescriptionEditor
                            key={`title-editor-${attributeNames.length}`}
                            title="Title"
                            oneLine={true}
                            richText={richText}
                            value={title}
                            onChange={(title) => setTitle(title)}
                            templateAttributes={attributeNames}
                          />
                        ) : (
                          <ProductAttributeInputText
                            label="Title"
                            value={title}
                            onChange={(title) => setTitle(title)}
                          />
                        )}
                      </Grid>
                      <Grid item xs={12}>
                        {description !== undefined && (
                          <ProductDescriptionEditor
                            key={`description-editor-${attributeNames.length}`}
                            title="Description"
                            richText={richText}
                            value={description}
                            onChange={(description) =>
                              setDescription(description)
                            }
                            templateAttributes={attributeNames}
                          />
                        )}
                      </Grid>

                      <Grid item xs={12}>
                        <ProductTags tags={tags} onChange={setTags} />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <ProductTemplateDefaultAttributeInputField
                      name={'Brand'}
                      attributes={templateAttributes}
                      onChange={handleTemplateAttributeChange}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <ProductTemplateDefaultAttributeInputField
                      name={'Category'}
                      attributes={templateAttributes}
                      onChange={handleTemplateAttributeChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <ProductTemplateDefaultAttributeInputField
                      name={'Condition'}
                      attributes={templateAttributes}
                      disabled={true}
                      onChange={handleTemplateAttributeChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <ProductTemplateDefaultAttributeInputField
                      name={'Condition Description'}
                      attributes={templateAttributes}
                      onChange={handleTemplateAttributeChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <ProductTemplateDefaultAttributeInputField
                      name={'Cost'}
                      attributes={templateAttributes}
                      disabled={true}
                      onChange={handleTemplateAttributeChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <ProductTemplateDefaultAttributeInputField
                      name={'Price'}
                      attributes={templateAttributes}
                      disabled={true}
                      onChange={handleTemplateAttributeChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <ProductTemplateDefaultAttributeInputField
                      name={'MSRP'}
                      attributes={templateAttributes}
                      disabled={true}
                      onChange={handleTemplateAttributeChange}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <ProductSizeWeightInput
                      width={width}
                      length={length}
                      height={height}
                      weight={weight}
                      sizeUnit={sizeUnit}
                      weightUnit={weightUnit}
                      onChange={handleSizeWeightChange}
                      template
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <ProductTemplateDefaultAttributeInputField
                      name={'Vendor'}
                      attributes={templateAttributes}
                      onChange={handleTemplateAttributeChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <ProductTemplateDefaultAttributeInputField
                      name={'Location'}
                      attributes={templateAttributes}
                      onChange={handleTemplateAttributeChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <ProductTemplateDefaultAttributeInputField
                      name={'Wharehouse'}
                      attributes={templateAttributes}
                      onChange={handleTemplateAttributeChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <ProductTemplateDefaultAttributeInputField
                      name={'Bin'}
                      attributes={templateAttributes}
                      onChange={handleTemplateAttributeChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container justifyContent="center" spacing={3}>
                      <Grid item xs={12}>
                        {/* Title */}
                        <Grid container justifyContent="center" spacing={0}>
                          <Grid item xs={12}>
                            <Typography variant="h6" textAlign="center">
                              Custom Attributes
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Grid container justifyContent="flex-end">
                              <Grid item>
                                <FormControlLabel
                                  label={
                                    <Typography
                                      variant="subtitle2"
                                      color="textSecondary"
                                    >
                                      Re-order
                                    </Typography>
                                  }
                                  value="top"
                                  control={
                                    <Switch
                                      checked={reorderAttributes}
                                      size="small"
                                      color="primary"
                                      onChange={() =>
                                        setReorderAttributes(!reorderAttributes)
                                      }
                                    />
                                  }
                                  labelPlacement="top"
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>

                      {editableAttributes?.map((attribute, i) => {
                        const editableAttributeElement = (
                          <ProductTemplateAttributeInputField
                            key={attribute.attribute.name}
                            attribute={attribute}
                            allowDelete
                            onChange={(a) =>
                              handleTemplateAttributeChange(
                                a,
                                attribute.attribute.name,
                              )
                            }
                            addToTitle={(a) => addAttributeToTitle(a)}
                            addToDescription={(a) =>
                              addAttributeToDescription(a)
                            }
                            mergeAttributes={(a) =>
                              handleMergeAttributesPress(a)
                            }
                          />
                        )
                        if (reorderAttributes) {
                          return (
                            <Grid
                              item
                              xs={12}
                              key={`${attribute.attribute.name}`}
                            >
                              <Draggable
                                id={`${attribute.attribute.name}`}
                                index={attribute.attribute.index}
                                onChange={handleTemplateAttributeIndexChange}
                              >
                                {editableAttributeElement}
                              </Draggable>
                            </Grid>
                          )
                        }
                        return (
                          <Grid
                            item
                            xs={12}
                            key={`${attribute.attribute.name}`}
                          >
                            {editableAttributeElement}
                          </Grid>
                        )
                      })}
                      <Grid item xs={12}>
                        <Grid container justifyContent="center" spacing={3}>
                          <Grid item>
                            <Button
                              onClick={() =>
                                pushTemplateAttributes(
                                  newAttribute({
                                    index: templateAttributes.length - 1,
                                  }),
                                )
                              }
                              variant="outlined"
                            >
                              Add Attribute
                            </Button>
                          </Grid>
                          <Grid item>
                            <div>
                              <Button
                                variant="outlined"
                                onClick={handleBulkActionsMenuClick}
                              >
                                Bulk Actions
                              </Button>
                              <Menu
                                id="long-menu"
                                MenuListProps={{
                                  'aria-labelledby': 'long-button',
                                }}
                                anchorEl={anchorEl}
                                open={bulkActionsMenuOpen}
                                onClose={handleBulkActionsMenuClose}
                                PaperProps={{
                                  style: {
                                    maxHeight: ITEM_HEIGHT * 4.5,
                                    width: '35ch',
                                  },
                                }}
                              >
                                {bulkActionOptions.map((option) => (
                                  <MenuItem
                                    key={option}
                                    data-option={option}
                                    selected={option === 'Pyxis'}
                                    onClick={(e) =>
                                      handleBulkActionsOptionClick(e)
                                    }
                                  >
                                    {option}
                                  </MenuItem>
                                ))}
                              </Menu>
                            </div>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container justifyContent="center" spacing={3}>
                      <Grid item xs={12}>
                        <Typography variant="h6" textAlign="center">
                          Variations
                        </Typography>
                        <Typography variant="body2" textAlign="center">
                          Select Attributes that products of this template can
                          vary on.
                          <br />
                          SKU, Quantity, Price, and Cost are included by
                          default.
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container justifyContent="center">
                          <Autocomplete
                            size="small"
                            multiple
                            fullWidth
                            value={templateVariationAttributes}
                            isOptionEqualToValue={(option, value) =>
                              option.name === value.name
                            }
                            options={templateAttributes.map(
                              (templateAttribute, i) => ({
                                name: templateAttribute.attribute.name,
                                index: i,
                              }),
                            )}
                            onChange={(e, value) =>
                              handleVariantAttributeChange(value)
                            }
                            getOptionLabel={(option) => option.name}
                            renderTags={(values, getTagProps) =>
                              values.map((option, index) => (
                                <Chip
                                  {...getTagProps({ index })}
                                  key={option.name}
                                  variant="outlined"
                                  label={option.name}
                                />
                              ))
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                label="Attributes"
                              />
                            )}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container justifyContent="space-between" spacing={3}>
                      <Grid item>
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={handleDeleteRequest}
                        >
                          Delete
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          variant="outlined"
                          onClick={handleDuplicateTemplate}
                        >
                          Duplicate
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button variant="contained" onClick={handleSave}>
                          Save
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </>,
              <>
                <Grid container>
                  {productTemplate ? (
                    <Tabs
                      tabs={availableIntegrations}
                      panels={[
                        <>
                          <EbayTemplateIntegrationComponent
                            // integration={integrations.ebay}
                            template={productTemplate}
                            onUpdate={handleUpdate}
                          ></EbayTemplateIntegrationComponent>
                        </>,
                        <>
                          <EtsyTemplateIntegrationComponent />
                        </>,
                        <>
                          <SqaureTemplateIntegrationComponent
                            integration={integrations.square}
                            template={productTemplate}
                            onUpdate={handleUpdate}
                          ></SqaureTemplateIntegrationComponent>
                        </>,
                        <>
                          <ShopifyTemplateIntegrationComponent
                            integration={integrations.shopify}
                            template={productTemplate}
                            onUpdate={handleUpdate}
                          ></ShopifyTemplateIntegrationComponent>
                        </>,
                        <>
                          <BigCommerceTemplateIntegrationComponent
                            integration={integrations.bigcommerce}
                            template={productTemplate}
                            onUpdate={handleUpdate}
                          ></BigCommerceTemplateIntegrationComponent>
                        </>,
                        <>
                          <CloverTemplateIntegrationComponent
                            integration={integrations.clover}
                            template={productTemplate}
                            onUpdate={handleUpdate}
                          ></CloverTemplateIntegrationComponent>
                        </>,
                      ]}
                    />
                  ) : null}
                </Grid>
              </>,
              <>
                <Grid container spacing={3} justifyContent="center">
                  <Grid item xs={12} md={8}>
                    <TextField
                      label="Name"
                      size="small"
                      required
                      value={name}
                      fullWidth
                      autoComplete="off"
                      onChange={(e) => {
                        setName(e.target.value)
                        setNameError(e.target.value ? '' : 'Name is required.')
                      }}
                      onBlur={() =>
                        setNameError(name ? '' : 'Name is required.')
                      }
                      error={!!nameError}
                      helperText={nameError}
                      onClick={() => {
                        setNameError('')
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <TextField
                      label="Template Details"
                      size="small"
                      multiline
                      minRows={10}
                      fullWidth
                      value={typeDescription}
                      autoComplete="off"
                      onChange={(e) => setTypeDescription(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <TextField
                      label="Notes"
                      size="small"
                      multiline
                      minRows={5}
                      fullWidth
                      value={typeNotes}
                      autoComplete="off"
                      onChange={(e) => setTypeNotes(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} md={8}>
                    {/* <ProductTags tags={typeTags} onChange={setTypeTags} /> */}
                  </Grid>
                  <Grid item xs={12}>
                    <Stack direction="row" justifyContent="space-between">
                      <Button
                        variant="contained"
                        onClick={handleDelete}
                        color="secondary"
                      >
                        Delete
                      </Button>
                      <Button variant="contained" onClick={handleSave}>
                        Save
                      </Button>
                    </Stack>
                  </Grid>
                </Grid>
              </>,
            ]}
          ></Tabs>
          <Grid item xs={12}>
            <Box sx={{ height: '250px' }} />
          </Grid>
        </Component>
        <Alert
          alert={alert}
          onClose={() => setAlert({ ...alert, open: false })}
        ></Alert>
        <Modal
          open={deleteModalOpen}
          title={`Delete Template "${name}"?`}
          onSubmit={handleDelete}
          onClose={() => setDeleteModalOpen(false)}
        >
          <Grid container justifyContent="center" spacing={3}>
            <Grid item xs={12}>
              <Typography>
                <strong>Warning</strong>: Deleting this template will delete all
                Template Integrations, Configurations, and Products
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography>
                Type the name of the Template to confirm and continue
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                value={confirmDeleteName}
                onChange={(e) => setConfirmDeleteName(e.target.value)}
                name="Template Name"
                placeholder={name}
                size="small"
              ></TextField>
            </Grid>
          </Grid>
        </Modal>

        <Modal
          open={mergeAttributesModalOpen}
          title={`Merge Attribute ${mergeAttributeSource?.attribute.name}`}
          onSubmit={handleMergeAttributes}
          onClose={() => setMergeAttributesModalOpen(false)}
        >
          <Grid container justifyContent="center" spacing={3}>
            <Grid item xs={12}>
              <Typography>Select Attribute to Merge into</Typography>
              <Typography variant="body2">
                Attribute options will be copied to selected attribute. <br />
                All Product values will be merged.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <SingleSelect
                label="Target Attribute"
                freeSolo={false}
                value={mergeAttributeTarget?.attribute.name || ''}
                options={editableAttributes
                  .filter(
                    (a) =>
                      a.attribute.id &&
                      a.attribute.name !== mergeAttributeSource?.attribute.name,
                  )
                  .map((a) => a.attribute.name)}
                onChange={(attributeName) => {
                  const selectedAttribute = editableAttributes?.find(
                    (editableAttribute) =>
                      editableAttribute.attribute.name === attributeName,
                  )
                  if (!selectedAttribute) return
                  setMergeAttributeTarget(selectedAttribute)
                }}
              ></SingleSelect>
            </Grid>

            <Grid item xs={12}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={mergeAttributesAction === 'fill'}
                      onClick={() => setMergeAttributesAction('fill')}
                    />
                  }
                  label={
                    <Grid container spacing={2}>
                      <Grid item>
                        <Typography variant="body1" component="span">
                          Fill
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="body2" component="span">
                          Fill in missing values
                        </Typography>
                      </Grid>
                    </Grid>
                  }
                />
              </FormGroup>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={mergeAttributesAction === 'replace'}
                      onClick={() => setMergeAttributesAction('replace')}
                    />
                  }
                  label={
                    <Grid container spacing={2}>
                      <Grid item>
                        <Typography variant="body1" component="span">
                          Replace
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="body2" component="span">
                          Replace values with defined values
                        </Typography>
                      </Grid>
                    </Grid>
                  }
                />
              </FormGroup>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={mergeAttributesAction === 'overwrite'}
                      onClick={() => setMergeAttributesAction('overwrite')}
                    />
                  }
                  label={
                    <Grid container spacing={2}>
                      <Grid item>
                        <Typography variant="body1" component="span">
                          Overwrite
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="body2" component="span">
                          Overwrite values
                        </Typography>
                      </Grid>
                    </Grid>
                  }
                />
              </FormGroup>
            </Grid>
            <Grid item xs={12}>
              <Typography>Delete Source Attribute</Typography>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={mergeAttributesDeleteSource}
                      onClick={() =>
                        setMergeAttributesDeleteSource(
                          !mergeAttributesDeleteSource,
                        )
                      }
                    />
                  }
                  label={
                    <Typography variant="body1" component="span">
                      Delete
                    </Typography>
                  }
                />
              </FormGroup>
            </Grid>
          </Grid>
        </Modal>
      </Container>
    </div>
  )
}
