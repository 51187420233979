import React, { useEffect, useState } from 'react'
import { TreeItem, TreeView } from '@mui/x-tree-view'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import {
  CategorySuggestion,
  CategoryTree,
  CategoryTreeNode,
} from '../../../../types/Ebay.types'
import { err, log } from '../../../../utils/functions'
import {
  getEbayCategories,
  getEbayCategorySuggestions,
} from '../../../../api/product'
import Component from '../../../common/Component'
import Button from '@mui/material/Button'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Stack from '@mui/material/Stack'
import Alert, { AlertInput, handleAlert } from '../../../common/Alert'
import { Typography } from '@mui/material'

export type EbayCategory = {
  id: number
  name: string
}
interface EbayCategoriesProps {
  category: EbayCategory | undefined
  categoryVersion: number
  onChange: (
    categoryVersion: number,
    category: EbayCategory | undefined,
  ) => void
}

export default function EbayCategories({
  category,
  onChange,
}: EbayCategoriesProps): JSX.Element {
  const [categories, setCategories] = useState<CategoryTree | undefined>()
  const [alert, setAlert] = useState<AlertInput>({ open: false })

  const [categorySuggestions, setCategorySuggestions] = useState<
    CategorySuggestion[]
  >([])
  const [categorySuggestionQuery, setCategorySuggestionQuery] = useState('')
  const [viewMenu, setViewMenu] = useState<boolean>(false)

  const handleViewMenu = () => {
    setViewMenu(!viewMenu)
  }

  const handleQuery = () => {
    setViewMenu(false) // do not show menu
    categorySuggestionQuery &&
      getEbayCategorySuggestions(categorySuggestionQuery)
        .then((res) => {
          if (res.success && res.data) {
            setCategorySuggestions(res.data.categorySuggestions || [])
          } else {
            handleAlert(setAlert, res, 'Added service to Dashboard.')
          }
        })
        .catch((e) => log(e))
  }

  useEffect(() => {
    // Do not load if categories is set
    if (category) return
    getEbayCategories()
      .then((res) => {
        if (res.success) {
          setCategories(res.data)
        } else {
          handleAlert(setAlert, res, 'Added service to Dashboard.')
        }
      })
      .catch((e) => err(e))
  }, [category])

  return (
    <Grid container spacing={2} justifyContent="center">
      <Grid item xs={12}>
        <Typography component="span" variant="h6">
          Category
        </Typography>
        <Typography sx={{ ml: 1 }} component="span" variant="caption">
          {categories?.categoryTreeVersion
            ? `(v.${categories.categoryTreeVersion})`
            : null}
        </Typography>
      </Grid>

      {category?.id && category.name ? (
        <Grid item xs={12}>
          <Grid container alignItems="center">
            <Grid item lg={2} md={3} xs={12}>
              <Typography>
                <strong>Selected Category</strong>
              </Typography>
            </Grid>
            <Grid item lg={8} md={7} xs={9}>
              <TextField
                label="Selected Ebay Category"
                value={category.name}
                disabled={true}
                autoComplete="off"
                size="small"
                fullWidth
              />
            </Grid>
            <Grid item lg={1} md={1} xs={2}>
              <Typography sx={{ ml: 1 }} component="span" variant="caption">
                (id: {category.id})
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <Button size="small" onClick={() => onChange(0, undefined)}>
                Clear
              </Button>
            </Grid>
          </Grid>
        </Grid>
      ) : null}

      {(!category?.id || !category?.name) && (
        <Grid item xs={12}>
          <Grid container justifyContent="space-between">
            <Grid item sm={8} xs={12}>
              <Stack direction="row" spacing={1}>
                <Grid item xs={11}>
                  <TextField
                    label="Search Ebay Categories"
                    value={categorySuggestionQuery}
                    onChange={(e) => setCategorySuggestionQuery(e.target.value)}
                    onSubmit={() => log('Submitted')}
                    autoComplete="off"
                    size="small"
                    fullWidth
                  />
                </Grid>
                <Grid item>
                  <Button variant="contained" onClick={handleQuery}>
                    Search
                  </Button>
                </Grid>
              </Stack>
            </Grid>

            <Grid item sm={4} xs={12}>
              <Button variant="outlined" onClick={handleViewMenu}>
                {viewMenu ? 'View Search' : 'View Menu'}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      )}
      {(!category?.id || !category?.name) &&
        ((!viewMenu && categorySuggestions.length) ||
          (viewMenu && categories)) && (
          <Grid item xs={12}>
            <Component>
              {viewMenu ? (
                <TreeView
                  defaultCollapseIcon={<ExpandMoreIcon />}
                  defaultExpandIcon={<ChevronRightIcon />}
                  sx={{ height: '300px', flexGrow: 1, overflowY: 'auto' }}
                >
                  {categories?.rootCategoryNode ? (
                    <DisplayCategories
                      node={categories.rootCategoryNode}
                      onChange={(category) =>
                        onChange(
                          parseInt(categories.categoryTreeVersion),
                          category,
                        )
                      }
                    />
                  ) : null}
                </TreeView>
              ) : (
                <TreeView>
                  {categorySuggestions?.map((suggestion) => {
                    const suggestionArry: string[] = [
                      suggestion.category.categoryName,
                    ]
                    suggestion.categoryTreeNodeAncestors.forEach((ancestor) => {
                      suggestionArry.unshift(ancestor.categoryName)
                    })
                    return (
                      <TreeItem
                        key={suggestion.category.categoryId}
                        nodeId={suggestion.category.categoryId}
                        label={
                          <div>
                            {suggestionArry.slice(0, -1).join(' > ')}
                            {' > '}
                            <strong>{suggestionArry.slice(-1)[0]}</strong>
                          </div>
                        }
                        onClick={() =>
                          categories?.categoryTreeVersion &&
                          onChange(parseInt(categories.categoryTreeVersion), {
                            id: parseInt(suggestion.category.categoryId),
                            name: suggestion.category.categoryName,
                          })
                        }
                        sx={{ padding: '0.33em' }}
                      />
                    )
                  }) || 'Search Categories...'}
                </TreeView>
              )}
            </Component>
          </Grid>
        )}

      <Alert
        alert={alert}
        onClose={() => setAlert({ ...alert, open: false })}
      ></Alert>
    </Grid>
  )
}

function DisplayCategories({
  node,
  onChange,
}: {
  node: CategoryTreeNode
  onChange: (category: EbayCategory | undefined) => void
}): JSX.Element {
  return (
    <div>
      {node.childCategoryTreeNodes?.map((node) => (
        <TreeNode
          node={node}
          key={node.category.categoryId}
          onChange={onChange}
        />
      ))}
    </div>
  )
}

function TreeNode({
  node,
  onChange,
}: {
  node: CategoryTreeNode
  onChange: (category: EbayCategory | undefined) => void
}): JSX.Element {
  return (
    <TreeItem
      key={node.category.categoryId}
      nodeId={node.category.categoryId}
      label={node.category.categoryName}
      onClick={() =>
        node.leafCategoryTreeNode &&
        onChange({
          id: parseInt(node.category.categoryId),
          name: node.category.categoryName,
        })
      }
    >
      {node.childCategoryTreeNodes?.map((node) => (
        <TreeNode
          node={node}
          key={node.category.categoryId}
          onChange={onChange}
        />
      ))}
    </TreeItem>
  )
}

export function search(
  array: CategoryTreeNode[],
  is: (obj: CategoryTreeNode) => boolean,
): CategoryTreeNode | undefined {
  let found: CategoryTreeNode | undefined = undefined
  array.forEach((node) => {
    log(node.category.categoryName)
    if (found) return
    if (is(node)) {
      found = node
      return
    }
    if (!found && node.childCategoryTreeNodes?.length) {
      found = search(node.childCategoryTreeNodes, is)
    }
  })
  return found
}
