import React from 'react'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'

export default function LoadingBox(): JSX.Element {
  return (
    <Grid container>
      <Grid item xs={12}>
        <Box height={1000}></Box>
      </Grid>
    </Grid>
  )
}
