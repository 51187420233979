import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import React from 'react'

export default function EtsyTemplateIntegrationComponent(): JSX.Element {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h5">Etsy</Typography>
      </Grid>
      <Grid item xs={12}>
        <Box height={500}>
          <Typography>This integration is WIP. Check back later.</Typography>
        </Box>
      </Grid>
    </Grid>
  )
}
