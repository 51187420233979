import React from 'react'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Fab from '@mui/material/Fab'
import { useEffect, useRef, useState } from 'react'
import {
  getAllAttributeValues,
  getAllSkus,
  getAllTags,
  getAllTemplates,
  getInventoryColumns,
  GetProduct,
  searchProducts,
  SearchProductsQuery,
  setInventoryColumns,
} from '../../api/product'
import Component from '../../components/common/Component'
import NavBar from '../../components/common/NavBar'
import AddIcon from '@mui/icons-material/Add'
import {
  err,
  formatDate,
  isDefined,
  log,
  parseBoolean,
  removeElements,
  split,
  unique,
} from '../../utils/functions'
import { useHistory, useLocation } from 'react-router-dom'
import TextField from '@mui/material/TextField'
import Avatar from '@mui/material/Avatar'
import { PRODUCT_FIELD_NAMES } from '../../types'
import { replaceAttributes } from '../../classes/Parser'
import ProductParseToggle from './ParseProductDetailsToggle'
import Collapse from '@mui/material/Collapse'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandMore from '../../components/common/ExpandMore'
import Button from '@mui/material/Button'
import { Box, FormControlLabel, Switch, Tooltip } from '@mui/material'
import { MultipleSelect } from '../../components/common/MultipleSelect'
import { SingleSelect } from '../../components/common/SingleSelect'
import VerticalIconMenu from '../../components/common/VerticalIconMenu'
import PaginatedTable from '../../components/common/PaginatedTable'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'

enum PRODUCT_COLUMNS_ENUM {
  ID = 'id',
  SKU = 'SKU',
  TITLE = 'Title',
  NOTES = 'Notes',
  TAGS = 'Tags',
  DESCRIPTION = 'Description',
  CONDITION = 'Condition',
  IMAGE = 'Image',
  QUANTITY = 'Quantity',
  SOLD = 'Sold',
  COST = 'Cost',
  PRICE = 'Price',
  CREATED = 'Created',
  CATEGORY = 'Category',
  // VENDOR = 'Vendor',
  // WHAREHOUSE = 'Wharehouse',
  // LOCATION = 'Location',
  BIN = 'Bin',
  WEIGHT = 'Weight',
  WEIGHT_UNIT = 'Weight Unit',
  WIDTH = 'Width',
  LENGTH = 'Length',
  HEIGHT = 'Height',
  SIZE_UNIT = 'Size Unit',
  ACTIVE = 'Active',
  TEMPLATE = 'Template',
  LISTINGS = 'Listings',
}

const defaultColumns = [
  ' ',
  'Image',
  'SKU',
  // 'Id',
  'Template',
  'Quantity',
  'Sold',
  'Cost',
  'Price',
]

export default function Products(): JSX.Element {
  const history = useHistory()
  const isMounted = useRef(true)

  let querySearch: Record<string, string> | undefined = undefined
  let queryObject: SearchProductsQuery | undefined = undefined
  const urlSearch = new URLSearchParams(useLocation().search).toString()
  if (urlSearch) {
    const searchParams = new URLSearchParams(urlSearch)
    const queryObj: Record<string, string> = {}
    for (const [key, value] of searchParams) {
      if (value === '') continue
      queryObj[key] = value
    }
    querySearch = queryObj
    queryObject = queryObj as unknown as SearchProductsQuery
  }

  const [products, setProducts] = useState<GetProduct[]>([])
  const [columns, setColumns] = useState<string[]>([])
  const [parseDetails, setParseDetails] = useState<boolean>(true)

  const [filtersDrawerOpen, setFiltersDrawerOpen] = useState<boolean>(false)

  const [count, setCount] = useState<number>(0)
  const [page, setPage] = useState<number>(0)
  const [pageSize, setPageSize] = useState<number>(25)

  // Sort By
  const [sortBy, setSortBy] = useState<string>(PRODUCT_COLUMNS_ENUM.CREATED)
  const [orderBy, setOrderBy] = useState<string>('Descending')

  // Price
  const [minPrice, setMinPrice] = useState<number | undefined>(
    queryObject?.minPrice ? parseFloat(queryObject?.minPrice) : undefined,
  )
  const [maxPrice, setMaxPrice] = useState<number | undefined>(
    queryObject?.maxPrice ? parseFloat(queryObject?.maxPrice) : undefined,
  )

  // Sold
  const [minSold] = useState<number | undefined>(
    queryObject?.minSold ? parseInt(queryObject?.minSold) : undefined,
  )
  const [maxSold] = useState<number | undefined>(
    queryObject?.maxSold ? parseInt(queryObject?.maxSold) : undefined,
  )

  // Quantity
  const [minQuantity, setMinQuantity] = useState<number | undefined>(
    queryObject?.minQuantity ? parseInt(queryObject?.minQuantity) : undefined,
  )
  const [maxQuantity, setMaxQuantity] = useState<number | undefined>(
    queryObject?.maxQuantity ? parseInt(queryObject?.maxQuantity) : undefined,
  )

  // Skus
  const [skus, setSkus] = useState<string[]>(
    queryObject?.skus ? queryObject.skus.split('+') : [],
  )
  const [availableSkus, setAvailableSkus] = useState<string[]>([])

  // Templates
  const [templates, setTemplates] = useState<string[]>(
    queryObject?.skus ? queryObject.skus.split('+') : [],
  )
  const [availableTemplates, setAvailableTemplates] = useState<
    { name: string; id: number }[]
  >([])

  const [search, setSearch] = useState<string>(
    queryObject?.search?.split('+').join(' ') || '',
  )
  // const [searchDescription] = useState<boolean>(
  //   // parseBoolean(queryObject?.searchDescription) || true,
  //   true,
  // )

  const [availableTags, setAvailableTags] = useState<string[]>([])
  const [hasTags, setHasTags] = useState<string[]>(
    queryObject?.hasTags ? queryObject.hasTags.split('+') : [],
  )
  const [missingTags, setMissingTags] = useState<string[]>(
    queryObject?.missingTags ? queryObject.missingTags.split('+') : [],
  )

  const [listedOn, setListedOn] = useState<string[]>(
    queryObject?.listedOn ? queryObject.listedOn.split('+') : [],
  )
  const [notListedOn, setNotListedOn] = useState<string[]>(
    queryObject?.notListedOn ? queryObject.notListedOn.split('+') : [],
  )

  // TODO: Get available listed on from reliable source
  const [availableListedOn] = useState<string[]>([
    'Ebay',
    'BigCommerce',
    'Shopify',
    'Square',
    'Clover',
  ])

  const [matchAllAttributes, setMatchAllAttributes] = useState<boolean>(
    parseBoolean(queryObject?.matchAllAttributes),
  )
  const [matchAllListings, setMatchAllListings] = useState<boolean>(
    parseBoolean(queryObject?.matchAllListings),
  )

  const [editProducts] = useState<boolean>(false)

  const attibuteArray = queryObject?.attributes
    ? queryObject?.attributes.split('+')
    : []
  const attributeValuesFromStr = attibuteArray
    .map((a) => {
      const [name, action, valuesString] = a.split(';')
      if (!name) return undefined
      return {
        name,
        action,
        values: valuesString?.split(',') || [],
      }
    })
    .filter(isDefined)
  const [attributeValues, setAttributeValues] = useState<
    { name: string; action?: string; values: string[] }[]
  >(
    attributeValuesFromStr?.length
      ? attributeValuesFromStr
      : [
          {
            name: '',
            values: [],
          },
        ],
  )
  const [availableAttributes, setAvailableAttributes] = useState<string[]>([])
  const [availableAttributeValues, setAvailableAttributeValues] = useState<{
    [k: string]: string[]
  }>({})

  const selectableAttributes = availableAttributes
  // .filter(
  //   (availableAttribute) =>
  //     !attributeValues.some(
  //       (attributeValue) => attributeValue.name === availableAttribute,
  //     ),
  // )

  const handlePageChange = (newPage: number) => {
    setPage(newPage)
    handleSearchProducts(newPage, pageSize)
  }
  const handlePageSizeChange = (newPageSize: number) => {
    setPage(0)
    setPageSize(newPageSize)
    handleSearchProducts(0, newPageSize)
  }

  const handleAttributeMenuAction = (action: string, index: number) => {
    if (action === 'Delete') {
      const newAttributeValues = attributeValues.slice()
      newAttributeValues.splice(index, 1)
      setAttributeValues(newAttributeValues)
    } else if (action === 'Negate') {
      const newAttributeValue = attributeValues[index]
      if (!newAttributeValue) return
      const newAttributeValues = attributeValues.slice()
      newAttributeValue.action =
        newAttributeValue.action !== 'not in' ? 'not in' : 'in'
      newAttributeValues[index] = newAttributeValue
      setAttributeValues(newAttributeValues)
    }
  }

  const handleAttributeChange = (
    index: number,
    name: string | undefined,
    values?: string[],
  ) => {
    if (index < 0) return
    const newAttributeValues = attributeValues.slice()

    const existingAttributeValue = attributeValues[index]
    const newAttributeValue = {
      name: name || '',
      values: values || existingAttributeValue?.values || [],
      action: existingAttributeValue?.action || 'in',
    }
    newAttributeValues[index] = newAttributeValue

    setAttributeValues(newAttributeValues)
  }

  const handleAddAttribute = () => {
    const newAttributeValues = attributeValues.concat({
      name: '',
      values: [],
    })
    setAttributeValues(newAttributeValues)
  }

  const getSearchQuery = (q?: {
    offset?: number
    limit?: number
    sortBy?: string
    orderBy?: string
  }) => {
    // return filters in a way that can be sent to server
    const templateNameIds: { [k: string]: number } = {}
    availableTemplates.forEach((t) => (templateNameIds[t.name] = t.id))
    const templateIds =
      templates
        ?.map((templateName) => templateNameIds[templateName])
        .filter(isDefined) || []

    const attributes = attributeValues
      .filter((attributeValue) => attributeValue.name)
      .map((attribute) => {
        return `${attribute.name};${
          attribute.action || 'in'
        };${attribute.values.join(',')}`
      })

    const delim = '+'
    const query: Record<string, string> = {
      offset: `${q?.offset ?? page}`,
      limit: `${q?.limit || pageSize}`,
      sortBy: q?.sortBy || sortBy,
      orderBy: (q?.orderBy || orderBy) === 'Ascending' ? 'asc' : 'desc',
    }
    if (minPrice) query.minPrice = `${minPrice}`
    if (minPrice) query.minPrice = `${minPrice}`

    if (minPrice) query.minPrice = `${minPrice}`
    if (maxPrice) query.maxPrice = `${maxPrice}`
    if (minSold) query.minSold = `${minSold}`
    if (maxSold) query.maxSold = `${maxSold}`
    if (minQuantity) query.minQuantity = `${minQuantity}`
    if (maxQuantity) query.maxQuantity = `${maxQuantity}`

    if (skus.length) query.skus = skus.join(delim)
    if (templateIds.length) query.templateIds = templateIds.join(delim)
    if (search.length) query.search = search.split(' ').join(delim)
    if (hasTags.length) query.hasTags = hasTags.join(delim)
    if (missingTags.length) query.missingTags = missingTags.join(delim)
    if (attributes.length) query.attributes = attributes.join(delim)
    if (listedOn.length) query.listedOn = listedOn.join(delim)
    if (notListedOn.length) query.notListedOn = notListedOn.join(delim)

    // if (searchDescription) query.searchDescription = `${searchDescription}`
    if (matchAllAttributes) query.matchAllAttributes = `${matchAllAttributes}`
    if (matchAllListings) query.matchAllListings = `${matchAllListings}`

    return query
  }

  const handleClearFilter = () => {
    history.push({
      pathname: '/products',
    })
    setFiltersDrawerOpen(false)
    setPage(0)
    setSortBy('Updated')
    searchProducts({})
      .then((res) => {
        if (res.success && res.data) {
          setProducts(res.data.data)
          setCount(parseInt(`${res.data.items}`))
        }
      })
      .catch((e) => err(e))
  }

  const handleSearchProducts = (offset?: number, limit?: number) => {
    setPage(0)
    const query = getSearchQuery({ offset, limit })

    const queryString = new URLSearchParams(query).toString()
    history.push({
      pathname: '/products',
      search: queryString,
    })

    searchProducts(query)
      .then((res) => {
        if (res.success && res.data) {
          setProducts(res.data.data)
          setCount(parseInt(`${res.data.items}`))
        }
      })
      .catch((e) => err(e))
  }

  // Get Filter Values
  useEffect(() => {
    // Get attributes
    getAllSkus()
      .then((res) => {
        if (res.success && res.data) {
          setAvailableSkus(res.data)
        }
      })
      .catch((e) => err(e))
    // Get templates
    getAllTemplates()
      .then((res) => {
        if (res.success && res.data) {
          setAvailableTemplates(res.data)
        }
      })
      .catch((e) => err(e))
    // Get tags
    getAllTags()
      .then((res) => {
        if (res.success && res.data) {
          setAvailableTags(res.data)
        }
      })
      .catch((e) => err(e))
    // Get attribute values
    getAllAttributeValues()
      .then((res) => {
        if (res.success && res.data) {
          setAvailableAttributes(Object.keys(res.data))
          setAvailableAttributeValues(res.data)
        }
      })
      .catch((e) => err(e))
    // get channels
  }, [])

  /* Get Product Types */
  // useEffect(() => {
  //   if (isMounted.current) {
  //     getProducts()
  //       .then((res) => setProducts(res.data || []))
  //       .catch((e) => err(e))
  //     return () => {
  //       isMounted.current = false
  //     }
  //   }
  // })
  useEffect(() => {
    if (isMounted.current) {
      const query =
        querySearch || getSearchQuery({ offset: page, limit: pageSize })

      searchProducts(query)
        .then((res) => {
          if (res.success && res.data) {
            setProducts(res.data.data)
            setCount(parseInt(`${res.data.items}`))
          }
        })
        .catch((e) => err(e))
      return () => {
        isMounted.current = false
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /* Get Inventory Columns */
  useEffect(() => {
    if (isMounted.current) {
      getInventoryColumns()
        .then((res) => {
          if (res.success && res.data) {
            setColumns(split(res.data.columns, ','))
          } else {
            log(res.message)
          }
        })
        .catch((e) => err(e))
      return () => {
        isMounted.current = false
      }
    }
  })

  const handleColumnsChange = async (values: string[]) => {
    setColumns(values)
    void handleSetInventoryColumns(values)
  }

  const handleHeaderClick = (header: string, asc: boolean) => {
    const orderBy = asc ? 'Ascending' : 'Descending'
    setSortBy(header)
    setOrderBy(orderBy)
    const query = getSearchQuery({ sortBy: header, orderBy })
    searchProducts(query)
      .then((res) => {
        if (res.success && res.data) {
          setProducts(res.data.data)
          setCount(parseInt(`${res.data.items}`))
        }
      })
      .catch((e) => err(e))
  }

  const handleAddProduct = () => history.push('/addProduct')
  const handleProductClick = (productId: number) => {
    if (editProducts) return
    history.push(`/product/${productId}`)
  }
  const handleSetInventoryColumns = async (
    columns: string[],
  ): Promise<void> => {
    await setInventoryColumns(undefined, columns)
  }

  const handleDetailsToggle = () => {
    setParseDetails(!parseDetails)
  }

  const trailingDefaultColumn = [
    PRODUCT_COLUMNS_ENUM.LISTINGS,
    PRODUCT_COLUMNS_ENUM.CREATED,
  ]
  const allColumns = defaultColumns
    .concat(columns)
    .concat(trailingDefaultColumn)

  const sortableColumns = removeElements(allColumns, [
    ' ',
    PRODUCT_COLUMNS_ENUM.IMAGE,
  ])

  const handleProductUpdate = (
    product: GetProduct,
    column: string,
    value: string,
    isAttribute?: boolean,
  ) => {
    // no op
    // const newProducts = products.slice()
    // const productIndex = newProducts.findIndex(
    //   (p) => p.product.id === product.product.id,
    // )
    // if (productIndex === -1) return
    // const updateProduct = products[productIndex]
    // if (!updateProduct) return
    // if (!isAttribute) {
    //   // eslint-disable-next-line prettier/prettier
    //   (updateProduct.product as any)[column] = value
    // } else {
    //   const attrIndex = product.attributes.findIndex(
    //     (a) => a.templateAttribute.name === column,
    //   )
    //   if (attrIndex === -1) return
    //   const attr = product.attributes[attrIndex]
    //   if (!attr?.attribute) return
    //   attr.attribute.value = value
    //   updateProduct.attributes[attrIndex] = attr
    // }
    // // update array
    // newProducts[productIndex] = updateProduct
    // setProducts(newProducts)
    // console.log(column, value)
  }

  const getColumnValues = (
    getProduct: GetProduct,
  ): (JSX.Element | string)[] => {
    const { product, template, attributes, readiness, listedOn } = getProduct
    return allColumns.map((column) => {
      if (column === ' ') {
        if (!readiness) return ''
        if (readiness === 'ready') {
          return (
            <Tooltip
              title="Has Title, Description, and Required Attributes"
              key={`product-${product.id}-ready-icon`}
              sx={{ mt: 0.2 }}
            >
              <CheckCircleOutlineIcon
                color="success"
                sx={{ opacity: 0.8 }}
                fontSize="small"
              />
            </Tooltip>
          )
        } else if (readiness === 'partial') {
          return (
            <Tooltip
              title="Missing Recommended Attributes"
              key={`product-${product.id}-warn-icon`}
            >
              <InfoOutlinedIcon
                color="warning"
                sx={{ opacity: 0.8 }}
                fontSize="small"
              />
            </Tooltip>
          )
        } else if (readiness === 'error') {
          return (
            <Tooltip
              title="Missing Title, Description, or Required Attributes"
              key={`product-${product.id}-err-icon`}
            >
              <InfoOutlinedIcon color="error" fontSize="small" />
            </Tooltip>
          )
        }
      }

      if (column === PRODUCT_COLUMNS_ENUM.IMAGE) {
        const name =
          (getProduct.product.title || getProduct.template.name).replace(
            /\W/g,
            '',
          )?.[0] || '-'
        return (
          <Avatar
            key={getProduct.images?.[0]?.url}
            src={getProduct.images?.[0]?.url}
            alt="No Image"
            sx={{ width: 35, height: 35 }}
          >
            {name}
          </Avatar>
        )
      }
      if (column === PRODUCT_COLUMNS_ENUM.CREATED) {
        return formatDate(product[PRODUCT_FIELD_NAMES['Created'] || 'id'])
      }
      // column is product key
      const columnKey = Object.keys(PRODUCT_FIELD_NAMES).find(
        (key) => key === column,
      )
      if (
        columnKey &&
        (column === PRODUCT_COLUMNS_ENUM.TITLE ||
          column === PRODUCT_COLUMNS_ENUM.DESCRIPTION)
      ) {
        const value = product[PRODUCT_FIELD_NAMES[columnKey] || 'id'] + ''
        // parse or return raw
        return parseDetails ? replaceAttributes(value, getProduct) : value
      }

      if (column === PRODUCT_COLUMNS_ENUM.TEMPLATE) {
        return template.name
      }
      if (column === PRODUCT_COLUMNS_ENUM.ID) {
        return `${product.id}`
      }

      if (column === PRODUCT_COLUMNS_ENUM.TAGS) {
        return product.tags ? product.tags.split(',').join(', ') : '-'
      }

      if (column === PRODUCT_COLUMNS_ENUM.LISTINGS) {
        return listedOn?.join(', ') || ''
      }

      const productValue = columnKey
        ? `${product[PRODUCT_FIELD_NAMES[columnKey] || 'id']}`
        : undefined

      const hasAttribute = attributes.find(
        (attr) => attr.templateAttribute.name === column,
      )
      const attributeValue = hasAttribute?.attribute?.value
      // return product key or attribute value.

      if (columnKey && editProducts && productValue !== undefined) {
        return (
          <TextField
            key={`edit-product-${product.id}-value-${columnKey}`}
            size="small"
            inputProps={{
              style: {
                paddingTop: 0,
                paddingBottom: 0,
              },
            }}
            defaultValue={productValue}
            onChange={(e) =>
              handleProductUpdate(getProduct, columnKey, e.target.value)
            }
          />
        )
      }

      if (column && editProducts && hasAttribute) {
        return (
          <TextField
            key={`edit-product-${product.id}-attribute-${columnKey}`}
            size="small"
            inputProps={{
              style: {
                paddingTop: 0,
                paddingBottom: 0,
              },
            }}
            defaultValue={attributeValue}
            onBlur={(e) =>
              handleProductUpdate(getProduct, column, e.target.value, true)
            }
          />
        )
      }

      return productValue || attributeValue || '-'
    })
  }
  return (
    <div
      style={{
        width: '100%',
      }}
    >
      <NavBar />
      <Container>
        <Grid container sx={{ mt: 1 }} spacing={3}>
          <Grid item xs={12}>
            <Component>
              <Grid container spacing={1}>
                <Grid item xs={12} sx={{ position: 'relative' }}>
                  <Typography variant="h6">
                    Products
                    <Fab
                      color="primary"
                      size="small"
                      sx={{ position: 'absolute', right: 0 }}
                      onClick={handleAddProduct}
                    >
                      <AddIcon />
                    </Fab>
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Grid container>
                    <Grid item md={11} sm={10} xs={9}>
                      <MultipleSelect
                        label="Display Columns"
                        value={columns}
                        freeSolo={true}
                        options={unique(
                          (
                            Object.values(PRODUCT_COLUMNS_ENUM) as string[]
                          ).concat(availableAttributes),
                        )}
                        onChange={(values) => handleColumnsChange(values)}
                      />
                    </Grid>
                    <Grid item md={1} sm={2} xs={3}>
                      <ProductParseToggle
                        toggled={parseDetails}
                        onChange={handleDetailsToggle}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container justifyContent="center" spacing={1}>
                    <Grid item xs={12}>
                      <TextField
                        size="small"
                        fullWidth={true}
                        label="Search Keywords"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            handleSearchProducts()
                          }
                        }}
                      />
                    </Grid>
                    {/* <Grid item xs={2}>
                      <Grid container justifyContent="flex-end">
                        <FormControlLabel
                          style={{ marginTop: '0.5em' }}
                          label={
                            <Typography
                              variant="subtitle2"
                              color="textSecondary"
                            >
                              Search Description
                            </Typography>
                          }
                          value="end"
                          control={
                            <Switch
                              size="small"
                              color="primary"
                              checked={searchDescription}
                              onChange={() =>
                                setSearchDescription(!searchDescription)
                              }
                            />
                          }
                          labelPlacement="end"
                        />
                      </Grid>
                    </Grid> */}
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container justifyContent="space-between" spacing={2}>
                    <Grid item>
                      <Typography
                        sx={{ cursor: 'pointer' }}
                        onClick={() => setFiltersDrawerOpen(!filtersDrawerOpen)}
                      >
                        <strong>Filters</strong>
                        <ExpandMore expand={filtersDrawerOpen}>
                          <ExpandMoreIcon />
                        </ExpandMore>
                      </Typography>
                    </Grid>

                    <Grid item>
                      <Grid container justifyContent="flex-end" spacing={4}>
                        <Grid item>
                          <Button size="small" onClick={handleClearFilter}>
                            Clear
                          </Button>
                        </Grid>
                        <Grid item>
                          <Button
                            variant="contained"
                            onClick={() => handleSearchProducts(0)}
                            size="small"
                          >
                            Search
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                {/* <Grid item xs={12}>
                  <Grid container justifyContent="flex-end">
                    <Grid item>
                      <FormControlLabel
                        label={
                          <Typography variant="subtitle2" color="textSecondary">
                            Edit
                          </Typography>
                        }
                        value="end"
                        control={
                          <Switch
                            size="small"
                            color="primary"
                            checked={editProducts}
                            onChange={() => setEditProducts(!editProducts)}
                          />
                        }
                        labelPlacement="end"
                      />
                    </Grid>
                  </Grid>
                </Grid> */}
                <Grid item xs={12}>
                  <Collapse
                    orientation="vertical"
                    in={filtersDrawerOpen}
                    sx={{ pb: 2 }}
                  >
                    <Grid container spacing={2} justifyContent="space-between">
                      <Grid item xs={12}>
                        <Grid container justifyContent="center" spacing={1}>
                          <Grid item xs={2}>
                            <SingleSelect
                              key={`select-sortby-${sortBy}`}
                              label="Sort By"
                              freeSolo={false}
                              defaultOption={false}
                              value={sortBy || PRODUCT_COLUMNS_ENUM.CREATED}
                              options={sortableColumns}
                              onChange={(sortBy) =>
                                setSortBy(
                                  sortBy || PRODUCT_COLUMNS_ENUM.CREATED,
                                )
                              }
                            />
                          </Grid>
                          <Grid item xs={2}>
                            <SingleSelect
                              label="Order By"
                              freeSolo={false}
                              defaultOption={false}
                              value={orderBy || 'Descending'}
                              options={['Ascending', 'Descending']}
                              onChange={(orderBy) =>
                                setOrderBy(orderBy || 'Descending')
                              }
                            />
                          </Grid>
                          <Grid item xs={2}>
                            <TextField
                              label="Min Price"
                              size="small"
                              type="number"
                              fullWidth={true}
                              value={minPrice ?? ''}
                              onChange={(e) =>
                                setMinPrice(parseFloat(e.target.value))
                              }
                            ></TextField>
                          </Grid>
                          <Grid item xs={2}>
                            <TextField
                              label="Max Price"
                              size="small"
                              type="number"
                              fullWidth={true}
                              value={maxPrice ?? ''}
                              onChange={(e) =>
                                setMaxPrice(parseFloat(e.target.value))
                              }
                            ></TextField>
                          </Grid>
                          {/* <Grid item sm={4} xs={12}>
                            <Grid container justifyContent="center" spacing={1}>
                              <Grid item xs={12}>
                                <Typography>
                                  <strong>Sold</strong>
                                </Typography>
                              </Grid>
                              <Grid item xs={6}>
                                <TextField
                                  label="Min"
                                  size="small"
                                  type="number"
                                  value={minSold ?? ''}
                                  onChange={(e) =>
                                    setMinSold(parseFloat(e.target.value))
                                  }
                                ></TextField>
                              </Grid>
                              <Grid item xs={6}>
                                <TextField
                                  label="Max"
                                  size="small"
                                  type="number"
                                  value={maxSold ?? ''}
                                  onChange={(e) =>
                                    setMaxSold(parseFloat(e.target.value))
                                  }
                                ></TextField>
                              </Grid>
                            </Grid>
                          </Grid> */}
                          <Grid item xs={2}>
                            <TextField
                              label="Min Quantity"
                              size="small"
                              type="number"
                              fullWidth={true}
                              value={minQuantity ?? ''}
                              onChange={(e) =>
                                setMinQuantity(parseFloat(e.target.value))
                              }
                            ></TextField>
                          </Grid>
                          <Grid item xs={2}>
                            <TextField
                              label="Max Quantity"
                              size="small"
                              type="number"
                              fullWidth={true}
                              value={maxQuantity ?? ''}
                              onChange={(e) =>
                                setMaxQuantity(parseFloat(e.target.value))
                              }
                            ></TextField>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={3}>
                        <MultipleSelect
                          value={skus || []}
                          label="SKU"
                          options={availableSkus}
                          onChange={setSkus}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <MultipleSelect
                          value={templates || []}
                          label="Template"
                          options={availableTemplates.map((t) => t.name)}
                          onChange={setTemplates}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <MultipleSelect
                          value={hasTags || []}
                          label="Has Tags"
                          options={availableTags}
                          onChange={setHasTags}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <MultipleSelect
                          value={missingTags || []}
                          label="Missing Tags"
                          options={availableTags}
                          onChange={setMissingTags}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container justifyContent="center" spacing={1}>
                          <Grid item sm={6} xs={12}>
                            <MultipleSelect
                              value={listedOn || []}
                              label="Listed On"
                              options={availableListedOn}
                              onChange={setListedOn}
                            />
                          </Grid>
                          <Grid item sm={6} xs={12}>
                            <MultipleSelect
                              value={notListedOn || []}
                              label="Not Listed On"
                              options={availableListedOn}
                              onChange={setNotListedOn}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Grid container justifyContent="flex-end">
                              <FormControlLabel
                                label={
                                  <Typography
                                    variant="subtitle2"
                                    color="textSecondary"
                                  >
                                    Match All
                                  </Typography>
                                }
                                value="end"
                                control={
                                  <Switch
                                    size="small"
                                    color="primary"
                                    checked={matchAllListings}
                                    onChange={() =>
                                      setMatchAllListings(!matchAllListings)
                                    }
                                  />
                                }
                                labelPlacement="end"
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container justifyContent="center" spacing={1}>
                          {attributeValues.map((attributeValue, index) => {
                            return (
                              <Grid
                                item
                                xs={12}
                                key={attributeValue.name + index}
                              >
                                <Grid
                                  container
                                  justifyContent="center"
                                  spacing={1}
                                >
                                  <Grid item sm={6} xs={12}>
                                    <Grid container justifyContent="center">
                                      <Grid item xs={12}>
                                        <SingleSelect
                                          label="Attribute"
                                          freeSolo={false}
                                          value={attributeValue.name}
                                          options={selectableAttributes}
                                          onChange={(value) =>
                                            handleAttributeChange(index, value)
                                          }
                                        />
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                  <Grid item sm={5} xs={11}>
                                    <Grid container justifyContent="center">
                                      <Grid item xs={12}>
                                        <MultipleSelect
                                          freeSolo={true}
                                          label={
                                            attributeValue.action !== 'not in'
                                              ? 'Attribute Values'
                                              : 'Not Attribute Values'
                                          }
                                          value={attributeValue.values}
                                          options={
                                            availableAttributeValues[
                                              attributeValue.name
                                            ]
                                          }
                                          onChange={(values) =>
                                            handleAttributeChange(
                                              index,
                                              attributeValue.name,
                                              values,
                                            )
                                          }
                                        />
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                  <Grid item xs={1}>
                                    <VerticalIconMenu
                                      onClick={(action) =>
                                        handleAttributeMenuAction(action, index)
                                      }
                                      options={['Delete', 'Negate']}
                                    />
                                  </Grid>
                                </Grid>
                              </Grid>
                            )
                          })}
                          <Grid item xs={12}>
                            <Grid container justifyContent="flex-end">
                              <FormControlLabel
                                label={
                                  <Typography
                                    variant="subtitle2"
                                    color="textSecondary"
                                  >
                                    Match All
                                  </Typography>
                                }
                                value="end"
                                control={
                                  <Switch
                                    size="small"
                                    color="primary"
                                    checked={matchAllAttributes}
                                    onChange={() =>
                                      setMatchAllAttributes(!matchAllAttributes)
                                    }
                                  />
                                }
                                labelPlacement="end"
                              />
                            </Grid>
                            <Button
                              onClick={handleAddAttribute}
                              variant="outlined"
                              size="small"
                            >
                              Add Attribute
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Collapse>
                </Grid>
                <Grid item xs={12}>
                  <PaginatedTable
                    key={`paginated-table-page-${page}-${sortBy}-${orderBy}`}
                    data={products}
                    head={allColumns}
                    sortableHeaders={sortableColumns}
                    sortBy={sortBy}
                    orderBy={orderBy === 'Ascending' ? 'asc' : 'desc'}
                    row={(product) => getColumnValues(product)}
                    onRowClick={(row) => handleProductClick(row.product.id)}
                    onHeaderClick={handleHeaderClick}
                    sortable={true}
                    count={count}
                    page={page}
                    pageSize={pageSize}
                    onPageChange={handlePageChange}
                    onPageSizeChange={handlePageSizeChange}
                  ></PaginatedTable>
                </Grid>
              </Grid>
            </Component>
          </Grid>
        </Grid>
        <Box m={50}></Box>
      </Container>
    </div>
  )
}
