import React, { ReactNode } from 'react'

export default function CodeInline({
  children,
  size,
}: {
  children: ReactNode
  size?: 's' | 'm'
}): JSX.Element {
  return (
    <code
      style={{
        lineHeight: 1.5,
        letterSpacing: 0,
        fontFamily: 'Menlo,Consolas,"Droid Sans Mono",monospace',
        fontWeight: 400,
        display: 'inline-block',
        padding: size !== 's' ? '4px 8px' : '2px 4px',
        color: '#1C2025',
        backgroundColor: '#F3F6F9',
        border: '1px solid',
        borderColor: ' #DAE2ED',
        borderRadius: '5px',
        fontSize: '0.8125rem',
        direction: 'ltr',
      }}
    >
      {children}
    </code>
  )
}
