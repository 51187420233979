import React, { useEffect, useState } from 'react'
import { GetProduct, GetProductTemplate } from '../../../../api/product'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import { ProductAttributeInputText } from '../../../../pages/products/product/ProductAttributeInputText'
import {
  GetIntegrationResult,
  IntegrationName,
  createIntegration,
  getIntegration,
  listProduct,
  unlistProduct,
} from '../../../../api/integration'
import {
  err,
  getDescriptionValue,
  getTitleValue,
} from '../../../../utils/functions'
import {
  SetSquareTemplateIntegration,
  SquareLocation,
  SquareProductIntegration,
  SquareTemplateIntegration,
} from '../../../../types/Square.types'
import Alert, { AlertInput, handleAlert } from '../../../common/Alert'
import { Integration } from '../../../../api/types'
import {
  getSquareIntegrations,
  getSquareLocations,
  setSquareProductIntegration,
  setSquareTemplateIntegration,
} from '../../../../api/integrations/square'
import { ProductAttributeInputNumber } from '../../../../pages/products/product/ProductAttributeInputNumber'
import { SingleSelect } from '../../../common/SingleSelect'
import ListSyncComponent from '../common/ListSyncComponent'
import LoadingBox from '../../../common/LoadingBox'
import { ProductTitleDescription } from '../../ProductTitleDescription'
import { ListUnlistButtons } from '../common/ListUnlistButtons'

interface SquareTemplateIntegrationComponentProps {
  integration?: GetIntegrationResult<IntegrationName.SQUARE> | undefined
  template?: GetProductTemplate
  product?: GetProduct
  onUpdate?: () => void
}

export default function SquareTemplateIntegrationComponent({
  integration: integrationResult,
  template,
  product,
}: SquareTemplateIntegrationComponentProps): JSX.Element {
  const [loading, setLoading] = useState<boolean>(!integrationResult)
  const [templateIntegration, setTemplateIntegration] = useState<
    SquareTemplateIntegration | undefined
  >()
  const [productIntegration, setProductIntegration] = useState<
    SquareProductIntegration | undefined
  >()
  const [integration, setIntegration] = useState<Integration | undefined>(
    integrationResult?.integration,
  )
  const [title, setTitle] = useState<string>('')
  const [description, setDescription] = useState<string>('')
  const [locations, setLocations] = useState<SquareLocation[]>([])
  const [selectedLocation, setSelectedLocation] = useState<
    | {
        id: string
        name: string
      }
    | undefined
  >()

  const [list, setList] = useState<boolean>(false)
  const [sync, setSync] = useState<boolean>(false)
  const [syncQuantity, setSyncQuantity] = useState<boolean>(false)

  const [alert, setAlert] = useState<AlertInput>({ open: false })

  const handleSelectLocation = (locationName: string | undefined) => {
    if (!locationName) return
    const location = locations.find((l) => l.name === locationName)
    if (!location) {
      setSelectedLocation(undefined)
      return
    }
    setSelectedLocation({ id: location.id, name: location.name })
  }

  const handleCreateIntegration = () => {
    createIntegration(IntegrationName.SQUARE)
      .then((res) => {
        if (res.success && res.data) {
          setIntegration(res.data)
        }
      })
      .catch((e) => {
        console.log(e)
      })
  }

  const handleListProduct = () => {
    const productId = product?.product.id
    if (!productId) {
      return
    }
    listProduct(IntegrationName.SQUARE, productId)
      .then((res) => {
        handleAlert(setAlert, res, 'Successfully listed product.')
      })
      .catch((e) => {
        setAlert({
          open: true,
          severity: 'error',
          message: `${e.response.data.message}`,
        })
      })
  }

  const handleUnlistProduct = () => {
    const productId = product?.product.id
    if (!integration?.id || !productId) return
    unlistProduct(IntegrationName.SQUARE, productId, integration.id)
      .then((res) => {
        handleAlert(setAlert, res, 'Successfully Unlisted.')
      })
      .catch((e) =>
        setAlert({
          open: true,
          severity: 'error',
          message: e.response.data.message,
        }),
      )
  }

  // Get and set SquareTemplateIntegration, SquareLocations
  useEffect(() => {
    getSquareLocations()
      .then((res) => {
        if (res.data && res.success && Array.isArray(res.data)) {
          setLocations(res.data)
        }
      })
      .catch((e) => err(e))

    // Integration
    if (!integration) {
      getIntegration(IntegrationName.SQUARE)
        .then((res) => {
          setLoading(false)
          if (res.success && res.data) {
            setIntegration(res.data.integration)
          }
        })
        .catch((e) => err(e))
    }
  }, [integration])

  useEffect(() => {
    if (!integration) {
      return
    }

    const integrationId = integration.id
    const templateId = template?.template.id || product?.template.id
    // Square Template Integration
    if (integrationId && templateId) {
      getSquareIntegrations(integrationId, templateId, product?.product.id)
        .then((res) => {
          if (res.success && res.data) {
            const squareProductIntegration = res.data.productIntegration
            const squareTemplateIntegration = res.data.templateIntegration
            setTemplateIntegration(squareTemplateIntegration)
            setProductIntegration(squareProductIntegration)

            setList(
              squareProductIntegration?.list ??
                squareTemplateIntegration?.list ??
                false,
            )
            setSync(
              squareProductIntegration?.sync ??
                squareTemplateIntegration?.sync ??
                false,
            )
            setSyncQuantity(
              squareProductIntegration?.syncQuantity ??
                squareTemplateIntegration?.syncQuantity ??
                false,
            )
          }
        })
        .catch((e) => err(e))
    }
  }, [integration, product, template])

  useEffect(() => {
    const location = locations.find(
      (l) => l.id === templateIntegration?.locationId,
    )
    if (!location) return
    setSelectedLocation({ id: location?.id, name: location.name })
  }, [templateIntegration, locations])

  // Set title and description state
  useEffect(() => {
    const title = getTitleValue(
      productIntegration,
      templateIntegration,
      product,
    )
    const description = getDescriptionValue(
      productIntegration,
      templateIntegration,
      product,
    )

    setTitle(title)
    setDescription(description)
  }, [product, template, productIntegration, templateIntegration])

  const handleSave = () => {
    // if product, set product integration
    if (product && templateIntegration) {
      const updateProductIntegration = {
        templateIntegrationId: templateIntegration?.id,
        productId: product.product.id,
        title,
        description,
        locationId: selectedLocation?.id,
        list,
        sync,
        syncQuantity,
      }
      setSquareProductIntegration(updateProductIntegration)
        .then((res) => {
          handleAlert(setAlert, res, 'Set Square Product Integration.')
        })
        .catch((e) => {
          err(e)
        })
      return
    }

    // If Template, save template
    if (!integration || !template) return
    const squareTemplateIntegration: SetSquareTemplateIntegration = {
      templateId: template.template.id,
      integrationId: integration.id,
      locationId: selectedLocation?.id,
      title,
      description,
      list,
      sync,
      syncQuantity,
    }
    setSquareTemplateIntegration(squareTemplateIntegration)
      .then((res) => {
        handleAlert(setAlert, res, 'Set Square Template Integration.')
      })
      .catch((e) => {
        err(e)
      })
  }

  if (loading) {
    return <LoadingBox />
  }

  if (!integration) {
    return (
      <Grid container justifyContent="center" spacing={3}>
        <Grid item xs={12}>
          <Typography>This integration has not been created yet.</Typography>
        </Grid>
        <Grid item xs={12}>
          <Button variant="contained" onClick={handleCreateIntegration}>
            Create
          </Button>
        </Grid>
      </Grid>
    )
  }

  return (
    <Grid container justifyContent="center" spacing={2}>
      <Grid item xs={12}>
        <ListUnlistButtons
          list={list}
          hasProductIntegration={!!productIntegration}
          product={product}
          onList={handleListProduct}
          onUnlist={handleUnlistProduct}
        />
      </Grid>
      <Grid item xs={12}>
        <ListSyncComponent
          list={list}
          sync={sync}
          syncQuantity={syncQuantity}
          onListChange={setList}
          onSyncChange={setSync}
          onSyncQuantityChange={setSyncQuantity}
        />
      </Grid>

      <Grid item xs={12}>
        <ProductTitleDescription
          template={template}
          product={product}
          title={title}
          description={description}
          required={!!product}
          onTitleChange={setTitle}
          onDescriptionChange={setDescription}
          disableUnparsedEdit={true}
        />
      </Grid>

      {product && (
        <>
          <Grid item xs={12}>
            <ProductAttributeInputText
              label="SKU"
              value={product.product.sku}
              disabled={true}
            />
          </Grid>

          <Grid item xs={12}>
            <ProductAttributeInputText
              label="Condition"
              value={product.product.condition}
              disabled={true}
            />
          </Grid>
          <Grid item xs={12}>
            <ProductAttributeInputNumber
              label="Price"
              value={product.product.price}
              disabled={true}
            />
          </Grid>
          <Grid item xs={12}>
            <ProductAttributeInputNumber
              label="Quantity"
              value={product.product.quantity}
              disabled={true}
            />
          </Grid>
        </>
      )}

      <Grid item xs={12}>
        <SingleSelect
          key={`select-square-location-${selectedLocation?.id}`}
          label="Square Location"
          value={selectedLocation?.name || ''}
          onChange={(location) => handleSelectLocation(location)}
          options={locations.map((l) => l.name)}
        />
      </Grid>

      <Grid item xs={12}>
        <Grid container justifyContent="flex-end">
          <Grid item>
            <Button variant="contained" onClick={handleSave}>
              Save
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Alert
        alert={alert}
        onClose={() => setAlert({ ...alert, open: false })}
      ></Alert>
    </Grid>
  )
}
