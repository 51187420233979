import React, { useState } from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import {
  Aspect,
  AspectUsageEnum,
  EbayAspectTemplateIntegration,
  EbayTemplateIntegration,
} from '../../../../types/Ebay.types'
import {
  GetProductTemplateAttribute,
  ProductTemplateAttributeInput,
  updateProductTemplateAttributes,
} from '../../../../api/product'
import { useMemo } from 'react'
import AspectSection from './AspectSection'
import { AspectIntegrationInput } from './EbayTemplateIntegrationComponent'
import Button from '@mui/material/Button'
import { DEFAULT_ATTRIBUTE_TYPE } from '../../../../utils/constants'
import { getAttributeTag, isLike } from '../../../../utils/functions'
import Modal from '../../../common/Modal'
import CodeInline from '../../../common/CodeInline'
import Alert, { AlertInput, handleAlert } from '../../../common/Alert'
import { setEbayTemplateIntegration } from '../../../../api/integrations/ebay'
import { AttributeConstraintEnum } from '../../../../api/types'

const SUBMIT_MODAL_CLOSE_COOLDOWN = 2500

export default function ItemAspects({
  templateIntegration,
  aspects,
  aspectIntegrations,
  templateAttributes,
  onChange,
  onUpdate,
}: {
  templateIntegration?: EbayTemplateIntegration
  aspects: Aspect[]
  aspectIntegrations: EbayAspectTemplateIntegration[]
  templateAttributes: GetProductTemplateAttribute[]
  onChange: (aspect: EbayAspectTemplateIntegration) => void
  onUpdate?: () => void
}): JSX.Element {
  const [open, setOpen] = useState<boolean>(false)
  const [addAspectAttributesType, setAddAspectAttributesType] =
    useState<string>('')
  const [addAspectAttributes, setAddAspectAttributes] = useState<
    ProductTemplateAttributeInput[]
  >([])

  const [alert, setAlert] = useState<AlertInput>({ open: false })

  const requiredAspects = useMemo(
    () => aspects.filter((aspect) => aspect.aspectConstraint.aspectRequired),
    [aspects],
  )
  const reccommendedAspects = useMemo(
    () =>
      aspects.filter(
        (aspect) =>
          !aspect.aspectConstraint.aspectRequired &&
          aspect.aspectConstraint.aspectUsage === AspectUsageEnum.RECOMMENDED,
      ),
    [aspects],
  )
  const optionalAspects = useMemo(
    () =>
      aspects.filter(
        (aspect) =>
          !aspect.aspectConstraint.aspectRequired &&
          aspect.aspectConstraint.aspectUsage === AspectUsageEnum.OPTIONAL,
      ),
    [aspects],
  )

  const handleChange = (aspect: AspectIntegrationInput) => {
    onChange(aspect)
    // onUpdate?.()
  }

  const createTemplateAttributeInput = (
    aspect: Aspect,
    index?: number,
  ): ProductTemplateAttributeInput => {
    let constraint: AttributeConstraintEnum | undefined = undefined
    if (aspect.aspectConstraint.aspectRequired) {
      constraint = AttributeConstraintEnum.REQUIRED
    } else if (
      aspect.aspectConstraint.aspectUsage === AspectUsageEnum.RECOMMENDED
    ) {
      constraint = AttributeConstraintEnum.RECOMMENDED
    } else if (
      aspect.aspectConstraint.aspectUsage === AspectUsageEnum.OPTIONAL
    ) {
      constraint = AttributeConstraintEnum.OPTIONAL
    }
    return {
      attribute: {
        name: aspect.localizedAspectName,
        type: DEFAULT_ATTRIBUTE_TYPE,
        constraint,
        index,
      },
      attributeOptions: [],
    }
  }

  const getAspectsAsAttributes = (aspects: Aspect[]) => {
    const newIndex = templateAttributes.length // length
    const addTemplateAttributes: ProductTemplateAttributeInput[] = aspects.map(
      (aspect, i) => createTemplateAttributeInput(aspect, newIndex + i),
    )

    // filter away the attributes that already exist in templateAttributes
    const newAddTemplateAttributes = addTemplateAttributes.filter(
      (addTemplateAttribute) => {
        return !templateAttributes.find((templateAttribute) =>
          isLike(
            templateAttribute.attribute.name,
            addTemplateAttribute.attribute.name,
          ),
        )
      },
    )

    return newAddTemplateAttributes
  }

  const addAspectsAsAttributes = async (
    addTemplateAttributes: ProductTemplateAttributeInput[],
  ) => {
    const templateId = templateAttributes?.[0]?.attribute.templateId
    if (!templateId) {
      console.log('Invalid.')
      return
    }
    const res = await updateProductTemplateAttributes(
      templateId,
      addTemplateAttributes,
    )

    if (!res.success || !res.data) return
    if (!templateIntegration) return

    const allAttributes = templateAttributes.concat(res.data)
    // For each adding, add aspect integration
    const newAspectIntegrations = aspectIntegrations.map(
      (aspectIntegration) => {
        const aspectName = aspectIntegration.aspectName
        const attributeIndex = allAttributes.findIndex(
          (attribute) => attribute.attribute.name === aspectName,
        )

        if (attributeIndex !== -1) {
          // If attribute match exists, update value with attribute tag
          return {
            aspectName: aspectIntegration.aspectName,
            value: getAttributeTag(aspectName),
            dataType: aspectIntegration.dataType,
            required: aspectIntegration.required,
          }
        } else {
          // Return current
          return aspectIntegration
        }
      },
    )

    await setEbayTemplateIntegration(templateIntegration, newAspectIntegrations)

    let successMessage = 'Successfully added Template Attributes.'
    if (addTemplateAttributes.length === 1) {
      successMessage = `Successfully added '${
        addTemplateAttributes[0]?.attribute.name || 'Attribute'
      }' Template Attribute.`
    }

    handleAlert(setAlert, res, successMessage)
  }

  const aspectAttributesAllExist = (aspects: Aspect[]): boolean => {
    return (
      aspects.filter((aspect) =>
        templateAttributes.find((a) =>
          isLike(a.attribute.name, aspect.localizedAspectName),
        ),
      ).length === aspects.length
    )
  }

  return (
    <Grid container justifyContent="center" spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h6">Aspects</Typography>
        <Typography variant="body2">Add To Template</Typography>
      </Grid>
      <Grid item>
        <Grid container spacing={4} justifyContent="center">
          <Grid item>
            <Button
              variant="contained"
              size="small"
              onClick={() => {
                setAddAspectAttributesType('All')
                setAddAspectAttributes(getAspectsAsAttributes(aspects))
                setOpen(true)
              }}
              disabled={aspectAttributesAllExist(aspects)}
            >
              Add All
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              size="small"
              onClick={() => {
                setAddAspectAttributesType('Required')
                setAddAspectAttributes(getAspectsAsAttributes(requiredAspects))
                setOpen(true)
              }}
              disabled={aspectAttributesAllExist(requiredAspects)}
            >
              Add Required
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              size="small"
              onClick={async () => {
                setAddAspectAttributesType('Reccommended')
                setAddAspectAttributes(
                  getAspectsAsAttributes(reccommendedAspects),
                )
                setOpen(true)
              }}
              disabled={aspectAttributesAllExist(reccommendedAspects)}
            >
              Add Reccommended
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              size="small"
              onClick={async () => {
                setAddAspectAttributesType('Optional')
                setAddAspectAttributes(getAspectsAsAttributes(optionalAspects))
                setOpen(true)
              }}
              disabled={aspectAttributesAllExist(optionalAspects)}
            >
              Add Optional
            </Button>
          </Grid>
        </Grid>
      </Grid>
      {!aspects.length && (
        <Grid item xs={12}>
          <Typography>Must set category.</Typography>
        </Grid>
      )}

      {requiredAspects.length ? (
        <Grid item xs={12}>
          <AspectSection
            label="Required Aspects"
            aspects={requiredAspects}
            aspectIntegrations={aspectIntegrations}
            onChange={handleChange}
            onUpdate={onUpdate}
            templateAttributes={templateAttributes}
            open={true}
          />
        </Grid>
      ) : null}
      {reccommendedAspects.length ? (
        <Grid item xs={12}>
          <AspectSection
            label="Recommended Aspects"
            aspects={reccommendedAspects}
            aspectIntegrations={aspectIntegrations}
            onChange={handleChange}
            onUpdate={onUpdate}
            templateAttributes={templateAttributes}
            open={true}
          />
        </Grid>
      ) : null}
      {optionalAspects.length ? (
        <Grid item xs={12}>
          <AspectSection
            label="Optional Aspects"
            aspects={optionalAspects}
            aspectIntegrations={aspectIntegrations}
            onChange={handleChange}
            onUpdate={onUpdate}
            templateAttributes={templateAttributes}
            open={true}
          />
        </Grid>
      ) : null}
      <Modal
        open={open}
        onClose={() => {
          setOpen(false)
        }}
        onSubmit={() => {
          void addAspectsAsAttributes(addAspectAttributes)
          setTimeout(() => {
            setOpen(false)
          }, SUBMIT_MODAL_CLOSE_COOLDOWN)
        }}
      >
        <Grid container justifyContent="center" spacing={3}>
          <Grid item>
            <Typography variant="h6">
              Add {addAspectAttributesType} Aspects as Template Attributes
            </Typography>
          </Grid>
          <Grid item>
            <Typography>
              This action will add the following Template Attributes
            </Typography>
          </Grid>

          {addAspectAttributes.map((attribute) => (
            <Grid key={attribute.attribute.name} item xs={12}>
              <Grid container spacing={3} justifyContent="center">
                <Grid item xs={8}>
                  <CodeInline>{attribute.attribute.name}</CodeInline>
                </Grid>
                <Grid item xs={4}>
                  <Button
                    size="small"
                    variant="outlined"
                    onClick={async () => {
                      await addAspectsAsAttributes([attribute])

                      // remove this attribute from list
                      setAddAspectAttributes(
                        addAspectAttributes.filter(
                          (addAspectAttribute) =>
                            addAspectAttribute.attribute.name !==
                            attribute.attribute.name,
                        ),
                      )
                    }}
                  >
                    Add
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Modal>
      <Alert
        alert={alert}
        onClose={() => setAlert({ ...alert, open: false })}
      ></Alert>
    </Grid>
  )
}
