import React, { useState } from 'react'
import { GetIntegrationResult, IntegrationName } from '../../api/integration'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import { squareBulkImport } from '../../api/integrations/square'
import Alert, { AlertInput, handleAlert } from '../../components/common/Alert'
import { err } from '../../utils/functions'

export default function SquareIntegration({
  integration,
}: {
  integration: GetIntegrationResult<IntegrationName.SQUARE>
}): JSX.Element {
  const [alert, setAlert] = useState<AlertInput>({ open: false })

  const handleAppStoreClick = () => {
    if (!integration.oAuthUrl) return
    window.open(integration.oAuthUrl, '_blank')
  }

  const handleBulkImport = () => {
    squareBulkImport()
      .then((res) => {
        handleAlert(setAlert, res, 'Bulk import process started.')
      })
      .catch((e) => err(e))
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        {/* Authentication */}
        <Grid container justifyContent="center" spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h6">Authentication</Typography>
          </Grid>
          {integration.authenticated ? (
            <Grid item xs={12}>
              <Grid container justifyContent="center">
                <Grid item xs={12}>
                  <Typography variant="h6">Store Details</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography>
                    {integration.integration?.shop || 'Store name not set.'}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          ) : null}
          {integration.authenticated ? (
            <>
              <Grid item xs={12}>
                <Typography>
                  To authenticate app access, please add the TeamAutomate Square
                  app to your store.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Grid
                  container
                  justifyContent="center"
                  spacing={1}
                  direction="column"
                >
                  <Grid item>
                    <Button variant="contained" onClick={handleAppStoreClick}>
                      Re-Authorize
                    </Button>
                  </Grid>
                  <Grid item>
                    <Typography variant="body2">
                      Authentication Last Updated:{' '}
                      {integration.integration?.updatedAt
                        ? new Date(
                            integration.integration?.updatedAt,
                          ).toLocaleString()
                        : 'Unknown'}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={12}>
                <Typography>
                  To authenticate app access, please authorize the TeamAutomate
                  app on your Square Store.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Button variant="contained" onClick={handleAppStoreClick}>
                  Open
                </Button>
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            <Button variant="contained" onClick={handleBulkImport}>
              Bulk Import Inventory
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Alert
        alert={alert}
        onClose={() => setAlert({ ...alert, open: false })}
      ></Alert>
    </Grid>
  )
}
