import React from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { PriceSuggestion } from '../../../../types/Ebay.types'
import { round } from '../../../../utils/functions'
import { BarChart } from '@mui/x-charts/BarChart'

interface EbayPriceSuggestionProps {
  priceSuggestion: PriceSuggestion | undefined
}

export default function EbayPriceSuggestion({
  priceSuggestion,
}: EbayPriceSuggestionProps): JSX.Element {
  return (
    <Grid container justifyContent="center" spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h5">Price Suggestion</Typography>
        <Typography variant="body2">
          Based on similar recently sold items within this category
        </Typography>
        <Typography variant="body2">
          View the search{' '}
          <a
            href={priceSuggestion?.searchUrl || ''}
            target="_blank"
            rel="noreferrer"
          >
            here
          </a>
        </Typography>
      </Grid>

      <Grid item xs={12}>
        {priceSuggestion?.graph.length ? (
          <>
            <Grid container justifyContent="center" spacing={1}>
              <Grid item xs={12}>
                <Typography variant="h6">
                  Price Metric Average{' '}
                  {`$${round(priceSuggestion.graphAverage || 0)}`}
                </Typography>
              </Grid>
              <Grid item md={8} xs={12}>
                <BarChart
                  skipAnimation={true}
                  xAxis={[
                    {
                      id: 'Price Metrics',
                      data: priceSuggestion.graph.map(
                        (g) => `${g.low || 'Under'} - ${g.high || 'Over'}`,
                      ),
                      scaleType: 'band',
                      // categoryGapRatio: 0.4,
                      // barGapRatio: 0.8,
                    },
                  ]}
                  series={[
                    {
                      data: priceSuggestion.graph.map((g) => g.count || 0),
                    },
                  ]}
                  margin={{
                    top: 25,
                    bottom: 25,
                    left: 50,
                    right: 50,
                  }}
                  colors={['#99CCFF']}
                  height={200}
                  leftAxis={null}
                  sx={{
                    '.MuiBarElement-root': {
                      ry: '3',
                      rx: '3',
                      stroke: '#99CCFF',
                      fill: '#99CCFF',
                      fillOpacity: '0.4',
                      strokeOpacity: '1',
                    },
                  }}
                />
              </Grid>
            </Grid>
          </>
        ) : null}

        {!priceSuggestion?.graph.length && priceSuggestion?.selection.length ? (
          <>
            <Grid container justifyContent="center" spacing={1}>
              <Grid item xs={12}>
                <Typography variant="h6">
                  Price Range Average{' '}
                  <strong>
                    {`$${round(priceSuggestion.selectionAverage || 0)}`}
                  </strong>
                </Typography>
              </Grid>
              <Grid item md={8} xs={12}>
                <BarChart
                  skipAnimation={true}
                  xAxis={[
                    {
                      id: 'Price Ranges',
                      data: priceSuggestion.selection.map(
                        (selection) =>
                          `${selection.low || 'Under'} - ${
                            selection.high || 'Over'
                          }`,
                      ),
                      scaleType: 'band',
                      // categoryGapRatio: 0.4,
                      // barGapRatio: 0.8,
                    },
                  ]}
                  series={[
                    {
                      data: priceSuggestion.selection.map(() => 1),
                    },
                  ]}
                  colors={['#99CCFF']}
                  height={200}
                  leftAxis={null}
                  margin={{
                    top: 25,
                    bottom: 25,
                    left: 50,
                    right: 50,
                  }}
                  sx={{
                    '.MuiBarElement-root': {
                      ry: '3',
                      rx: '3',
                      stroke: '#99CCFF',
                      fill: '#99CCFF',
                      fillOpacity: '0.4',
                      strokeOpacity: '1',
                    },
                  }}
                />
              </Grid>
            </Grid>
          </>
        ) : null}
      </Grid>
      {/* <Grid item xs={12}>
      {priceSuggestion?.selection.length ? (
        <>
          <Typography variant="h6">Price Ranges</Typography>
          <Typography variant="body2">
            Price Selection Ranges. Metrics on count of each price
            are not available.
          </Typography>
          {priceSuggestion?.selection.map((priceSelection) => (
            <Typography
              variant="body1"
              key={`${priceSelection.low}-${priceSelection.high}`}
            >
              {priceSelection.low
                ? `$${priceSelection.low}.00`
                : 'Under'}{' '}
              to{' '}
              {priceSelection.high
                ? `$${priceSelection.high}.00`
                : 'Over'}
            </Typography>
          ))}
        </>
      ) : null}
    </Grid> */}

      {/* <Grid item xs={12}>
      {priceSuggestion?.graph.length ? (
        <>
          <Typography variant="h6">Price Metrics</Typography>
          {priceSuggestion?.graph.map((priceSelection) => (
            <p key={`${priceSelection.low}-${priceSelection.high}`}>
              {priceSelection
                ? `$${priceSelection.low}.00`
                : 'Under'}{' '}
              -{' '}
              {priceSelection.high
                ? `$${priceSelection.high}.00`
                : 'Over'}{' '}
              : {priceSelection.count}
            </p>
          ))}
        </>
      ) : null}
    </Grid> */}
    </Grid>
  )
}
