import React, { useEffect, useState } from 'react'
import {
  GetIntegrationResult,
  IntegrationName,
  createIntegration,
  getIntegration,
  listProduct,
  unlistProduct,
} from '../../../../api/integration'
import { GetProduct, GetProductTemplate } from '../../../../api/product'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { ProductAttributeInputText } from '../../../../pages/products/product/ProductAttributeInputText'
import Button from '@mui/material/Button'
import Alert, { AlertInput, handleAlert } from '../../../common/Alert'
import { Integration } from '../../../../api/types'
import {
  err,
  getDescriptionValue,
  getTitleValue,
} from '../../../../utils/functions'
import ListSyncComponent from '../common/ListSyncComponent'
import {
  BigCommerceProductIntegration,
  BigCommerceTemplateIntegration,
  SetBigCommerceTemplateIntegration,
} from '../../../../types/BigCommerce.types'
import {
  getBigCommerceIntegrations,
  setBigCommerceProductIntegration,
  setBigCommerceTemplateIntegration,
} from '../../../../api/integrations/bigcommerce'
import LoadingBox from '../../../common/LoadingBox'
import { ListUnlistButtons } from '../common/ListUnlistButtons'
import { ProductTitleDescription } from '../../ProductTitleDescription'
import { ProductAttributeInputNumber } from '../../../../pages/products/product/ProductAttributeInputNumber'

interface BigCommerceTemplateIntegrationComponentProps {
  integration?: GetIntegrationResult<IntegrationName.BIG_COMMERCE> | undefined
  template?: GetProductTemplate
  product?: GetProduct
  onUpdate?: () => void
}
export default function BigCommerceTemplateIntegrationComponent({
  integration: integrationResult,
  template,
  product,
}: BigCommerceTemplateIntegrationComponentProps): JSX.Element {
  const [loading, setLoading] = useState<boolean>(!integrationResult)

  const [templateIntegration, setTemplateIntegration] = useState<
    BigCommerceTemplateIntegration | undefined
  >()
  const [productIntegration, setProductIntegration] = useState<
    BigCommerceProductIntegration | undefined
  >()
  const [integration, setIntegration] = useState<Integration | undefined>(
    integrationResult?.integration,
  )

  const [detailUpdate, setDetailUpdate] = useState<number>(0)

  const [title, setTitle] = useState<string>('')

  const [description, setDescription] = useState<string>('')
  const [category, setCategory] = useState<string>('')
  const [brand, setBrand] = useState<string>('')
  const [list, setList] = useState<boolean>(false)
  const [sync, setSync] = useState<boolean>(false)
  const [syncQuantity, setSyncQuantity] = useState<boolean>(false)

  const [alert, setAlert] = useState<AlertInput>({ open: false })

  const handleListProduct = () => {
    const productId = product?.product.id
    if (!productId) {
      return
    }
    listProduct(IntegrationName.BIG_COMMERCE, productId)
      .then((res) => {
        handleAlert(setAlert, res, 'Successfully listed product.')
      })
      .catch((e) => {
        setAlert({
          open: true,
          severity: 'error',
          message: `${e.response.data.message}`,
        })
      })
  }

  const handleUnlistProduct = () => {
    const productId = product?.product.id
    if (!integration?.id || !productId) return
    unlistProduct(IntegrationName.BIG_COMMERCE, productId, integration.id)
      .then((res) => {
        handleAlert(setAlert, res, 'Successfully Unlisted.')
      })
      .catch((e) =>
        setAlert({
          open: true,
          severity: 'error',
          message: e.response.data.message,
        }),
      )
  }

  const handleCreateIntegration = () => {
    createIntegration(IntegrationName.BIG_COMMERCE)
      .then((res) => {
        if (res.success && res.data) {
          setIntegration(res.data)
        }
      })
      .catch((e) => {
        console.log(e)
      })
  }

  // Get and set BigCommerceTemplateIntegration
  useEffect(() => {
    // Integration
    if (!integration) {
      getIntegration(IntegrationName.BIG_COMMERCE)
        .then((res) => {
          setLoading(false)
          if (res.success && res.data) {
            setIntegration(res.data.integration)
          }
        })
        .catch((e) => err(e))
    }
  }, [integration])

  useEffect(() => {
    if (!integration) {
      return
    }

    const integrationId = integration.id
    const templateId = template?.template.id || product?.template.id
    // Square Template Integration
    if (integrationId && templateId) {
      getBigCommerceIntegrations(integrationId, templateId, product?.product.id)
        .then((res) => {
          if (res.success && res.data) {
            const bigcommerceProductIntegration = res.data.productIntegration
            const bigcommerceTemplateIntegration = res.data.templateIntegration
            setTemplateIntegration(bigcommerceTemplateIntegration)
            setProductIntegration(bigcommerceProductIntegration)
            // TODO:
            // setBrand
            setList(
              bigcommerceProductIntegration?.list ??
                bigcommerceTemplateIntegration?.list ??
                false,
            )
            setSync(
              bigcommerceProductIntegration?.sync ??
                bigcommerceTemplateIntegration?.sync ??
                false,
            )
            setSyncQuantity(
              bigcommerceProductIntegration?.syncQuantity ??
                bigcommerceTemplateIntegration?.syncQuantity ??
                false,
            )
          }
        })
        .catch((e) => err(e))
    }
  }, [integration, product, template])

  // Set title and description state
  useEffect(() => {
    const title = getTitleValue(
      productIntegration,
      templateIntegration,
      product,
    )
    const description = getDescriptionValue(
      productIntegration,
      templateIntegration,
      product,
    )

    setTitle(title)
    setDescription(description)
    setDetailUpdate(detailUpdate + 1)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product, template, productIntegration, templateIntegration])

  const handleSave = () => {
    if (product && templateIntegration) {
      const updateProductIntegration = {
        templateIntegrationId: templateIntegration?.id,
        productId: product.product.id,
        title,
        description,
        list,
        sync,
        syncQuantity,
      }
      setBigCommerceProductIntegration(updateProductIntegration)
        .then((res) => {
          handleAlert(setAlert, res, 'Set BigCommerce Product Integration.')
        })
        .catch((e) => {
          err(e)
        })
      return
    }

    if (!integration || !template) return
    const shopifyTemplateIntegration: SetBigCommerceTemplateIntegration = {
      templateId: template?.template.id,
      integrationId: integration.id,
      // brandId: brand,
      title,
      description,
      list,
      sync,
      syncQuantity,
    }
    setBigCommerceTemplateIntegration(shopifyTemplateIntegration)
      .then((res) => {
        handleAlert(setAlert, res, 'Set BigCommerce Template Integration.')
      })
      .catch((e) => {
        err(e)
      })
  }

  if (loading) {
    return <LoadingBox />
  }

  if (!loading && !integration) {
    return (
      <Grid container justifyContent="center" spacing={2}>
        <Grid item xs={12}>
          <Typography>This integration has not been created yet.</Typography>
        </Grid>
        <Grid item xs={12}>
          <Button variant="contained" onClick={handleCreateIntegration}>
            Create
          </Button>
        </Grid>
      </Grid>
    )
  }

  return (
    <Grid container justifyContent="center" spacing={2}>
      <Grid item xs={12}>
        <ListUnlistButtons
          list={list}
          hasProductIntegration={!!productIntegration}
          product={product}
          onList={handleListProduct}
          onUnlist={handleUnlistProduct}
        />
      </Grid>
      <Grid item xs={12}>
        <ListSyncComponent
          list={list}
          sync={sync}
          syncQuantity={syncQuantity}
          onListChange={setList}
          onSyncChange={setSync}
          onSyncQuantityChange={setSyncQuantity}
        />
      </Grid>
      <Grid item xs={12}>
        <ProductTitleDescription
          key={`product-title-description-${detailUpdate}`}
          template={template}
          product={product}
          title={title}
          description={description}
          onTitleChange={setTitle}
          onDescriptionChange={setDescription}
          disableUnparsedEdit={true}
        />
      </Grid>

      {product && (
        <>
          <Grid item xs={12}>
            <ProductAttributeInputText
              label="SKU"
              value={product.product.sku}
              disabled={true}
            />
          </Grid>

          <Grid item xs={12}>
            <ProductAttributeInputText
              label="Condition"
              value={product.product.condition}
              disabled={true}
            />
          </Grid>
          <Grid item xs={12}>
            <ProductAttributeInputNumber
              label="Price"
              value={product.product.price}
              disabled={true}
            />
          </Grid>
          <Grid item xs={12}>
            <ProductAttributeInputNumber
              label="Quantity"
              value={product.product.quantity}
              disabled={true}
            />
          </Grid>
        </>
      )}

      <Grid item xs={12}>
        <ProductAttributeInputText
          label="Category"
          value={category}
          onChange={(category) => setCategory(category)}
          placeholder="[ Use Product Category ]"
        />
      </Grid>

      <Grid item xs={12}>
        <ProductAttributeInputText
          label="Brand"
          value={brand}
          onChange={(brand) => setBrand(brand)}
          placeholder="[ Use Product Brand ]"
        />
      </Grid>
      <Grid item xs={12}>
        <Grid container justifyContent="flex-end">
          <Grid item>
            <Button variant="contained" onClick={handleSave}>
              Save
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Alert
        alert={alert}
        onClose={() => setAlert({ ...alert, open: false })}
      ></Alert>
    </Grid>
  )
}
