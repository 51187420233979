import React, { useState } from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Collapse from '@mui/material/Collapse'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { styled } from '@mui/material/styles'
import IconButton, { IconButtonProps } from '@mui/material/IconButton'

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean
}

export default function CollapseTitle({
  title,
  subtitle,
  children,
  expanded: defaultExpanded,
  onClick,
}: {
  title: string | JSX.Element
  subtitle?: string | JSX.Element
  children: React.ReactNode
  expanded?: boolean
  onClick?: (expanded: boolean) => void
}): JSX.Element {
  const [expanded, setExpanded] = useState(defaultExpanded ?? true)
  const handleExpand = () => {
    onClick?.(!expanded)
    setExpanded(!expanded)
  }

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12}>
        <Typography component="div">
          <strong>{title}</strong>
          <ExpandMore expand={expanded} onClick={handleExpand}>
            <ExpandMoreIcon />
          </ExpandMore>
        </Typography>
        {subtitle ? <Typography component="div">{subtitle}</Typography> : null}
      </Grid>
      <Grid item xs={12}>
        <Collapse orientation="vertical" in={expanded}>
          <Grid container spacing={2} justifyContent="space-between">
            <Grid item xs={12}>
              {children}
            </Grid>
          </Grid>
        </Collapse>
      </Grid>

      {/* <Grid item xs={12} sm={6}>
        <ParameterSelect typeParameters={typeParameters} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <AspectInput
          key={aspect.localizedAspectName}
          aspect={aspect}
        />
      </Grid> */}
    </Grid>
  )
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props
  return <IconButton {...other} />
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}))
