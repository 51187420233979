import React from 'react'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormGroup from '@mui/material/FormGroup'
import Grid from '@mui/material/Grid'
import Switch from '@mui/material/Switch'
import Typography from '@mui/material/Typography'
import Tooltip from '@mui/material/Tooltip'

interface ListSyncComponentProps {
  list: boolean
  sync: boolean
  syncQuantity: boolean
  onListChange: (change: boolean) => void
  onSyncChange: (change: boolean) => void
  onSyncQuantityChange: (change: boolean) => void
}

export default function ListSyncComponent({
  list,
  sync,
  syncQuantity,
  onListChange,
  onSyncChange,
  onSyncQuantityChange,
}: ListSyncComponentProps): JSX.Element {
  const handleListChange = () => {
    if (list) {
      onSyncChange(false)
      onSyncQuantityChange(false)
    }
    onListChange(!list)
  }

  const handleSyncChange = () => {
    if (!list) return
    if (sync) {
      onSyncQuantityChange(false)
    }
    onSyncChange(!sync)
  }

  const handleSyncQuantityChange = () => {
    if (list && sync) {
      onSyncQuantityChange(!syncQuantity)
    }
  }

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12}>
        <Typography variant="h6">List and Sync</Typography>
      </Grid>

      <Grid item>
        <Grid container justifyContent="center" spacing={2}>
          <Grid item>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={list}
                    size="small"
                    onChange={handleListChange}
                  />
                }
                label="List"
              />
            </FormGroup>
          </Grid>
          <Grid item>
            <Tooltip title={!list ? 'List  must be set.' : ''}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      checked={sync}
                      size="small"
                      onChange={handleSyncChange}
                    />
                  }
                  label="Sync"
                />
              </FormGroup>
            </Tooltip>
          </Grid>
          <Grid item>
            <Tooltip title={!list || !sync ? 'List and Sync must be set.' : ''}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      checked={syncQuantity}
                      size="small"
                      onChange={handleSyncQuantityChange}
                    />
                  }
                  label="Sync Quantity"
                />
              </FormGroup>
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
