import React, { useState } from 'react'
import Grid from '@mui/material/Grid'
import Collapse from '@mui/material/Collapse'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Typography from '@mui/material/Typography'
import {
  Aspect,
  EbayAspectTemplateIntegration,
} from '../../../../types/Ebay.types'
import { GetProductTemplateAttribute } from '../../../../api/product'

import ExpandMore from '../../../common/ExpandMore'
import AspectGroup from './AspectGroup'
import { AspectIntegrationInput } from './EbayTemplateIntegrationComponent'

export default function AspectSection({
  label,
  aspects,
  aspectIntegrations,
  templateAttributes,
  open,
  onChange,
  onUpdate,
}: {
  label: string
  aspects: Aspect[]
  aspectIntegrations: EbayAspectTemplateIntegration[]
  templateAttributes: GetProductTemplateAttribute[]
  open?: boolean
  onChange: (aspect: AspectIntegrationInput) => void
  onUpdate?: () => void
}): JSX.Element {
  const [expanded, setExpanded] = useState(!!open)
  const handleExpand = () => setExpanded(!expanded)

  const getIntegrationAspect = (
    getAspect: Aspect,
  ): AspectIntegrationInput | undefined =>
    aspectIntegrations.find(
      (a) => a.aspectName === getAspect.localizedAspectName,
    )

  const handleChange = onChange

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="body1" component="div">
          <strong>{label}</strong>
          <ExpandMore expand={expanded} onClick={handleExpand}>
            <ExpandMoreIcon />
          </ExpandMore>
        </Typography>
        <Collapse in={expanded}>
          <Grid container spacing={2}>
            {aspects.map((aspect) => (
              <Grid item key={aspect.localizedAspectName} xs={12}>
                <AspectGroup
                  aspect={aspect}
                  templateAttributes={templateAttributes}
                  aspectIntegration={getIntegrationAspect(aspect)}
                  onChange={handleChange}
                  onUpdate={onUpdate}
                />
              </Grid>
            ))}
          </Grid>
        </Collapse>
      </Grid>
    </Grid>
  )
}
