import * as React from 'react'
import TextField from '@mui/material/TextField'
import Autocomplete, { autocompleteClasses } from '@mui/material/Autocomplete'
import useMediaQuery from '@mui/material/useMediaQuery'
import ListItem from '@mui/material/ListItem'
import Popper from '@mui/material/Popper'
import { useTheme, styled } from '@mui/material/styles'
import { VariableSizeList, ListChildComponentProps } from 'react-window'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import { GetProductTemplateAttribute } from '../../../../api/product'
import { forwardRef, useEffect, useState } from 'react'

const ADD_ALL_MAX_LENGTH = 25
const ADD_ALL_VALUE = '### ADD_ALL ###'
const LISTBOX_PADDING = 8 // px

type ListComponentDataSet = [
  { key: string; tabIndex: number; role: string },
  ExampleOptionValue,
]

const OuterElementContext = React.createContext({})

const OuterElementType = React.forwardRef<HTMLDivElement>(
  function OuterElementType(props, ref) {
    const outerProps = React.useContext(OuterElementContext)
    return <div ref={ref} {...props} {...outerProps} />
  },
)

function useResetCache(data: number) {
  const ref = React.useRef<VariableSizeList>(null)
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true)
    }
  }, [data])
  return ref
}

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: 'border-box',
    '& ul': {
      padding: 0,
      margin: 0,
    },
  },
})

interface ExampleOptionValue {
  value?: string
  isInTemplateOptions: boolean | undefined
  group?: string
}
interface AspectExamplesProps {
  label: string
  options: string[]
  templateAttribute: GetProductTemplateAttribute | undefined
  onChange: (option: string[]) => void
}

export default function AspectExamples({
  label,
  options,
  templateAttribute,
  onChange,
}: AspectExamplesProps): JSX.Element {
  const [optionValues, setOptionValues] = useState<ExampleOptionValue[]>([])

  useEffect(() => {
    const newOptionValues: ExampleOptionValue[] = options.map((option) => {
      const optionExists = templateAttribute
        ? templateAttribute.attributeOptions?.find(
            (opt) => opt.value === option,
          )
        : true // true because it is "in" undefined attribute. Meaning no reason to add.
      return {
        value: option,
        isInTemplateOptions: !!optionExists,
      }
    })

    const notAllOptionsInTemplateOptions =
      newOptionValues.length <= ADD_ALL_MAX_LENGTH &&
      newOptionValues.some((o) => !o.isInTemplateOptions)

    // If attribute is mapped and length is 25 or less, allow add all
    if (
      templateAttribute &&
      newOptionValues.length <= ADD_ALL_MAX_LENGTH &&
      notAllOptionsInTemplateOptions
    ) {
      // add addAll button
      const addAllHeader = {
        value: ADD_ALL_VALUE,
        isInTemplateOptions: undefined,
      }
      newOptionValues.unshift(addAllHeader)
    }

    setOptionValues(newOptionValues)
  }, [options, templateAttribute, templateAttribute?.attributeOptions])

  // Adapter for react-window
  const ListboxComponent = forwardRef<
    HTMLDivElement,
    React.HTMLAttributes<HTMLElement>
  >(function ListboxComponent(props, ref) {
    const { children, ...other } = props
    const itemData: React.ReactChild[] = []
    ;(children as React.ReactChild[]).forEach(
      (item: React.ReactChild & { children?: React.ReactChild[] }) => {
        itemData.push(item)
        itemData.push(...(item.children || []))
      },
    )

    const theme = useTheme()
    const smUp = useMediaQuery(theme.breakpoints.up('sm'), {
      noSsr: true,
    })
    const itemCount = itemData.length
    const itemSize = smUp ? 36 : 48

    const getChildSize = (child: React.ReactChild | undefined): number => {
      if (child && Object.prototype.hasOwnProperty.call(child, 'group')) {
        return 48
      }

      return itemSize
    }

    const getHeight = () => {
      if (itemCount > 8) {
        return 8 * itemSize
      }
      return itemData.map(getChildSize).reduce((a, b) => a + b, 0)
    }

    const gridRef = useResetCache(itemCount)

    return (
      <div ref={ref}>
        <OuterElementContext.Provider value={other}>
          <VariableSizeList
            itemData={itemData}
            height={getHeight() + 2 * LISTBOX_PADDING}
            width="100%"
            ref={gridRef}
            outerElementType={OuterElementType}
            innerElementType="ul"
            itemSize={(index) => getChildSize(itemData[index])}
            overscanCount={5}
            itemCount={itemCount}
          >
            {renderRow}
          </VariableSizeList>
        </OuterElementContext.Provider>
      </div>
    )
  })

  const handleAddAll = () => {
    onChange(options)
  }

  function renderRow(props: ListChildComponentProps) {
    const { data, index, style } = props

    const inlineStyle = {
      ...style,
      top: (style.top as number) + LISTBOX_PADDING,
    }

    const datas: ListComponentDataSet = data[index]
    const option = datas[1]

    // Add all button
    if (templateAttribute && option.value === ADD_ALL_VALUE) {
      return (
        <ListItem {...props} style={inlineStyle}>
          <Grid container justifyContent="center">
            <Button onClick={handleAddAll}>
              Add All To {templateAttribute.attribute.name}
            </Button>
          </Grid>
        </ListItem>
      )
    }

    return (
      <ListItem {...props} style={inlineStyle}>
        {!option.isInTemplateOptions ? (
          <Grid container justifyContent="space-between">
            <Grid
              item
              style={{
                width: '75%',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
              }}
            >
              {option.value}
            </Grid>
            <Grid
              item
              style={{
                width: '25%',
              }}
            >
              <Button
                size="small"
                onClick={() => {
                  onChange([option.value || ''])
                }}
              >
                Add
              </Button>
            </Grid>
          </Grid>
        ) : (
          option.value
        )}
      </ListItem>
    )
  }

  return (
    <Autocomplete
      size="small"
      id="virtualize-demo"
      disableListWrap
      fullWidth
      PopperComponent={StyledPopper}
      ListboxComponent={ListboxComponent}
      options={optionValues}
      renderInput={(params) => <TextField {...params} label={label} />}
      renderOption={(props, option) => {
        return [props, option] as React.ReactNode
      }}
      getOptionLabel={(option) => option.value || ''}
    />
  )
}
