import React, { useState } from 'react'
import Collapse from '@mui/material/Collapse'
import ExpandMore from '../../../components/common/ExpandMore'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import { SetProductAttribute, SetProductVariation } from '../../../api/product'
import ProductAttributeInputField from './ProductAttributeInputField'
import { ProductAttributeInputText } from './ProductAttributeInputText'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import ImageUpload from '../../../components/common/ImageUpload'
import { ProductTemplateAttribute } from '../../../api/types'
import { RequiredIntegrationTemplateAttributes } from '../../../api/integrations/ebay'
import IconButton from '@mui/material/IconButton'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'

const MENU_ITEM_HEIGHT = 48

enum MenuItemOptionEnum {
  MOVE_UP = 'Move Up',
  MOVE_DOWN = 'Move Down',
  // DELETE = 'Delete',
}

interface ProductVariationCollapseProps {
  productId?: number
  sku?: string
  price?: string
  quantity?: string
  variation: SetProductVariation
  templateVariationAttributes: ProductTemplateAttribute[]
  attributes: SetProductAttribute[]
  requiredAttributes: RequiredIntegrationTemplateAttributes[]
  onChange: (variation: SetProductVariation) => void
  onDelete: (name: string) => void
}
export default function ProductVariationCollapse({
  productId,
  price: defaultPrice,
  quantity: defaultQuantity,
  variation,
  attributes,
  templateVariationAttributes,
  requiredAttributes,
  onChange,
  onDelete,
}: ProductVariationCollapseProps): JSX.Element {
  const [expanded, setExpanded] = useState<boolean>(false)
  const handleExpand = () => setExpanded(!expanded)

  const [menuOpen, setMenuOpen] = useState<boolean>(false)
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const [name, setName] = useState<string>(variation.variation.name || '')
  const [cost, setCost] = useState<string>(`${variation.variation.cost || ''}`)
  const [price, setPrice] = useState<string>(
    `${variation.variation.price || defaultPrice || ''}`,
  )
  const [quantity, setQuantity] = useState<string>(
    `${variation.variation.quantity || defaultQuantity || ''}`,
  )
  const [sku, setSku] = useState<string>(variation.variation.sku || '')
  const [images, setImages] = useState<string[]>(
    variation.images.map((img) => img.url),
  )
  const [selectedImages, setSelectedImages] = useState<File[]>([])

  const menuOptions: MenuItemOptionEnum[] = [
    MenuItemOptionEnum.MOVE_UP,
    MenuItemOptionEnum.MOVE_DOWN,
    // MenuItemOptionEnum.DELETE,
  ]

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
    setMenuOpen(true)
  }
  const handleMenuClose = () => {
    setMenuOpen(false)
    setAnchorEl(null)
  }

  const handleMenuOptionClick = (
    e: React.MouseEvent<HTMLLIElement, MouseEvent>,
  ) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const action = (e.target as any).innerText
    const newVariation = variation
    const index = newVariation.variation.index
    if (
      action === MenuItemOptionEnum.MOVE_UP &&
      index !== undefined &&
      index > 0
    ) {
      newVariation.variation.index = index - 1
    } else if (action === MenuItemOptionEnum.MOVE_DOWN && index !== undefined) {
      newVariation.variation.index = index + 1
    }

    onChange(newVariation)
  }

  const handleVariationChange = (
    field: 'name' | 'sku' | 'quantity' | 'price' | 'cost',
    value: string,
  ) => {
    switch (field) {
      case 'price':
        setPrice(value)
        break
      case 'cost':
        setCost(value)
        break
      case 'quantity':
        setQuantity(value)
        break
      case 'sku':
        setSku(value)
        break
      case 'name':
        setName(value)
        break
      default:
      // no-op
    }
  }

  const handleSelectedImages = (files: File[]) => {
    setSelectedImages(files)
    const newVariation = variation
    newVariation.selectedImages = files
    onChange(newVariation)
  }

  const handleImages = (images: string[]) => {
    setImages(images)
    const newVariation = variation
    newVariation.images = images.map((img, i) => ({
      url: img,
      index: i,
    }))
    onChange(newVariation)
  }

  const handleBlur = () => {
    handleChange()
  }

  const handleChange = () => {
    const newVariation = variation
    newVariation.variation.price = price ? parseFloat(price) : undefined
    newVariation.variation.quantity = quantity ? parseInt(quantity) : undefined
    newVariation.variation.cost = cost ? parseInt(cost) : undefined
    newVariation.variation.sku = sku
    newVariation.variation.name = name
    newVariation.images = images.map((img, i) => ({
      url: img,
      index: i,
    }))
    newVariation.selectedImages = selectedImages
    onChange(newVariation)
  }

  const handleAttributeChange = (name: string, value: string) => {
    // find attribute
    const attribute = attributes.find(
      (attribute) => attribute.templateAttribute.name === name,
    )
    // update attribute value
    if (!attribute?.templateAttribute) return
    const newVariation = variation
    const index = newVariation.attributes.findIndex(
      (variationAttribute) =>
        variationAttribute.name === attribute.templateAttribute.name,
    )
    // add
    if (index === undefined || index < 0) {
      newVariation.attributes.push({
        name: attribute.templateAttribute.name,
        value,
      })
    } else if (index > -1) {
      // replace
      const newAttribute = newVariation.attributes[index]
      if (newVariation.attributes[index] && newAttribute) {
        newVariation.attributes[index] = { ...newAttribute, value }
      }
    }

    onChange(newVariation)
  }
  return (
    <Grid container justifyContent="center">
      <Grid item xs={11}>
        <Box
          sx={{
            border: '1px solid #ddd',
            borderRadius: '4px',
          }}
        >
          <Grid container>
            <Grid
              item
              xs={12}
              onClick={handleExpand}
              sx={{
                cursor: 'pointer',
              }}
            >
              <Typography
                variant="body1"
                component="div"
                sx={{ fontSize: 16, p: 1 }}
              >
                <strong>{variation.variation.name}</strong>
                <ExpandMore expand={expanded}>
                  <ExpandMoreIcon />
                </ExpandMore>
              </Typography>
            </Grid>
          </Grid>

          <Collapse in={expanded}>
            <Grid container spacing={2} p={2}>
              <Grid item xs={12}>
                <Typography>
                  Empty fields will default to product values.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <ProductAttributeInputText
                  label="Name"
                  required
                  value={name}
                  onChange={(name) => handleVariationChange('name', name)}
                  onBlur={handleBlur}
                />
              </Grid>
              <Grid item xs={12}>
                <ProductAttributeInputText
                  label="SKU"
                  required
                  value={sku}
                  onChange={(sku) => handleVariationChange('sku', sku)}
                  onBlur={handleBlur}
                />
              </Grid>
              <Grid item xs={12}>
                <ProductAttributeInputText
                  type="number"
                  label="Quantity"
                  value={quantity}
                  onChange={(quantity) =>
                    handleVariationChange('quantity', quantity)
                  }
                  onBlur={handleBlur}
                />
              </Grid>
              <Grid item xs={12}>
                <ProductAttributeInputText
                  type="number"
                  label="Price"
                  value={price}
                  onChange={(price) => handleVariationChange('price', price)}
                  onBlur={handleBlur}
                />
              </Grid>
              <Grid item xs={12}>
                <ProductAttributeInputText
                  type="number"
                  label="Cost"
                  value={cost}
                  onChange={(cost) => handleVariationChange('cost', cost)}
                  onBlur={handleBlur}
                />
              </Grid>
              <Grid item xs={12}>
                <ImageUpload
                  id={`variant-${variation.variation.name}-image-upload`}
                  productId={productId}
                  variationId={variation.variation.id}
                  images={images}
                  onChange={handleImages}
                  selectedImages={selectedImages}
                  onSelect={handleSelectedImages}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6">Attributes</Typography>
              </Grid>

              {templateVariationAttributes.map((templateVariationAttribute) => {
                const variationAttribute = variation.attributes.find(
                  (variationAttribute) =>
                    variationAttribute.name === templateVariationAttribute.name,
                )

                return (
                  <Grid item xs={12} key={templateVariationAttribute.name}>
                    <ProductAttributeInputField
                      name={templateVariationAttribute.name}
                      attributes={attributes}
                      requiredAttributes={requiredAttributes}
                      onChange={(name, value) =>
                        handleAttributeChange(name, value)
                      }
                      value={variationAttribute?.value}
                    />
                  </Grid>
                )
              })}
              <Grid item xs={12}>
                <Grid container justifyContent="space-between">
                  <Grid item>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => onDelete(variation.variation.name)}
                    >
                      Delete
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button variant="outlined" onClick={handleExpand}>
                      Collapse
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Collapse>
        </Box>
      </Grid>
      <Grid item xs={1}>
        <div>
          <IconButton
            aria-label="more"
            id="long-button"
            aria-controls={menuOpen ? 'long-menu' : undefined}
            aria-expanded={menuOpen ? 'true' : undefined}
            aria-haspopup="true"
            onClick={handleMenuClick}
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            id="long-menu"
            MenuListProps={{
              'aria-labelledby': 'long-button',
            }}
            anchorEl={anchorEl}
            open={menuOpen}
            onClose={handleMenuClose}
            PaperProps={{
              style: {
                maxHeight: MENU_ITEM_HEIGHT * 4.5,
                width: '20ch',
              },
            }}
          >
            {menuOptions.map((option) => (
              <MenuItem
                key={option}
                data-option={option}
                onClick={(e) => handleMenuOptionClick(e)}
              >
                {option}
              </MenuItem>
            ))}
          </Menu>
        </div>
      </Grid>
    </Grid>
  )
}
