import React, { useEffect, useState } from 'react'
import { GetProduct } from '../../../api/product'
import {
  GetIntegrationsResult,
  IntegrationDisplayName,
  IntegrationName,
  getIntegrations,
} from '../../../api/integration'
import { err } from '../../../utils/functions'
import Grid from '@mui/material/Grid'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'

interface BulkProductIntegrationsProps {
  product: GetProduct
}
export default function BulkProductIntegrations({
  product,
}: BulkProductIntegrationsProps): JSX.Element {
  // get all product integrations

  const [integrations, setIntegrations] = useState<
    GetIntegrationsResult | undefined
  >()

  useEffect(() => {
    getIntegrations({ productId: product.product.id })
      .then((res) => {
        if (res.success && res.data) {
          setIntegrations(res.data)
        }
      })
      .catch((e) => err(e))
  }, [product])

  // const rowNames = ['Cateogory', 'SKU', 'Condition', 'Price', 'Quantity']

  return (
    <Grid container justifyContent="center">
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {integrations &&
                Object.keys(integrations).map((integrationName) => (
                  <TableCell key={integrationName}>
                    {IntegrationDisplayName[integrationName as IntegrationName]}
                  </TableCell>
                ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {/* {integrations &&
              Object.values(integrations).map((integration) => {
                integration.productIntegration
              })} */}

            {/* {rowNames.map((rowName) => {

                const values = Object.values(integrations).map((integration) => {
                  integration.productIntegration.
                })

              })} */}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  )
}
