import React from 'react'
import Grid from '@mui/material/Grid'
import { ProductAttributeInputSelect } from '../../pages/products/product/ProductAttributeInputSelect'

interface ConditionsInputProps {
  conditionPrimary: string
  conditionSecondary?: string | undefined
  disabled?: boolean
  onChange: (primary: string, secondary?: string) => void
}
export default function ConditionsInput({
  conditionPrimary,
  conditionSecondary,
  disabled,
  onChange,
}: ConditionsInputProps): JSX.Element {
  const secondaryConditions = ['Refurbished', 'Used']
  const showSecondary = secondaryConditions.includes(conditionPrimary)

  // const CONDITIONS = {
  //   NEW: 'New',
  //   LIKE_NEW: 'Like New',
  //   NEW_OTHER: 'New Other',
  //   NEW_WITH_DEFECTS: 'New with Defects',
  //   MANUFACTURER_REFURBISHED: 'Manufacturer Refurbished',
  //   CERTIFIED_REFURBISHED: 'Certified Refurbished',
  //   EXCELLENT_REFURBISHED: 'Excellent Refurbished',
  //   VERY_GOOD_REFURBISHED: 'Very Good Refurbished',
  //   GOOD_REFURBISHED: 'Good Refurbished',
  //   SELLER_REFURBISHED: 'Seller Refurbished',
  //   USED_EXCELLENT: 'Used_excellent',
  //   USED_VERY_GOOD: 'Used Very Good',
  //   USED_GOOD: 'Used Good',
  //   USED_ACCEPTABLE: 'Used Acceptable',
  //   FOR_PARTS_OR_NOT_WORKING: 'For parts or not working',
  // }

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12}>
        <Grid container spacing={3}>
          <Grid item md={showSecondary ? 6 : 12} xs={12}>
            <ProductAttributeInputSelect
              disabled={disabled}
              label="Condition"
              value={conditionPrimary}
              options={[
                'New',
                'New Other (Open Box, With Tags, Unused)',
                'Used',
                'Refurbished',
                'Parts / Not Working',
              ]}
              onChange={(value) => onChange(value, conditionSecondary)}
            ></ProductAttributeInputSelect>
          </Grid>
          {showSecondary && (
            <Grid item md={showSecondary ? 6 : 12} xs={12}>
              <ProductAttributeInputSelect
                value={conditionSecondary || ''}
                options={['Excellent', 'Very Good', 'Good', 'Fair', 'Poor']}
                onChange={(value) => onChange(conditionPrimary, value)}
              ></ProductAttributeInputSelect>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid item xs={12}>
          {/* {Object.keys(CONDITIONS)} */}
        </Grid>
      </Grid>
    </Grid>
  )
}

/*
New
New Other (Open Box, With Tags, Unused)
Used -
  Excellent, Like New
  Good
  Fair
  Poor
Refurbished -
  Excellent
  Very Good
  Good
  Fair
  Poor
Parts / Not Working
 */
