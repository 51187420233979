import React from 'react'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Component from './Component'
import { deleteProductImage } from '../../api/product'
import { err, log } from '../../utils/functions'
import ImageDrag, { ImageDragItem } from './ImageDrag'

interface ImageUploadProps {
  id: string
  productId?: number
  variationId?: number
  images: string[]
  selectedImages: File[]
  onChange: (images: string[]) => void
  onSelect: (files: File[]) => void
}
export default function ImageUpload({
  id,
  productId,
  variationId,
  images,
  selectedImages,
  onChange,
  onSelect,
}: ImageUploadProps): JSX.Element {
  const handleSelect = (files: File[]) => {
    let newSelectedImages = [...selectedImages]
    newSelectedImages = newSelectedImages.concat(files)

    // do not add duplicates
    const names: string[] = []
    newSelectedImages = newSelectedImages.reduce(
      (accum: File[], curr: File): File[] => {
        if (!names.includes(curr.name)) {
          names.push(curr.name)
          accum.push(curr)
          return accum
        } else {
          return accum
        }
      },
      [],
    )

    onSelect(newSelectedImages)
  }

  const handleSelectedImageRemove = (index: number) => {
    const newSelectedImages = selectedImages.slice()
    newSelectedImages.splice(index, 1)
    onSelect(newSelectedImages)
  }

  const handleImageRemove = (index: number) => {
    const newImages = images.slice()
    newImages.splice(index, 1)
    onChange(newImages)
  }

  const handleImageDelete = (index: number) => {
    const imageUrl = images[index]
    if (!imageUrl) return
    deleteProductImage(productId, variationId, imageUrl)
      .then((res) => {
        log(res)
        if (res.success) {
          handleImageRemove(index)
          log('success')
        }
      })
      .catch((e) => err(e))
  }

  const handleImageReorder = (item: ImageDragItem, newIndex: number) => {
    const oldIndex = item.index
    const newImages = images.slice() // copy
    const url = newImages[oldIndex] // get value
    if (!url) return
    newImages.splice(oldIndex, 1) // remove old
    newImages.splice(newIndex, 0, url) // splice value into new location
    onChange(newImages)
  }

  const handleSelectedImageReorder = (
    item: ImageDragItem,
    newIndex: number,
  ) => {
    const oldIndex = item.index
    const newSelectedImages = selectedImages.slice() // copy
    const url = newSelectedImages[oldIndex] // get value
    if (!url) return
    newSelectedImages.splice(oldIndex, 1) // remove old
    newSelectedImages.splice(newIndex, 0, url) // splice value into new location
    onSelect(newSelectedImages)
  }

  return (
    <Grid container spacing={1} justifyContent="center">
      <Grid item xs={12} md={3} lg={2}>
        <Stack
          direction="row"
          justifyContent="center"
          sx={{
            position: 'relative',
            height: '100%',
            top: 10,
          }}
        >
          <Typography sx={{ mr: '0.5em', mb: '0.5em' }}>
            <strong>Images:</strong>
          </Typography>
        </Stack>
      </Grid>
      <Grid item xs={12} md={9} lg={10}>
        <Component>
          {variationId || productId ? (
            <Grid container spacing={2} justifyContent="center">
              <Grid item xs={12}>
                <Grid
                  container
                  spacing={2}
                  direction="row"
                  wrap="nowrap"
                  justifyContent="flex-start"
                  style={{
                    maxHeight: '100vh',
                    overflow: 'auto',
                    overflowX: 'auto',
                    padding: '1em',
                  }}
                >
                  {images.map((image: string, i) => (
                    <Grid item key={image}>
                      <ImageDrag
                        value={image}
                        index={i}
                        onChange={handleImageReorder}
                        onDelete={(index) => handleImageDelete(index)}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Grid>
              <Grid item xs={12}>
                {selectedImages.length ? (
                  <Typography>
                    <strong>Images Uploaded on Save</strong>
                  </Typography>
                ) : null}
                <Grid
                  container
                  spacing={2}
                  direction="row"
                  wrap="nowrap"
                  justifyContent="flex-start"
                  style={{
                    maxHeight: '100vh',
                    overflow: 'auto',
                    overflowX: 'auto',
                    padding: '1em',
                  }}
                >
                  {selectedImages.map((image: File, i) => (
                    <Grid item key={image.name}>
                      <ImageDrag
                        name={image.name}
                        selected={true}
                        value={image}
                        index={i}
                        onChange={handleSelectedImageReorder}
                        onDelete={(index) => handleSelectedImageRemove(index)}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <input
                  hidden
                  id={'image-upload' + id}
                  type="file"
                  name="myImage"
                  multiple
                  accept=".png,.jpeg,.jpg"
                  onChange={(event) => {
                    handleSelect(Array.from(event.target.files || []))
                  }}
                />
                <label htmlFor={'image-upload' + id}>
                  <Button variant="outlined" component="span" size="small">
                    Select Images
                  </Button>
                </label>
              </Grid>
            </Grid>
          ) : (
            <Typography>Click Save before uploading images.</Typography>
          )}
        </Component>
      </Grid>
    </Grid>
  )
}
