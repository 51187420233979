import React from 'react'
import { styled } from '@mui/material/styles'
import IconButton, { IconButtonProps } from '@mui/material/IconButton'

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean
  p?: number
}
const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, p, ...other } = props
  return <IconButton {...other} />
})(({ theme, expand, p }) => ({
  padding: p ?? 0,
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}))

export default ExpandMore
