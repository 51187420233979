import React from 'react'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'

interface ProductAttributeInputNumberProps {
  label?: string
  value?: number | undefined
  options?: string[]
  required?: boolean
  onChange?: (typeParam: number) => void
  disabled?: boolean
  hideLabel?: boolean
  helperText?: string
}

export function ProductAttributeInputNumber({
  label,
  value,
  required,
  onChange,
  disabled,
  hideLabel,
  helperText,
}: ProductAttributeInputNumberProps): JSX.Element {
  const lb = !hideLabel
  return (
    <Grid container spacing={1} justifyContent="center">
      {!hideLabel ? (
        <Grid item xs={12} md={3} lg={2}>
          <Stack
            direction="row"
            justifyContent="center"
            sx={{
              position: 'relative',
              height: '100%',
              top: 10,
            }}
          >
            <Typography
              sx={{
                mr: '0.5em',
                mb: '0.5em',
                color: disabled ? 'rgba(0, 0, 0, 0.38);' : 'inherit',
              }}
            >
              <strong>{label}</strong>
            </Typography>
          </Stack>
        </Grid>
      ) : null}

      <Grid item xs={12} md={lb ? 9 : 12} lg={lb ? 10 : 12}>
        <TextField
          type="number"
          label={disabled ? '' : (hideLabel && label) || ''}
          size="small"
          fullWidth
          value={value ?? ''}
          autoComplete="new-password"
          disabled={disabled}
          required={required}
          onChange={(e) => {
            if (e.target.value === '') return // no-op
            onChange?.(parseFloat(e.target.value ?? 0))
          }}
          helperText={helperText}
        />
      </Grid>
    </Grid>
  )
}
