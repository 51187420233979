import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import React, { useState } from 'react'

interface SingleSelectProps {
  label: string
  value: string
  options: string[]
  freeSolo?: boolean
  required?: boolean
  disabled?: boolean
  defaultOption?: boolean
  errorText?: string
  onChange?: (value: string | undefined) => void
}
export function SingleSelect({
  label,
  value,
  options,
  freeSolo,
  required,
  disabled,
  defaultOption,
  errorText,
  onChange,
}: SingleSelectProps): JSX.Element {
  const [localValue, setLocalValue] = useState<string>(value)

  const handleChange = (newValue: string) => {
    setLocalValue(newValue)
    onChange?.(newValue)
  }

  const allOptions = defaultOption !== false ? [''].concat(options) : options
  return (
    <Autocomplete
      value={localValue}
      options={allOptions}
      renderOption={(props, option) => {
        if (option === '') {
          return (
            <li {...props}>
              <i>Select</i>
            </li>
          )
        }
        return <li {...props}>{option}</li>
      }}
      freeSolo={freeSolo}
      disabled={disabled}
      autoHighlight={true}
      onChange={(e, value) => handleChange(value || '')}
      selectOnFocus
      isOptionEqualToValue={(option, value) => option === value}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          label={label}
          required={required}
          size="small"
          value={value}
          onChange={(e) => freeSolo === true && handleChange(e.target.value)}
          error={!!errorText}
          helperText={errorText}
        />
      )}
    ></Autocomplete>
  )
}
