import React from 'react'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'

interface ParseProductDetailsToggleProps {
  toggled: boolean
  placement?: 'top' | 'bottom' | 'end' | 'start' | undefined
  onChange: () => void
}
export default function ParseProductDetailsToggle({
  toggled,
  placement,
  onChange,
}: ParseProductDetailsToggleProps): JSX.Element {
  return (
    <Grid container justifyContent="center">
      <Grid item xs={12}>
        <Tooltip title="Parse attributes found in title and descriptions.">
          <FormControlLabel
            label={
              <Typography variant="subtitle2" color="textSecondary">
                Parse
              </Typography>
            }
            control={
              <Switch
                checked={toggled}
                size="small"
                color="primary"
                onChange={() => onChange()}
              />
            }
            labelPlacement={placement || 'top'}
          />
        </Tooltip>
      </Grid>
    </Grid>
  )
}
