import React, { useState } from 'react'
import { GetIntegrationResult, IntegrationName } from '../../api/integration'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Alert, { AlertInput, handleAlert } from '../../components/common/Alert'
import { bigcommerceBulkImport } from '../../api/integrations/bigcommerce'
import { err } from '../../utils/functions'

export default function BigCommerceIntegration({
  integration,
}: {
  integration: GetIntegrationResult<IntegrationName.BIG_COMMERCE>
}): JSX.Element {
  const [alert, setAlert] = useState<AlertInput>({ open: false })

  const handleAppStoreClick = () => {
    const storeHash = integration.integration?.shop?.replace('stores/', '')
    window.open(
      integration.oAuthUrl ||
        `https://store-${storeHash}.mybigcommerce.com/manage/marketplace/apps/my-apps`,
      '_blank',
    )
  }

  const handleBulkImport = () => {
    bigcommerceBulkImport()
      .then((res) => {
        handleAlert(setAlert, res, 'Bulk import process started.')
      })
      .catch((e) => err(e))
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        {/* Authentication */}
        <Grid container justifyContent="center" spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h6">Authentication</Typography>
          </Grid>
          {integration.authenticated ? (
            <Grid item xs={12}>
              <Grid container justifyContent="center">
                <Grid item xs={12}>
                  <Typography variant="h6">Store Details</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography>
                    {integration.integration?.shop || 'Store name not set.'}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          ) : null}
          {integration.authenticated ? (
            <>
              <Grid item xs={12}>
                <Typography>
                  To authenticate app access, please add the TeamAutomate
                  BigCommerce app to your store.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Grid container justifyContent="center" spacing={3}>
                  <Grid item>
                    <Button variant="contained" onClick={handleAppStoreClick}>
                      Manage
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button variant="contained" onClick={handleAppStoreClick}>
                      Re-Authorize
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={12}>
                <Typography>Revoke / Re-Authenticate</Typography>
              </Grid>
              <Grid item xs={12}>
                <Button variant="contained" onClick={handleAppStoreClick}>
                  Open Marketplace
                </Button>
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Button variant="contained" onClick={handleBulkImport}>
          Bulk Import Inventory
        </Button>
      </Grid>
      <Alert
        alert={alert}
        onClose={() => setAlert({ ...alert, open: false })}
      ></Alert>
    </Grid>
  )
}
