import React from 'react'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { useHistory } from 'react-router-dom'
import { useEffect, useRef, useState } from 'react'
import { GetProductTemplate, getProductTemplates } from '../../api/product'
import Component from '../../components/common/Component'
import Table from '../../components/common/Table'
import NavBar from '../../components/common/NavBar'
import Fab from '@mui/material/Fab'
import AddIcon from '@mui/icons-material/Add'
import Stack from '@mui/material/Stack'
import { err } from '../../utils/functions'

export default function ProductTemplates(): JSX.Element {
  const history = useHistory()
  const isMounted = useRef(true)

  const [templates, setProductTemplates] = useState<GetProductTemplate[]>([])

  /* Get Product Templates */
  useEffect(() => {
    if (isMounted.current) {
      getProductTemplates()
        .then((res) => setProductTemplates(res.data || []))
        .catch((e) => err(e))
      return () => {
        isMounted.current = false
      }
    }
  })

  const handleAddType = () => {
    history.push('/createTemplate')
  }

  const handleRowClick = (templateId: number) => {
    history.push(`/template/${templateId}`)
  }

  return (
    <div
      style={{
        width: '100%',
      }}
    >
      <NavBar />
      <Container>
        <Grid container sx={{ mt: 1 }} spacing={3}>
          <Grid item xs={12}>
            <Component>
              <Grid container spacing={3}>
                <Grid item xs={12} sx={{ position: 'relative' }}>
                  <Typography variant="h6">
                    Templates
                    <Fab
                      color="primary"
                      size="small"
                      sx={{ position: 'absolute', right: 0 }}
                      onClick={handleAddType}
                    >
                      <AddIcon />
                    </Fab>
                  </Typography>
                </Grid>
                <Stack></Stack>
                <Grid item xs={12}>
                  <Table
                    data={templates}
                    head={['Name', 'Description', 'Products']}
                    row={(item) => [
                      item.template.name,
                      item.template.typeDescription,
                      item.template.products,
                    ]}
                    pageSize={25}
                    onRowClick={(row) => handleRowClick(row.template.id)}
                  ></Table>
                </Grid>
              </Grid>
            </Component>
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}
