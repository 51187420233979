import React from 'react'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import { log } from '../../../../utils/functions'
import Grid from '@mui/material/Grid'
import { Integration } from '../../../../api/types'

interface EbayAuthorizationProps {
  integration: Integration | undefined
  authorized: boolean | undefined
  oAuthUrl?: string
}
export default function EbayAuthorization({
  integration,
  authorized,
  oAuthUrl,
}: EbayAuthorizationProps): JSX.Element | null {
  const handleClick = () => {
    if (oAuthUrl) {
      window.open(oAuthUrl, '_blank')
    } else {
      log('Invalid OAuth Url')
    }
  }

  return authorized !== false ? (
    <Grid container justifyContent="center" direction="column" spacing={1}>
      <Grid item>
        <Button sx={{ mt: 2 }} variant="contained" onClick={handleClick}>
          Re-Authorize
        </Button>
      </Grid>
      <Grid item>
        <Typography variant="body2">
          Authentication Last Updated:{' '}
          {integration?.updatedAt
            ? new Date(integration?.updatedAt).toLocaleString()
            : 'Unknown'}
        </Typography>
      </Grid>
    </Grid>
  ) : (
    <>
      <Typography variant="h6">Unauthorized</Typography>
      <Typography>
        In order to access your Ebay APIs, you must authorize app access. <br />
        Click below will navigate to Ebay to sign in and grant our app access.
      </Typography>

      <Button sx={{ mt: 2 }} variant="contained" onClick={handleClick}>
        Authorize
      </Button>
    </>
  )
}
