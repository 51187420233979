import React from 'react'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import Grid from '@mui/material/Grid'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import Typography from '@mui/material/Typography'
import { useEffect } from 'react'
import { useState } from 'react'
import { GetLog, sendInvoice, updateLogs } from '../../api/service'
import { DAY_MS } from '../../utils/constants'
import { err, formatDate } from '../../utils/functions'
import Alert, { AlertInput } from '../common/Alert'
import Component from '../common/Component'
import Modal from '../common/Modal'
import Table from '../common/Table'

interface ServiceLogTableProps {
  logs: GetLog[] | undefined
}

export default function ServiceLogTable({
  logs = [],
}: ServiceLogTableProps): JSX.Element {
  const [alert, setAlert] = useState<AlertInput>({ open: false })

  const [filterLogs, setFilterLogs] = useState<GetLog[]>([])
  const [selectedLogs, setSelectedLogs] = useState<GetLog[]>([])
  const [dateRange, setDateRange] = useState(14)
  const [unpaid, setUnpaid] = useState(true)
  const [paidModalOpen, setPaidModalOpen] = useState(false)
  const [sendInvoiceModalOpen, setSendInvoiceModalOpen] = useState(false)

  const isSelected = (sl: GetLog) => selectedLogs.indexOf(sl) !== -1
  const numLogs = filterLogs.length || 0
  const numSelectedLogs = selectedLogs.length

  const handlePaidSubmit = async (logs: GetLog[]) => {
    const logIds = logs.map((log) => log.id)
    await updateLogs(logIds)
    setPaidModalOpen(false)
  }

  const openInNewTab = (href: string) => {
    Object.assign(document.createElement('a'), {
      target: '_blank',
      href: href,
    }).click()
  }

  const handleSendInvoiceSubmit = (logs: GetLog[]) => {
    sendInvoice(logs)
      .then((res) => {
        if (res.success && res.data) {
          openInNewTab(res.data)
          setSendInvoiceModalOpen(false)
        } else {
          setAlert({ open: true, severity: 'error', message: res.message })
        }
      })
      .catch((e) => err(e))
  }

  const handleSelectLog = (sl: GetLog) => {
    const selectedIndex = selectedLogs.indexOf(sl)
    let newSelected: GetLog[] = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedLogs, sl)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedLogs.slice(1))
    } else if (selectedIndex === selectedLogs.length - 1) {
      newSelected = newSelected.concat(selectedLogs.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedLogs.slice(0, selectedIndex),
        selectedLogs.slice(selectedIndex + 1),
      )
    }
    setSelectedLogs(newSelected)
  }
  const handleSelectAllLogs = () => {
    if (numLogs === numSelectedLogs) {
      setSelectedLogs([])
    } else {
      setSelectedLogs(filterLogs)
    }
  }

  const head = [
    <Checkbox
      indeterminate={numSelectedLogs > 0 && numSelectedLogs < numLogs}
      checked={numLogs > 0 && numSelectedLogs === numLogs}
      onChange={handleSelectAllLogs}
      inputProps={{ 'aria-label': 'select all desserts' }}
      key={1}
    />,
    'Service',
    'SKU',
    'Completed At',
    'Paid',
  ]
  const row = (sl: GetLog) => [
    <Checkbox
      size="small"
      sx={{ height: 0 }}
      checked={isSelected(sl)}
      key={sl.id}
    />,
    sl?.displayName,
    sl?.sku,
    formatDate(sl.createdAt),
    sl.paid ? 'Yes' : 'No',
  ]

  const dateOptions = [
    { name: '1 Day', value: 1 },
    { name: '1 Week', value: 7 },
    { name: '2 Week', value: 14 },
    { name: '1 Month', value: 30 },
    { name: 'All', value: 365 * 5 },
  ]

  const inDate = (sl: GetLog) =>
    new Date(sl.createdAt).getTime() > new Date().getTime() - dateRange * DAY_MS

  const isPaid = (sl: GetLog) => sl.paid === !unpaid
  const logFilters = (sl: GetLog) => inDate(sl) && isPaid(sl)

  useEffect(() => {
    setSelectedLogs([])
    const newFiltered = logs.filter(logFilters)
    setFilterLogs(newFiltered)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(logs), unpaid, dateRange])

  const totalFee = selectedLogs
    .map((log) => log?.fee || 0)
    .reduce((a, b) => a + b, 0)

  const displayTotalFee = `$${Math.round(totalFee * 100) / 100}`
  return (
    <Component title="Logs">
      <Grid container spacing={1} justifyContent="space-evenly">
        <Grid item xs={4}>
          <FormControl>
            <InputLabel id="date-select-label">Date Range</InputLabel>
            <Select
              labelId="date-select-label"
              label="Date Range"
              sx={{ width: 150 }}
              value={dateRange}
              onChange={(e) => setDateRange(e.target.value as number)}
              size="small"
            >
              {dateOptions.map((da, i) => (
                <MenuItem value={da.value} key={`select-daterange-${i}`}>
                  {da.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControlLabel
            sx={{ position: 'relative', top: '15%' }}
            control={
              <Checkbox
                sx={{ height: 0 }}
                checked={unpaid}
                onChange={() => setUnpaid(!unpaid)}
                name="checkedA"
              />
            }
            label="Unpaid"
          />
        </Grid>
        <Grid item xs={12}></Grid>

        <Grid item xs={12}>
          <Table
            data={filterLogs}
            head={head}
            row={row}
            onRowClick={(row) => handleSelectLog(row)}
          />
        </Grid>

        <Grid item xs={12}>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <Grid item xs={12} md={6}>
              <Typography>
                Total:
                <strong> {displayTotalFee}</strong>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                spacing={2}
                direction="row"
              >
                <Grid item>
                  <Button
                    color="primary"
                    variant="contained"
                    disabled={numSelectedLogs === 0}
                    onClick={() => setSendInvoiceModalOpen(true)}
                  >
                    Send Invoice
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    color="primary"
                    variant="contained"
                    disabled={numSelectedLogs === 0}
                    onClick={() => {
                      const start = new Date(
                        new Date().getTime() - dateRange * DAY_MS,
                      ).toLocaleDateString()
                      const end = new Date().toLocaleDateString()
                      window.open(
                        `/api/service/getLogs?format=csv&start=${start}&end=${end}&unpaid=${unpaid}`,
                        '_blank',
                      )
                    }}
                  >
                    Open CSV
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    color="primary"
                    variant="contained"
                    disabled={numSelectedLogs === 0}
                    onClick={() => setPaidModalOpen(true)}
                  >
                    Mark as Paid
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Alert
        alert={alert}
        onClose={() => setAlert({ ...alert, open: false })}
      />
      <Modal
        open={paidModalOpen}
        title="Update Logs"
        onClose={() => setPaidModalOpen(false)}
        onSubmit={() => handlePaidSubmit(selectedLogs)}
      >
        <Typography>
          This will set {numSelectedLogs} item{numSelectedLogs > 1 ? 's' : ''}{' '}
          as paid. Continue?
        </Typography>
      </Modal>

      <Modal
        open={sendInvoiceModalOpen}
        title="Send Invoice"
        onClose={() => setSendInvoiceModalOpen(false)}
        onSubmit={() => handleSendInvoiceSubmit(selectedLogs)}
      >
        <Typography>
          This will send a PayPal invoice for {numSelectedLogs} selected
          item(s). All items will be marked as paid after the invoice is sent.
          Continue?
        </Typography>
        <Typography>
          Invoice total (pre-tax): <strong>{displayTotalFee}</strong>
        </Typography>
      </Modal>
    </Component>
  )
}
