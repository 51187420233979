import React, { useState, useEffect } from 'react'
import {
  GetIntegrationsResult,
  IntegrationDisplayName,
  IntegrationName,
  getIntegrations,
  unlistProduct,
} from '../../api/integration'
import Grid from '@mui/material/Grid'
import { Button, Typography } from '@mui/material'
import Table from '../common/Table'
import { getProductListings } from '../../api/product'
import { ProductListing } from '../../api/types'
import { Link } from 'react-router-dom'

export function ProductListingsTable({
  productId,
}: {
  productId?: number
}): JSX.Element {
  const [integrations, setIntegrations] = useState<
    GetIntegrationsResult | undefined
  >()
  const [productListings, setProductListings] = useState<ProductListing[]>([])

  useEffect(() => {
    getIntegrations()
      .then((res) => {
        if (res.success && res.data) {
          setIntegrations(res.data)
        }
      })
      .catch((e) => {
        console.log(e)
      })
  }, [])

  useEffect(() => {
    if (!productId) return
    getProductListings({ productId })
      .then((res) => {
        if (res.success && res.data) {
          setProductListings(res.data)
        }
      })
      .catch((e) => {
        console.log(e)
      })
  }, [productId])

  const handleUnlistProduct = async (
    channel: string,
    productId: number,
    integrationId: number,
  ) => {
    await unlistProduct(channel, productId, integrationId)
      .then((res) => {
        // handle success
      })
      .catch((e) => {
        console.log(e)
        // handle failure
      })
  }

  const getChannelLink = (channel: string, externalId: string): string => {
    switch (channel) {
      case IntegrationName.EBAY:
        return `https://www.ebay.com/itm/${externalId}`
      case IntegrationName.SQUARE:
        return `https://squareup.com/dashboard/items/library/${externalId}`
      case IntegrationName.SHOPIFY: {
        const shopName = integrations?.shopify?.integration?.shop?.replaceAll(
          '.myshopify.com',
          '',
        )
        if (!shopName) return ''
        return `https://admin.shopify.com/store/${shopName}/products/${externalId}`
      }
      case IntegrationName.BIG_COMMERCE: {
        const store = integrations?.bigcommerce?.integration?.shop?.replace(
          'stores/',
          '',
        )
        if (!store) return ''
        return `https://store-${store}.mybigcommerce.com/manage/products/edit/${externalId}`
      }
      case IntegrationName.CLOVER: {
        const shop = integrations?.clover?.integration?.shop
        if (!shop) return ''
        // TODO: Do not use sandbox
        return `https://sandbox.dev.clover.com/inventory/m/${shop}/items/${externalId}`
      }
      default:
        return ''
    }
  }

  return (
    <Grid container justifyContent="center" spacing={3}>
      <Grid item xs={12}>
        <Grid container justifyContent="center">
          <Grid item xs={12}>
            <Typography>Integrations</Typography>
          </Grid>
          <Grid item xs={12}>
            <Table
              head={['Channel', 'Status', 'Listing', 'Product', 'Action']}
              data={productListings}
              row={(row, i) => {
                return [
                  IntegrationDisplayName[row.channel as IntegrationName],
                  row.status,
                  <a
                    key={row.externalId}
                    href={getChannelLink(row.channel, row.externalId)}
                    target="__blank"
                  >
                    {row.externalId}
                  </a>,
                  <Link key={row.productId} to={`/product/${row.productId}`}>
                    {row.productId}
                  </Link>,
                  <Button
                    size="small"
                    key="unlist"
                    onClick={() =>
                      handleUnlistProduct(
                        row.channel,
                        row.productId,
                        row.integrationId,
                      )
                    }
                  >
                    Unlist
                  </Button>,
                ]
              }}
            ></Table>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
