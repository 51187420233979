import React, { useState } from 'react'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import { SetProductTemplateAttribute } from '../../api/product'
import Chip from '@mui/material/Chip'
import Autocomplete from '@mui/material/Autocomplete'
import { Typography } from '@mui/material'
import { newOption } from './ProductTemplate'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { isDefined } from '../../utils/functions'
import { AttributeConstraintEnum } from '../../api/types'

const MENU_ITEM_HEIGHT = 48

enum MenuItemOptionEnum {
  ADD_TO_TITLE = 'Add To Title',
  ADD_TO_DESCRIPTION = 'Add to Description',
  MERGE_ATTRIBUTES = 'Merge Attributes',
  SET_OPTIONAL = 'Set Optional',
  SET_RECOMMENDED = 'Set Recommended',
  SET_REQUIRED = 'Set Required',
  DELETE = 'Delete',
}
export interface ProductTemplateAttributeInputFieldProps {
  attribute: SetProductTemplateAttribute
  onChange: (attribute: SetProductTemplateAttribute | undefined) => void
  allowDelete?: boolean
  nameEditable?: boolean
  placeholder?: string
  disabled?: boolean
  required?: boolean
  addToTitle?: (attribute: SetProductTemplateAttribute) => void
  addToDescription?: (attribute: SetProductTemplateAttribute) => void
  mergeAttributes?: (attribute: SetProductTemplateAttribute) => void
}

export function ProductTemplateAttributeInputField({
  attribute: templateAttribute,
  onChange,
  allowDelete,
  placeholder,
  nameEditable = true,
  disabled,
  required,
  addToTitle,
  addToDescription,
  mergeAttributes,
}: ProductTemplateAttributeInputFieldProps): JSX.Element {
  const { attribute, attributeOptions } = templateAttribute
  const [errorText, setErrorText] = useState<string>('')
  const [menuOpen, setMenuOpen] = useState<boolean>(false)
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [attributeName, setAttributeName] = useState<string>(
    attribute.name || '',
  )

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
    setMenuOpen(true)
  }
  const handleMenuClose = () => {
    setMenuOpen(false)
    setAnchorEl(null)
  }

  const menuOptions: MenuItemOptionEnum[] = [
    MenuItemOptionEnum.ADD_TO_TITLE,
    MenuItemOptionEnum.ADD_TO_DESCRIPTION,
    attribute.id ? MenuItemOptionEnum.MERGE_ATTRIBUTES : undefined,
    MenuItemOptionEnum.SET_OPTIONAL,
    MenuItemOptionEnum.SET_RECOMMENDED,
    MenuItemOptionEnum.SET_REQUIRED,
    MenuItemOptionEnum.DELETE,
  ].filter(isDefined)

  const handleMenuOptionClick = (
    e: React.MouseEvent<HTMLLIElement, MouseEvent>,
  ) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const action = (e.target as any).innerText

    if (action === MenuItemOptionEnum.ADD_TO_TITLE) {
      // Add To Title
      addToTitle?.(templateAttribute)
    } else if (action === MenuItemOptionEnum.ADD_TO_DESCRIPTION) {
      // Add To Description
      addToDescription?.(templateAttribute)
    } else if (action === MenuItemOptionEnum.MERGE_ATTRIBUTES) {
      // Merge Attributes
      mergeAttributes?.(templateAttribute)
    } else if (action === MenuItemOptionEnum.SET_OPTIONAL) {
      templateAttribute.attribute.constraint = AttributeConstraintEnum.OPTIONAL
      onChange(templateAttribute)
    } else if (action === MenuItemOptionEnum.SET_RECOMMENDED) {
      templateAttribute.attribute.constraint =
        AttributeConstraintEnum.RECOMMENDED
      onChange(templateAttribute)
    } else if (action === MenuItemOptionEnum.SET_REQUIRED) {
      templateAttribute.attribute.constraint = AttributeConstraintEnum.REQUIRED
      onChange(templateAttribute)
    } else if (action === MenuItemOptionEnum.DELETE) {
      // Delete
      onChange?.(undefined)
    }

    handleMenuClose()
  }

  const opts = disabled
    ? []
    : attributeOptions.map((o) => o.value).sort((a, b) => a.localeCompare(b)) // sort by value alphabetically
  const sz = +!allowDelete // add to size to make up for empty space

  const constraintName =
    (attribute.constraint === 'required' ? '**' : '') +
    (attribute.constraint === 'recommended' ? '*' : '')
  return (
    <Grid container spacing={1} justifyContent="center">
      <Grid item xs={5} sm={4} md={3} lg={2}>
        {nameEditable ? (
          <TextField
            label={attributeName ? attributeName + constraintName : 'Name'}
            onBlur={(e) => {
              setErrorText(attributeName ? '' : 'Name is required.')
              const name = e.target.value
              setErrorText(name ? '' : 'Name is required.')
              onChange?.({
                attribute: { ...attribute, name },
                attributeOptions,
              })
            }}
            required={required}
            error={!!errorText}
            helperText={errorText}
            size="small"
            fullWidth
            value={attributeName}
            autoComplete="new-password"
            placeholder={placeholder}
            onChange={(e) => {
              const name = e.target.value
              setErrorText(name ? '' : 'Name is required.')
              setAttributeName(name)
            }}
          />
        ) : (
          <Typography sx={{ mt: 1 }}>
            <strong>
              {attribute.name}
              {attribute.constraint === 'required' ? '**' : ''}
              {attribute.constraint === 'recommended' ? '*' : ''}
            </strong>
          </Typography>
        )}
      </Grid>
      <Grid item xs={6 + sz} sm={7 + sz} md={8 + sz} lg={9 + sz}>
        <Autocomplete
          value={opts}
          options={opts}
          freeSolo
          multiple
          limitTags={5}
          disabled={disabled}
          onChange={(e, values) => {
            // convert new entries (strings) into options
            const newValues = values.map((v, index) =>
              newOption({ value: v, index }),
            )
            onChange?.({ attribute, attributeOptions: newValues })
          }}
          renderTags={(values, getTagProps) =>
            values.map((option, index) => (
              <Chip
                {...getTagProps({ index })}
                key={index}
                variant="outlined"
                label={option}
              />
            ))
          }
          renderInput={(attribute) => (
            <TextField
              {...attribute}
              fullWidth
              label={disabled ? 'Product Specific' : 'Options'}
              placeholder={placeholder}
              size="small"
              value={attributeOptions}
              required={required}
            />
          )}
        ></Autocomplete>
      </Grid>
      {allowDelete ? (
        <Grid item xs={1}>
          <div>
            <IconButton
              aria-label="more"
              id="long-button"
              aria-controls={menuOpen ? 'long-menu' : undefined}
              aria-expanded={menuOpen ? 'true' : undefined}
              aria-haspopup="true"
              onClick={handleMenuClick}
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              id="long-menu"
              MenuListProps={{
                'aria-labelledby': 'long-button',
              }}
              anchorEl={anchorEl}
              open={menuOpen}
              onClose={handleMenuClose}
              slotProps={{
                paper: {
                  style: {
                    maxHeight: MENU_ITEM_HEIGHT * 6,
                    width: '20ch',
                  },
                },
              }}
            >
              {menuOptions.map((option) => (
                <MenuItem
                  key={option}
                  data-option={option}
                  onClick={(e) => handleMenuOptionClick(e)}
                >
                  {option}
                </MenuItem>
              ))}
            </Menu>
          </div>
        </Grid>
      ) : null}
    </Grid>
  )
}
