import React from 'react'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'

interface RichTextToggleProps {
  toggled: boolean
  onChange: () => void
}
export default function RichTextToggle({
  toggled,
  onChange,
}: RichTextToggleProps): JSX.Element {
  return (
    <Grid container justifyContent="center">
      <Grid item xs={12}>
        <Tooltip title="Use Rich Text editor. Type '@' to insert attributes.">
          <FormControlLabel
            label={
              <Typography variant="subtitle2" color="textSecondary">
                Rich Text
              </Typography>
            }
            value="top"
            control={
              <Switch
                checked={toggled}
                size="small"
                color="primary"
                onChange={() => onChange()}
              />
            }
            labelPlacement="top"
          />
        </Tooltip>
      </Grid>
    </Grid>
  )
}
