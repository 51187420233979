import React, { useContext, useState, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { err, isAdmin, wait } from '../utils/functions'
import {
  getService,
  getClientService,
  runService,
  updateTags,
  GetLog,
  getLogs,
  deleteClientService,
} from '../api/service'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import Container from '@mui/material/Container'

import NavBar from '../components/common/NavBar'
import Component from '../components/common/Component'
import Alert, { AlertInput } from '../components/common/Alert'
import AppContext from '../app/AppContext'
import ServiceLogTable from '../components/service/ServiceLogTable'
import { ClientService, Service } from '../api/types'
import Modal from '../components/common/Modal'
import Stack from '@mui/material/Stack'

export default function ServicePage(): JSX.Element {
  const { serviceId: serviceIdParam } = useParams<{
    serviceId?: string | undefined
  }>()
  const clientServiceId = parseInt(serviceIdParam || '0')
  const history = useHistory()
  const { appState } = useContext(AppContext)

  const [service, setService] = useState<Service>()
  const [clientService, setClientService] = useState<ClientService>()
  const [output, setOutput] = useState('')
  const [logs, setLogs] = useState<GetLog[]>()
  const [alert, setAlert] = useState<AlertInput>({ open: false })
  const [deleteServiceModalOpen, setDeleteServiceModalOpen] = useState(false)

  const [fetchTags, setFetchTags] = useState('')
  const [addTags, setAddTags] = useState('')
  const [removeTags, setRemoveTags] = useState('')

  const roleId = appState.user.roleId
  const clientId = appState.client?.id || 0

  // Get Service
  useEffect(() => {
    if (clientService?.serviceId) {
      getService(clientId, clientService.serviceId)
        .then((res) => setService(res.data))
        .catch((e) => err(e))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientService])

  // Get Client Service
  useEffect(() => {
    getClientService(clientId, clientServiceId)
      .then((result) => {
        const clientServ = result.data
        if (clientServ) {
          setClientService(clientServ)
          setFetchTags(clientServ.fetchTags)
          setAddTags(clientServ.addTags)
          setRemoveTags(clientServ.removeTags)
        } else {
          setAlert({ open: true, severity: 'error', message: result.message })
        }
      })
      .catch((e) => err(e))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Get Service Logs
  useEffect(() => {
    getLogs(clientId, { serviceId: clientServiceId })
      .then((res) => {
        setLogs(res?.data)
      })
      .catch((e) => err(e))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleTagsChange = (method: string, tags: string | undefined) => {
    updateTags(clientId, clientServiceId, method, tags || '')
      .then(async (result) => {
        await wait() // allow time for off click event to pass to prevent closing of alert
        if (result.success) {
          setAlert({ open: true, severity: 'success', message: result.data })
        } else {
          setAlert({ open: true, severity: 'error', message: result.data })
        }
      })
      .catch((e) => err(e))
  }

  const handleRunServiceClick = async (): Promise<void> => {
    const onChunk = (data: string) => setOutput(data)
    const tags = { fetch: fetchTags, add: addTags, remove: removeTags }
    const result = await runService(clientId, clientServiceId, tags, onChunk)
    setOutput(result)
  }

  const handleDeleteService = async (): Promise<void> => {
    const result = await deleteClientService(clientId, clientServiceId)
    if (result.success) {
      setAlert({
        open: true,
        severity: 'success',
        message: `Deleted Service. Redirecting to Dashboard.`,
      })

      await wait(1500)
      history.push('/Dashboard')
    } else {
      setAlert({ open: true, severity: 'error', message: result.data })
    }
  }

  return (
    <>
      <div
        style={{
          width: '100%',
        }}
      >
        <NavBar />
        <Container>
          <div
            style={{
              padding: '1em',
              paddingTop: '2em',
            }}
          >
            <Grid container spacing={3} justifyContent="center">
              <Grid item xs={12}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={4}>
                    <Grid container direction="column" spacing={2}>
                      <Grid item xs={12}>
                        <Component>
                          <Grid
                            container
                            justifyContent="center"
                            direction="column"
                            spacing={2}
                          >
                            <Grid item>
                              <Stack spacing={1}>
                                <Typography variant="h6">
                                  {service?.displayName ||
                                    service?.name ||
                                    'No Name'}
                                </Typography>
                                <Typography variant="subtitle2">
                                  Cost per Item: ${clientService?.fee}
                                </Typography>
                                <Button
                                  variant="contained"
                                  onClick={handleRunServiceClick}
                                >
                                  Run Service
                                </Button>
                              </Stack>
                            </Grid>
                          </Grid>
                        </Component>
                      </Grid>
                      <Grid item xs={12}>
                        <Component>
                          <Typography variant="h6">Description</Typography>
                          <div
                            style={{
                              textAlign: 'left',
                              lineHeight: 1.4,
                              textIndent: '25px',
                            }}
                          >
                            {service?.description}
                          </div>
                        </Component>
                      </Grid>

                      <Grid item xs={12}>
                        <Component title="Sellbrite Tags">
                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <Typography>
                                <strong>Fetch Tags</strong> items will be
                                processed
                              </Typography>
                              <Typography>
                                <strong>Add Tags</strong> can be
                                {` {attributes}`}
                              </Typography>
                              <Typography>
                                <strong>Remove Tags</strong> will be removed
                              </Typography>
                              <Typography>
                                <strong>Comma Separated</strong>
                              </Typography>
                            </Grid>

                            <Grid item xs={12}>
                              <TextField
                                label="Fetch Tags"
                                variant="outlined"
                                fullWidth
                                size="small"
                                value={fetchTags}
                                onChange={(e) => setFetchTags(e.target.value)}
                                onBlur={() =>
                                  handleTagsChange('fetch', fetchTags)
                                }
                              ></TextField>
                            </Grid>

                            <Grid item xs={12}>
                              <TextField
                                label="Add Tags"
                                variant="outlined"
                                fullWidth
                                size="small"
                                value={addTags}
                                onChange={(e) => setAddTags(e.target.value)}
                                onBlur={() => handleTagsChange('add', addTags)}
                              ></TextField>
                            </Grid>

                            <Grid item xs={12}>
                              <TextField
                                label="Remove Tags"
                                variant="outlined"
                                fullWidth
                                size="small"
                                value={removeTags}
                                onChange={(e) => setRemoveTags(e.target.value)}
                                onBlur={() =>
                                  handleTagsChange('remove', removeTags)
                                }
                              ></TextField>
                            </Grid>
                          </Grid>
                        </Component>
                      </Grid>

                      <Grid item xs={12}>
                        {isAdmin(roleId) ? (
                          <Component title="Manage">
                            <Grid container direction="column" spacing={2}>
                              <Grid item>
                                <Grid
                                  container
                                  justifyContent="flex-start"
                                  spacing={2}
                                >
                                  <Grid item>
                                    <Button variant="outlined" color="primary">
                                      Edit Details
                                    </Button>
                                  </Grid>
                                  <Grid item>
                                    <Button
                                      variant="outlined"
                                      color="secondary"
                                      onClick={() =>
                                        setDeleteServiceModalOpen(true)
                                      }
                                    >
                                      Delete
                                    </Button>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Component>
                        ) : null}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Component title="Output">
                          <Grid container justifyContent="center" spacing={2}>
                            <Grid item xs={12}>
                              Service&apos;s logs and results will be displayed
                              below.
                            </Grid>
                            <Grid item xs={12}>
                              <TextField
                                sx={{
                                  backgroundColor: '#fafafa',
                                }}
                                inputProps={{
                                  style: {
                                    fontFamily: 'Courier New',
                                    color: '#000',
                                  },
                                }}
                                multiline
                                rows={4}
                                value={output}
                                variant="outlined"
                                contentEditable={false}
                                fullWidth={true}
                                disabled
                              />
                            </Grid>
                          </Grid>
                        </Component>
                      </Grid>
                      <Grid item xs={12}>
                        <ServiceLogTable logs={logs} />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
          {/* Padd the bottom of the page */}
          <div style={{ height: '500px' }}></div>
        </Container>
        <Alert
          alert={alert}
          onClose={() => setAlert({ ...alert, open: false })}
        />
        <Modal
          open={deleteServiceModalOpen}
          title="Delete Service"
          onClose={() => setDeleteServiceModalOpen(false)}
          onSubmit={() => handleDeleteService()}
        >
          <Typography>
            This will permanently delete this service. You will be able to
            create a new instance of this service again in the
            &apos;Client&apos; page. Click `Submit` to delete.
          </Typography>
        </Modal>
      </div>
    </>
  )
}
