import instance from '.'
import { getResult } from '../utils/functions'
import { APIResult } from '../utils/types'
import {
  ClientService,
  GetClientService,
  DataFields,
  Product,
  Service,
} from './types'

/* @API:service.getClientServices */
export async function getClientServices(
  clientId: number,
): Promise<APIResult<GetClientService[]>> {
  const services = await instance
    .post('/api/service/getClientServices', { clientId })
    .then((res) => getResult<GetClientService[]>(res))
  return services
}

/* @API:service.getClientService */
export async function getClientService(
  clientId: number,
  clientServiceId: number,
): Promise<APIResult<GetClientService>> {
  const clientService = await instance
    .post('/api/service/getClientService', { clientId, clientServiceId })
    .then((res) => getResult<GetClientService>(res))
  return clientService
}

/* @API:service.deleteClientService */
export async function deleteClientService(
  clientId: number,
  clientServiceId: number,
): Promise<APIResult<string>> {
  return await instance
    .post('/api/service/deleteClientService', { clientId, clientServiceId })
    .then((res) => res.data)
}

/* @API:service.addClientService */
export async function addClientService(
  clientId: number,
  serviceName: string,
  fee: number,
  billing: string,
): Promise<APIResult<Service[]>> {
  const services = await instance
    .post('/api/service/addClientService', {
      clientId,
      serviceName,
      fee,
      billing,
    })
    .then((res) => getResult<Service[]>(res))
  return services
}

/* @API:service.getService */
export async function getService(
  clientId: number,
  serviceId: number,
): Promise<APIResult<Service>> {
  const service = await instance
    .post('/api/service/getService', { clientId, serviceId })
    .then((res) => getResult<Service>(res))
  return service
}

/* @API:service.getAvailableServices */
export async function getAvailableServices(): Promise<APIResult<string[]>> {
  const service = await instance
    .post('/api/service/getAvailableServices')
    .then((res) => getResult<string[]>(res))
  return service
}

/* @API:service.getServices */
export async function getServices(): Promise<APIResult<Service[]>> {
  const service = await instance
    .post('/api/service/getServices')
    .then((res) => getResult<Service[]>(res))
  return service
}

export type ServiceLog = {
  id: number
  itemId?: number
  serviceId: number
  clientId: number
  userId: number
  completed?: boolean
  paid?: boolean
  active?: boolean
  createdAt: string
}

export type Log = {
  id: number
  sku?: number
  serviceId: number
  clientId: number
  userId: number
  completed?: boolean
  paid?: boolean
  active?: boolean
  createdAt: string
}

/* @API:service.getServiceLogs */
export type GetServiceLog = ServiceLog &
  DataFields<Product> &
  DataFields<ClientService> &
  DataFields<Service> & {
    productId: number
    serviceId: number
    clientServiceId: number
  }

export async function getServiceLogs(
  clientId: number,
  where: Record<string, unknown> = {},
): Promise<APIResult<GetServiceLog[]>> {
  const serviceLogs = await instance
    .post('/api/service/getServiceLogs', { clientId, where })
    .then((res) => getResult<GetServiceLog[]>(res))
  return serviceLogs
}

/* @API:service.getLogs */
export type GetLog = Log &
  DataFields<ClientService> &
  DataFields<Service> & {
    serviceId: number
    clientServiceId: number
  }
export async function getLogs(
  clientId: number,
  where: Record<string, unknown> = {},
): Promise<APIResult<GetLog[]>> {
  const serviceLogs = await instance
    .post('/api/service/getLogs', { clientId, where })
    .then((res) => getResult<GetLog[]>(res))
  return serviceLogs
}

/* @API:service.runService */
export async function runService(
  clientId: number,
  clientServiceId: number,
  tags: { fetch: string; add: string; remove: string },
  onChunk?: (data: string) => void,
): Promise<string> {
  const service: string = await instance
    .post(
      '/api/service/runService',
      { clientId, clientServiceId, tags },
      {
        // handle chunk being returned from server
        onDownloadProgress: (progressEvent) => {
          onChunk?.(progressEvent.event.currentTarget.response)
        },
      },
    )
    .then((res) => res.data)
  return service
}

/* @API:service.updateTags */
export async function updateTags(
  clientId: number,
  serviceId: number,
  method: string,
  tags: string,
): Promise<APIResult<string>> {
  return await instance
    .post('/api/service/updateTags', { clientId, serviceId, method, tags })
    .then((res) => res.data)
}

/* @API:service.updateLogs */
export async function updateLogs(
  serviceLogIds: number[],
): Promise<APIResult<string>> {
  return await instance
    .post('/api/service/updateLogs', { serviceLogIds })
    .then((res) => res.data)
}

/* @API:service.sendInvoice */
export async function sendInvoice(logs: GetLog[]): Promise<APIResult<string>> {
  return await instance
    .post('/api/service/sendInvoice', { logs: logs })
    .then((res) => res.data)
}
