import React, { useEffect, useState } from 'react'
import { GetProduct, GetProductTemplate } from '../../../../api/product'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import { ProductAttributeInputText } from '../../../../pages/products/product/ProductAttributeInputText'
import {
  GetIntegrationResult,
  IntegrationName,
  createIntegration,
  getIntegration,
  listProduct,
  unlistProduct,
} from '../../../../api/integration'
import {
  err,
  getDescriptionValue,
  getTitleValue,
} from '../../../../utils/functions'
import {
  SetCloverTemplateIntegration,
  CloverTemplateIntegration,
  CloverCategory,
  CloverProductIntegration,
} from '../../../../types/Clover.types'
import Alert, { AlertInput, handleAlert } from '../../../common/Alert'
import { Integration } from '../../../../api/types'
import ListSyncComponent from '../common/ListSyncComponent'
import ColorPicker from '../../../common/ColorPicker'
import {
  getCloverCategories,
  getCloverIntegrations,
  setCloverProductIntegration,
  setCloverTemplateIntegration,
} from '../../../../api/integrations/clover'
import LoadingBox from '../../../common/LoadingBox'
import { ListUnlistButtons } from '../common/ListUnlistButtons'
import { ProductAttributeInputNumber } from '../../../../pages/products/product/ProductAttributeInputNumber'
import { ProductTitleDescription } from '../../ProductTitleDescription'
import { SingleSelect } from '../../../common/SingleSelect'

interface CloverTemplateIntegrationComponentProps {
  integration?: GetIntegrationResult<IntegrationName.CLOVER> | undefined
  template?: GetProductTemplate
  product?: GetProduct
  onUpdate?: () => void
}

export default function CloverTemplateIntegrationComponent({
  integration: integrationResult,
  template,
  product,
}: CloverTemplateIntegrationComponentProps): JSX.Element {
  const [loading, setLoading] = useState<boolean>(!integrationResult)
  const [templateIntegration, setTemplateIntegration] = useState<
    CloverTemplateIntegration | undefined
  >()
  const [productIntegration, setProductIntegration] = useState<
    CloverProductIntegration | undefined
  >()

  const [integration, setIntegration] = useState<Integration | undefined>(
    integrationResult?.integration,
  )
  const [title, setTitle] = useState<string>('')
  const [description, setDescription] = useState<string>('')
  const [color, setColor] = useState<string | undefined>(undefined)
  const [categories, setCategories] = useState<CloverCategory[]>([])
  const [selectedCategory, setSelectedCategory] = useState<
    | {
        id: string
        name: string
      }
    | undefined
  >()

  const [list, setList] = useState<boolean>(false)
  const [sync, setSync] = useState<boolean>(false)
  const [syncQuantity, setSyncQuantity] = useState<boolean>(false)

  const [alert, setAlert] = useState<AlertInput>({ open: false })

  const handleListProduct = () => {
    const productId = product?.product.id
    if (!productId) {
      return
    }
    listProduct(IntegrationName.CLOVER, productId)
      .then((res) => {
        handleAlert(setAlert, res, 'Successfully listed product.')
      })
      .catch((e) => {
        setAlert({
          open: true,
          severity: 'error',
          message: `${e.response.data.message}`,
        })
      })
  }

  const handleUnlistProduct = () => {
    const productId = product?.product.id
    if (!integration?.id || !productId) return
    unlistProduct(IntegrationName.CLOVER, productId, integration.id)
      .then((res) => {
        handleAlert(setAlert, res, 'Successfully Unlisted.')
      })
      .catch((e) =>
        setAlert({
          open: true,
          severity: 'error',
          message: e.response.data.message,
        }),
      )
  }

  const handleSelectCategory = (categoryName: string | undefined) => {
    const category = categories.find((c) => c.name === categoryName)
    if (!category?.id) {
      setSelectedCategory(undefined)
      return
    }
    setSelectedCategory({ id: category.id, name: category.name })
  }

  const handleCreateIntegration = () => {
    createIntegration(IntegrationName.CLOVER)
      .then((res) => {
        if (res.success && res.data) {
          setIntegration(res.data)
        }
      })
      .catch((e) => {
        console.log(e)
      })
  }

  // Get and set CloverTemplateIntegration, CloverLocations
  useEffect(() => {
    getCloverCategories()
      .then((res) => {
        if (res.data && res.success && Array.isArray(res.data)) {
          setCategories(res.data)
        }
      })
      .catch((e) => err(e))

    // Integration
    if (!integration) {
      getIntegration(IntegrationName.CLOVER)
        .then((res) => {
          setLoading(false)
          if (res.success && res.data) {
            setIntegration(res.data.integration)
          }
        })
        .catch((e) => err(e))
    }
  }, [integration])

  useEffect(() => {
    if (!integration) {
      return
    }

    const integrationId = integration.id
    const templateId = template?.template.id || product?.template.id
    // Clover Template Integration
    if (integrationId && templateId) {
      getCloverIntegrations(integrationId, templateId, product?.product.id)
        .then((res) => {
          if (res.success && res.data) {
            const productIntegration = res.data.productIntegration
            const templateIntegration = res.data.templateIntegration
            setTemplateIntegration(templateIntegration)
            setProductIntegration(productIntegration)

            setColor(templateIntegration?.color)
            setList(
              productIntegration?.list ?? templateIntegration?.list ?? false,
            )
            setSync(
              productIntegration?.sync ?? templateIntegration?.sync ?? false,
            )
            setSyncQuantity(
              productIntegration?.syncQuantity ??
                templateIntegration?.syncQuantity ??
                false,
            )
          }
        })
        .catch((e) => err(e))
    }
  }, [integration, product, template])

  useEffect(() => {
    const category = categories.find(
      (c) => c.id === templateIntegration?.categoryId,
    )
    if (!category?.id) {
      setSelectedCategory(undefined)
      return
    }
    setSelectedCategory({ id: category?.id, name: category.name })
  }, [templateIntegration, categories])

  useEffect(() => {
    // set state
    const title = getTitleValue(
      productIntegration,
      templateIntegration,
      product,
    )
    const description = getDescriptionValue(
      productIntegration,
      templateIntegration,
      product,
    )

    setTitle(title)
    setDescription(description)
  }, [product, template, productIntegration, templateIntegration])

  const handleSave = () => {
    // if product, set product integration
    if (product && templateIntegration) {
      const updateProductIntegration = {
        templateIntegrationId: templateIntegration?.id,
        productId: product.product.id,
        title,
        description,
        color: color,
        categoryId: selectedCategory?.id,
        list,
        sync,
        syncQuantity,
      }
      setCloverProductIntegration(updateProductIntegration)
        .then((res) => {
          handleAlert(setAlert, res, 'Set Clover Product Integration.')
        })
        .catch((e) => {
          err(e)
        })
      return
    }

    // If Template, save template
    if (!integration || !template) return
    const cloverTemplateIntegration: SetCloverTemplateIntegration = {
      templateId: template.template.id,
      integrationId: integration.id,
      color: color,
      categoryId: selectedCategory?.id,
      title,
      description,
      list,
      sync,
      syncQuantity,
    }
    setCloverTemplateIntegration(cloverTemplateIntegration)
      .then((res) => {
        handleAlert(setAlert, res, 'Set Clover Template Integration.')
      })
      .catch((e) => {
        err(e)
      })
  }

  if (loading) {
    return <LoadingBox />
  }

  if (!integration && !loading) {
    return (
      <Grid container justifyContent="center" spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h5">Clover</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography>This integration has not been created yet.</Typography>
        </Grid>
        <Grid item xs={12}>
          <Button variant="contained" onClick={handleCreateIntegration}>
            Create
          </Button>
        </Grid>
      </Grid>
    )
  }

  return (
    <Grid container justifyContent="center" spacing={2}>
      <Grid item xs={12}>
        <ListUnlistButtons
          list={list}
          hasProductIntegration={!!productIntegration}
          product={product}
          onList={handleListProduct}
          onUnlist={handleUnlistProduct}
        />
      </Grid>
      <Grid item xs={12}>
        <ListSyncComponent
          list={list}
          sync={sync}
          syncQuantity={syncQuantity}
          onListChange={setList}
          onSyncChange={setSync}
          onSyncQuantityChange={setSyncQuantity}
        />
      </Grid>

      <Grid item xs={12}>
        <ProductTitleDescription
          template={template}
          product={product}
          title={title}
          required={!!product}
          onTitleChange={setTitle}
          disableUnparsedEdit={true}
        />
      </Grid>

      {product && (
        <>
          <Grid item xs={12}>
            <ProductAttributeInputText
              label="SKU"
              value={product.product.sku}
              disabled={true}
            />
          </Grid>

          <Grid item xs={12}>
            <ProductAttributeInputText
              label="Condition"
              value={product.product.condition}
              disabled={true}
            />
          </Grid>
          <Grid item xs={12}>
            <ProductAttributeInputNumber
              label="Price"
              value={product.product.price}
              disabled={true}
            />
          </Grid>
          <Grid item xs={12}>
            <ProductAttributeInputNumber
              label="Quantity"
              value={product.product.quantity}
              disabled={true}
            />
          </Grid>
        </>
      )}

      <Grid item xs={12}>
        <SingleSelect
          key={`select-clover-category-${selectedCategory?.id}`}
          label="Clover Category"
          value={selectedCategory?.name || ''}
          onChange={(c) => handleSelectCategory(c)}
          options={categories.map((c) => c.name)}
        />
      </Grid>

      <Grid item xs={12}>
        <Grid container justifyContent="center" spacing={1}>
          <Grid item xs={12} md={6} sx={{ m: 'auto' }}>
            <Typography variant="h6">Color</Typography>
            <Typography variant="body2">Clover Item Color</Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid container justifyContent="center">
              <ColorPicker value={color} onChange={setColor} />
              <Grid item xs={12} sx={{ mt: 1 }}>
                <Typography variant="body2"> Color: {color}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid container justifyContent="flex-end">
          <Grid item>
            <Button variant="contained" onClick={handleSave}>
              Save
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Alert
        alert={alert}
        onClose={() => setAlert({ ...alert, open: false })}
      ></Alert>
    </Grid>
  )
}
