import Autocomplete from '@mui/material/Autocomplete'
import Chip from '@mui/material/Chip'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import React from 'react'

interface ProductTagsProps {
  tags: string[]
  onChange: (tags: string[]) => void
}

export default function ProductTags({
  tags,
  onChange,
}: ProductTagsProps): JSX.Element {
  return (
    <Grid container spacing={1}>
      <Grid item xs={12} md={3} lg={2}>
        <Stack
          direction="row"
          justifyContent="center"
          sx={{
            position: 'relative',
            height: '100%',
            top: 10,
          }}
        >
          <Typography
            sx={{
              mr: '0.5em',
              mb: '0.5em',
            }}
          >
            <strong>Tags</strong>
          </Typography>
        </Stack>
      </Grid>
      <Grid item xs={12} md={9} lg={10}>
        <Autocomplete
          value={tags}
          options={tags}
          freeSolo
          multiple
          limitTags={5}
          onChange={(e, values) => onChange(values)}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                {...getTagProps({ index })}
                key={option}
                variant="outlined"
                label={option}
              />
            ))
          }
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              placeholder={'Tags...'}
              size="small"
              value={tags}
            />
          )}
        ></Autocomplete>
      </Grid>
    </Grid>
  )
}
