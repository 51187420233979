import React, { useState } from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import {
  Aspect,
  AspectUsageEnum,
  EbayAspectTemplateIntegration,
  EbayTemplateIntegration,
} from '../../../../types/Ebay.types'
import {
  GetProduct,
  GetProductTemplateAttribute,
} from '../../../../api/product'
import { useMemo } from 'react'
import Alert, { AlertInput } from '../../../common/Alert'
import { getAttributeName } from '../../../../utils/functions'
import { ProductAttributeInputText } from '../../../../pages/products/product/ProductAttributeInputText'
import { SingleSelect } from '../../../common/SingleSelect'

export default function EbayProductAspects({
  product,
  templateIntegration,
  aspects,
  aspectIntegrations,
  templateAttributes,
  disabled,
  onChange,
  onUpdate,
}: {
  product: GetProduct
  templateIntegration?: EbayTemplateIntegration
  aspects: Aspect[]
  aspectIntegrations: EbayAspectTemplateIntegration[]
  templateAttributes: GetProductTemplateAttribute[]
  disabled?: boolean
  onChange: (aspect: EbayAspectTemplateIntegration) => void
  onUpdate?: () => void
}): JSX.Element {
  const [alert, setAlert] = useState<AlertInput>({ open: false })

  // const handleChange = (aspect: AspectIntegrationInput) => {
  //   onChange(aspect)
  //   // onUpdate?.()
  // }

  const productAspectAttributeValues: { [k: string]: string | undefined } =
    useMemo(() => {
      const aspectAttributes: { [k: string]: string | undefined } = {}
      aspectIntegrations.forEach((aspectIntegration) => {
        const attributeName = getAttributeName(aspectIntegration.value)
        if (!attributeName) return
        aspectAttributes[aspectIntegration.aspectName] = attributeName
      })

      const productAspectAttributeValuesObj: {
        [k: string]: string | undefined
      } = {}
      product.attributes.forEach((attribute) => {
        const aspectname = aspectAttributes[attribute.templateAttribute.name]
        if (!aspectname) return
        productAspectAttributeValuesObj[aspectname] = attribute.attribute?.value
      })
      return productAspectAttributeValuesObj
    }, [aspectIntegrations, product.attributes])

  return (
    <Grid container justifyContent="center" spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h6">Aspects</Typography>
        <Typography variant="body2">
          Aspect Attribute mapping can be updated on Template Ebay Integration
          page
        </Typography>
      </Grid>
      {aspects.map((aspect) => {
        const value =
          productAspectAttributeValues[aspect.localizedAspectName] || ''
        let valueInOptions = false
        const options =
          aspect.aspectValues?.map((v) => {
            if (v.localizedValue === value) {
              valueInOptions = true
            }
            return v.localizedValue
          }) || []

        const isError =
          aspect.aspectConstraint.aspectMode === 'SELECTION_ONLY' &&
          valueInOptions

        return (
          <Grid item xs={12} key={aspect.localizedAspectName}>
            {aspect.aspectValues?.length ? (
              <SingleSelect
                freeSolo={aspect.aspectConstraint.aspectMode === 'FREE_TEXT'}
                required={aspect.aspectConstraint.aspectRequired}
                label={aspect.localizedAspectName}
                disabled={disabled}
                // tag={
                //   (aspect.aspectConstraint.aspectRequired ? ' Required' : '') ||
                //   (!aspect.aspectConstraint.aspectRequired &&
                //   aspect.aspectConstraint.aspectUsage ===
                //     AspectUsageEnum.RECOMMENDED
                //     ? ' Reccommended'
                //     : '')
                // }
                errorText={isError ? 'Value is not recognized by Ebay.' : ''}
                options={options}
                value={value}
              />
            ) : (
              <ProductAttributeInputText
                required={aspect.aspectConstraint.aspectRequired}
                tag={aspect.localizedAspectName}
                hideLabel={true}
                disabled={disabled}
                label={
                  (aspect.aspectConstraint.aspectRequired ? ' Required' : '') ||
                  (!aspect.aspectConstraint.aspectRequired &&
                  aspect.aspectConstraint.aspectUsage ===
                    AspectUsageEnum.RECOMMENDED
                    ? ' Reccommended'
                    : '')
                }
                value={productAspectAttributeValues[aspect.localizedAspectName]}
              />
            )}
          </Grid>
        )
      })}

      <Alert
        alert={alert}
        onClose={() => setAlert({ ...alert, open: false })}
      ></Alert>
    </Grid>
  )
}
