/* eslint-disable @typescript-eslint/no-explicit-any */
import { ReactRenderer } from '@tiptap/react'
import tippy, { Instance } from 'tippy.js'

import { MentionList } from './MentionList'

interface RenderType {
  onStart: (props: any, clientRect?: (() => DOMRect) | null | undefined) => void
  onUpdate: (props: {
    clientRect?: (() => DOMRect | null) | null | undefined
  }) => void
  onKeyDown: (props: { event: any }) => boolean
  onExit: (props: unknown) => void
}

const suggestion = (mentions: string[]): any => ({
  items: ({ query }: { query: string }): string[] => {
    return mentions
      .filter((item) => item.toLowerCase().startsWith(query.toLowerCase()))
      .slice(0, 100)
  },

  render: (): RenderType => {
    let reactRenderer: ReactRenderer<any>
    let popup: Instance[]

    return {
      onStart: (props) => {
        if (!props.clientRect) {
          return
        }

        reactRenderer = new ReactRenderer(MentionList, {
          props,
          editor: props.editor,
        })

        popup = tippy('body', {
          getReferenceClientRect: props.clientRect,
          appendTo: () => document.body,
          content: reactRenderer.element,
          showOnCreate: true,
          interactive: true,
          trigger: 'manual',
          placement: 'bottom-start',
        })
      },

      onUpdate(props) {
        reactRenderer.updateProps(props)

        if (!props.clientRect) {
          return
        }

        popup[0]?.setProps({
          getReferenceClientRect: props.clientRect as () => DOMRect,
        })
      },

      onKeyDown(props) {
        if (props.event.key === 'Escape') {
          popup[0]?.hide()

          return true
        }

        return reactRenderer.ref?.onKeyDown(props)
      },

      onExit() {
        popup[0]?.destroy()
        reactRenderer.destroy()
      },
    }
  },
})

export default suggestion
