import React from 'react'
import NavBar from '../components/common/NavBar'
import Grid from '@mui/material/Grid'
import { ProductListingsTable } from '../components/product/ProductListingsTable'
import Container from '@mui/material/Container'
import Component from '../components/common/Component'

export default function ProductListings(): JSX.Element {
  return (
    <>
      <NavBar />
      <div
        style={{
          padding: '2em',
        }}
      >
        <Container>
          <Component title="Integrations">
            <Grid container>
              <Grid item xs={12}>
                <ProductListingsTable />
              </Grid>
            </Grid>
          </Component>
        </Container>
      </div>
    </>
  )
}
