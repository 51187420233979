import React, { useState } from 'react'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Component from '../common/Component'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { addUserRole } from '../../api/admin'
import Alert, { AlertInput, handleAlert } from '../common/Alert'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'

interface AddUserRoleProps {
  clientId: number
}

export default function AddUserRole({
  clientId,
}: AddUserRoleProps): JSX.Element {
  const [userEmail, setUserEmail] = useState('')
  const [userRole, setUserRole] = useState<number>(2)
  const [alert, setAlert] = useState<AlertInput>({ open: false })
  const handleSelect = (roleNumber: number) => {
    setUserRole(roleNumber)
  }

  const handleSubmit = async (): Promise<void> => {
    if (!userEmail) return
    const res = await addUserRole(clientId, userEmail, userRole)
    handleAlert(setAlert, res, `Role created for user ${userEmail}.`)
  }
  return (
    <>
      <Component title="Edit/Add User to Client">
        <Grid container spacing={2} justifyContent="center" direction="column">
          <Grid item>
            <Typography>
              In order to add a user, the user must have have already created a
              TeamAutomate account with their email. Once they have registered,
              you can add them as a user to your client. Select the correct
              &apos;Role&apos; level for the user. Admin users have unristricted
              access. (Roles are still in alpha and most features are enable for
              all users.)
            </Typography>
          </Grid>
          <Grid item>
            <Grid container justifyContent="center" spacing={3}>
              <Grid item>
                <TextField
                  variant="outlined"
                  label="User Email"
                  required
                  value={userEmail}
                  onChange={(e) => setUserEmail(e.target.value)}
                />
              </Grid>
              <Grid item>
                <FormControl>
                  <InputLabel id="demo-simple-select-label">Role</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    label="Role"
                    id="demo-simple-select"
                    value={userRole}
                    onChange={(e) => handleSelect(e.target.value as number)}
                  >
                    <MenuItem value={1}>Admin</MenuItem>
                    <MenuItem value={2}>Owner</MenuItem>
                    <MenuItem value={3}>Manager</MenuItem>
                    <MenuItem value={4}>Employee</MenuItem>
                    <MenuItem value={5}>User</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Component>
      <Alert
        alert={alert}
        onClose={() => setAlert({ ...alert, open: false })}
      />
    </>
  )
}
