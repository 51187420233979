import React, { useState } from 'react'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import { Editor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import { EditorState, convertToRaw, ContentState } from 'draft-js'
import { ProductAttribute } from '../../api/types'
import TipTapEditor from '../../components/tiptap/TipTapEditor'

interface ProductDescriptionEditorProps {
  value: string | undefined
  title?: string
  oneLine?: boolean
  required?: boolean
  onChange: (value: string) => void
  allowHtml?: boolean
  placeholder?: string
  disabled?: boolean
  attributes?: ProductAttribute[]
  templateAttributes?: string[]
  richText?: boolean
}

export function ProductDescriptionEditor({
  value,
  title,
  oneLine,
  onChange,
  allowHtml,
  required,
  placeholder,
  disabled,
  attributes,
  templateAttributes,
  richText,
}: ProductDescriptionEditorProps): JSX.Element {
  const attributeNames = templateAttributes

  const convertEditorToHtml = (editorState: EditorState): string => {
    const htmlValue = draftToHtml(convertToRaw(editorState.getCurrentContent()))
    return htmlValue.trim()
  }

  const convertHtmlToEditor = (
    html: string | undefined,
  ): EditorState | undefined => {
    if (!html) return undefined
    const blocksFromHtml = htmlToDraft(html)
    if (!blocksFromHtml) return
    const { contentBlocks, entityMap } = blocksFromHtml
    const contentState = ContentState.createFromBlockArray(
      contentBlocks,
      entityMap,
    )
    return EditorState.createWithContent(contentState)
  }

  const [editHtml, setEditHtml] = useState<boolean>(false)
  const [errorText, setErrorText] = useState('')

  const renderTitle = !!title
  return (
    <Grid container spacing={1} justifyContent="center">
      {renderTitle && (
        <Grid item xs={12} md={3} lg={2}>
          <Stack
            direction="row"
            justifyContent="center"
            sx={{
              position: 'relative',
              height: '100%',
              top: 10,
            }}
          >
            <Typography sx={{ mr: '0.5em', mb: '0.5em' }}>
              <strong>{title}</strong>
            </Typography>
          </Stack>
        </Grid>
      )}
      <Grid item xs={12} md={renderTitle ? 9 : 12} lg={renderTitle ? 10 : 12}>
        {allowHtml === true ? (
          <Grid container justifyContent="flex-end">
            {editHtml ? (
              <Button onClick={() => setEditHtml(!editHtml)}>View Raw</Button>
            ) : (
              <Button onClick={() => setEditHtml(!editHtml)}>
                HTML Editor
              </Button>
            )}
          </Grid>
        ) : null}

        {editHtml ? (
          <Editor
            editorState={convertHtmlToEditor(value)}
            onEditorStateChange={(editorState) => {
              onChange(convertEditorToHtml(editorState))
            }}
            readOnly={disabled}
            editorStyle={{
              border: '1px solid #767676',
              borderRadius: '4px',
              minHeight: '16em',
              paddingLeft: '16.5px',
              paddingRight: '16.5px',
              overflow: 'scroll',
            }}
          />
        ) : null}

        {/* {richText ? (
          <RichTextInput
            value={value}
            onChange={(v) => {
              console.log(v)
              onChange(v)
            }}
            mentions={attributeNames}
          />
        ) : null} */}

        {!editHtml && richText ? (
          <>
            <TipTapEditor
              placeholder={placeholder}
              required={required}
              disabled={disabled}
              oneLine={oneLine}
              value={value}
              mentions={attributeNames}
              onChange={(v) => {
                onChange(v)
              }}
            />
            {required && !value ? (
              <Grid container>
                <Typography
                  sx={{ mt: 0.5, mx: 1.5 }}
                  variant="caption"
                  color="#d32f2f"
                  justifySelf="start"
                >
                  Value is Required.
                </Typography>
              </Grid>
            ) : null}
          </>
        ) : null}

        {!editHtml && !richText ? (
          <TextField
            onBlur={() =>
              setErrorText(!value && required ? 'Description is required.' : '')
            }
            error={!!errorText}
            helperText={errorText}
            minRows={10}
            fullWidth
            multiline
            disabled={disabled}
            onChange={(e) => {
              onChange(e.target.value)
              setErrorText(
                !e.target.value && required ? 'Description is required.' : '',
              )
            }}
            placeholder={placeholder}
            value={value}
          />
        ) : null}
      </Grid>
    </Grid>
  )
}
