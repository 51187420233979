import instance from '..'
import { APIResult } from '../../utils/types'
import { getResult } from '../../utils/functions'
import {
  SquareCatalogObject,
  SquareLocation,
  SquareProductIntegration,
  SquareTemplateIntegration,
} from '../../types/Square.types'
import { DataFields, Integration } from '../types'

export function getSquareLocations(): Promise<APIResult<SquareLocation[]>> {
  return instance
    .get('/api/integration/square/locations')
    .then((res) => getResult(res))
}

export async function squareBulkImport(params?: {
  ids?: string[]
  template?: string
  updateExistingProducts?: boolean
}): Promise<APIResult<void>> {
  const result = await instance
    .post('/api/integration/square/bulkImport', params)
    .then((res) => getResult<void>(res))
  return result
}

export interface GetSquareItem {
  items: SquareCatalogObject[]
  cursor?: string
}

export async function getSquareItems(query?: {
  textFilter?: string
  offset?: number
  limit?: number
}): Promise<APIResult<GetSquareItem>> {
  const result = await instance
    .get('/api/integration/square/items', {
      params: {
        textFilter: query?.textFilter,
        offset: query?.offset,
        limit: query?.limit,
      },
    })
    .then((res) => getResult<GetSquareItem>(res))
  return result
}

export type GetSquareIntegrationsResult = {
  integration?: Integration
  templateIntegration?: SquareTemplateIntegration
  productIntegration?: SquareProductIntegration
}

export async function getSquareIntegrations(
  integrationId: number,
  templateId?: number,
  productId?: number,
): Promise<APIResult<GetSquareIntegrationsResult>> {
  const squareTemplateIntegration = await instance
    .get('/api/integration/square/integrations', {
      params: {
        integrationId,
        templateId,
        productId,
      },
    })
    .then((res) => getResult<GetSquareIntegrationsResult>(res))
  return squareTemplateIntegration
}

export async function setSquareTemplateIntegration(
  templateIntegration?: DataFields<Omit<SquareTemplateIntegration, 'clientId'>>,
): Promise<APIResult<SquareTemplateIntegration>> {
  const setTemplateIntegration = await instance
    .post('/api/integration/square/templateIntegration', {
      templateIntegration,
    })
    .then((res) => getResult<SquareTemplateIntegration>(res))
  return setTemplateIntegration
}

export async function setSquareProductIntegration(
  productIntegration?: DataFields<Omit<SquareProductIntegration, 'clientId'>>,
): Promise<APIResult<SquareProductIntegration>> {
  const setProductIntegration = await instance
    .post('/api/integration/square/productIntegration', {
      productIntegration,
    })
    .then((res) => getResult<SquareProductIntegration>(res))
  return setProductIntegration
}
